import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Menu } from 'antd';
import { ApiOutlined, HomeOutlined } from '@ant-design/icons';
import { NavLink, useLocation } from 'react-router-dom';

import Logo from './../../assets/logo.png';

import HeaderComponent from './../header';

import { Container, ApplicationName, Header, RounderIcon } from './styles';
import { OfficeBuildingIcon } from '@heroicons/react/outline';

const { SubMenu } = Menu;

const MenuComponent: React.FC<any> = ({ isDark, setIsDark, isCollapsed }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [forceOpen, setForceOpen] = useState('true');
  const { pathname } = useLocation();

  useEffect(() => {
    const storeForceOpen = localStorage.getItem('forceOpen');

    if (storeForceOpen) {
      setForceOpen(storeForceOpen);
    }

    if (storeForceOpen === 'false') {
      setCollapsed(true);
    }
  }, []);

  const mouseEnter = useCallback(() => {
    if (collapsed) {
      setCollapsed(false);
      isCollapsed(false);
    }
  }, [collapsed, isCollapsed]);

  const mouseLeave = useCallback(() => {
    if (!collapsed && forceOpen === 'false') {
      setCollapsed(true);
      isCollapsed(true);
    }
  }, [collapsed, forceOpen, isCollapsed]);

  const selectedKey = useMemo(() => {
    const split = pathname.split('/');

    if (pathname.localeCompare('/ai/booking-status-sets') === 0) {
      return 'booking-status-ai-sets';
    }

    if (split.length === 2) {
      return split[1];
    }

    return split[2];
  }, [pathname]);

  return (
    <>
      <Container
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}
        className={`${collapsed ? 'min-menu' : ''} bg-white dark:bg-ebonyClay`}
        width={collapsed ? '80px' : '260px'}>
        <Menu
          mode="inline"
          selectedKeys={[selectedKey]}
          onMouseEnter={mouseEnter}
          onMouseLeave={mouseLeave}
          className={`${
            collapsed ? 'min-menu' : ''
          }  dark:bg-ebonyClay overflow-y-auto`}
          inlineCollapsed={collapsed}>
          <Header isDark={isDark} setIsDark={setIsDark} collapsed={collapsed}>
            <ApplicationName collapsed={collapsed}>
              {collapsed ? (
                <img src={Logo} className="w-8" alt="" />
              ) : (
                <div className="flex items-center">
                  <img src={Logo} className="w-8 mr-2" alt="" />
                  <span className="dark:text-iron">RMS</span>
                </div>
              )}
            </ApplicationName>
            {!collapsed && (
              <RounderIcon
                collapsed={collapsed}
                onClick={() => {
                  const newValue = forceOpen === 'true' ? 'false' : 'true';
                  setForceOpen(newValue);
                  localStorage.setItem('forceOpen', newValue);

                  if (forceOpen === 'true') {
                    setCollapsed(true);
                    isCollapsed(true);
                  }
                }}>
                {forceOpen === 'true' && <div className="ballInner" />}
              </RounderIcon>
            )}
          </Header>
          <Menu.Item
            className="dashboard dark:text-iron"
            icon={<HomeOutlined className="dark:text-iron" />}
            key="dashboard">
            <NavLink className="dark:text-iron" to="/dashboard">
              Dashboard
            </NavLink>
          </Menu.Item>
          <Menu.Item
            className="dark:text-iron"
            icon={<OfficeBuildingIcon className="w-5 h-5 dark:text-iron" />}
            key="hotel-onboarding">
            <NavLink
              className="dark:text-iron"
              to="/default/hotel-onboarding/list">
              Hotel Onboarding
            </NavLink>
          </Menu.Item>
          {
            <p className={`${collapsed ? 'opacity-0' : ''} section-label`}>
              Components
            </p>
          }
          <SubMenu
            className="dark:text-iron"
            key="submenu-api"
            icon={<ApiOutlined className="dark:text-iron" />}
            title="API">
            <SubMenu className="dark:text-iron" title="Rate Shopper">
              <Menu.Item icon={<span>O</span>} key="rate-shopper">
                <NavLink className="dark:text-iron" to="/api/rate-shopper/list">
                  Link Property
                </NavLink>
              </Menu.Item>
              <Menu.Item icon={<span>O</span>} key="provider">
                <NavLink className="dark:text-iron" to="/api/provider">
                  Provider
                </NavLink>
              </Menu.Item>
            </SubMenu>

            <SubMenu className="dark:text-iron" title="Logs">
              <Menu.Item icon={<span>O</span>} key="report">
                <NavLink className="dark:text-iron" to="/api/report/list">
                  Reports
                </NavLink>
              </Menu.Item>

              <Menu.Item icon={<span>O</span>} key="files">
                <NavLink className="dark:text-iron" to="/api/files/list">
                  Files
                </NavLink>
              </Menu.Item>
            </SubMenu>

            <SubMenu className="dark:text-iron" title="Reports Schedule">
              <Menu.Item icon={<span>O</span>} key="reports-schedule">
                <NavLink
                  className="dark:text-iron"
                  to="/api/reports-schedule/list">
                  Reports Schedule
                </NavLink>
              </Menu.Item>
            </SubMenu>

            <SubMenu className="dark:text-iron" title="Market Segments">
              <Menu.Item icon={<span>O</span>} key="market-segments">
                <NavLink
                  className="dark:text-iron"
                  to="/api/market-segments/list">
                  Market Segments
                </NavLink>
              </Menu.Item>
            </SubMenu>

            <SubMenu className="dark:text-iron" title="Booking Status Sets">
              <Menu.Item icon={<span>O</span>} key="booking-status-sets">
                <NavLink
                  className="dark:text-iron"
                  to="/api/booking-status-sets">
                  Booking Status Sets
                </NavLink>
              </Menu.Item>
            </SubMenu>

            <SubMenu className="dark:text-iron" title="Booking Agents">
              <Menu.Item icon={<span>O</span>} key="booking-agents">
                <NavLink className="dark:text-iron" to="/api/booking-agents">
                  Booking Agents
                </NavLink>
              </Menu.Item>
              <Menu.Item icon={<span>O</span>} key="booking-agents-linkage">
                <NavLink
                  className="dark:text-iron"
                  to="/api/booking-agents-linkage">
                  Booking Agents Linkage
                </NavLink>
              </Menu.Item>
            </SubMenu>

            <SubMenu className="dark:text-iron" title="Hotels">
              <Menu.Item icon={<span>O</span>} key="configuration-wizard">
                <NavLink
                  className="dark:text-iron"
                  to="/api/configuration-wizard">
                  Configuration Wizard
                </NavLink>
              </Menu.Item>
              <Menu.Item icon={<span>O</span>} key="rates-type">
                <NavLink className="dark:text-iron" to="/api/rates-type">
                  Rates type
                </NavLink>
              </Menu.Item>
              <Menu.Item icon={<span>O</span>} key="trivago">
                <NavLink className="dark:text-iron" to="/api/trivago">
                  Trivago
                </NavLink>
              </Menu.Item>
            </SubMenu>

            <SubMenu className="dark:text-iron" title="Hotel Ratings">
              <Menu.Item icon={<span>O</span>} key="google-ratings">
                <NavLink className="dark:text-iron" to="/api/google-ratings">
                  Google Rating
                </NavLink>
              </Menu.Item>
            </SubMenu>

            <SubMenu className="dark:text-iron" title="Channel Manager">
              <Menu.Item icon={<span>O</span>} key="channel-manager">
                <NavLink className="dark:text-iron" to="/api/channel-manager">
                  Channel Manager
                </NavLink>
              </Menu.Item>
            </SubMenu>

            <SubMenu className="dark:text-iron" title="Unblocked Hotels">
              <Menu.Item icon={<span>O</span>} key="unblocked-hotels">
                <NavLink className="dark:text-iron" to="/api/unblocked-hotels">
                  Unblocked Hotels
                </NavLink>
              </Menu.Item>
            </SubMenu>

            <SubMenu className="dark:text-iron" title="Clear Cache">
              <Menu.Item icon={<span>O</span>} key="clear-cache">
                <NavLink className="dark:text-iron" to="/api/clear-cache">
                  Clear Cache
                </NavLink>
              </Menu.Item>
            </SubMenu>

            <SubMenu className="dark:text-iron" title="Maintenance Mode">
              <Menu.Item icon={<span>O</span>} key="maintenance-mode">
                <NavLink className="dark:text-iron" to="/api/maintenance-mode">
                  Maintenance Mode
                </NavLink>
              </Menu.Item>
            </SubMenu>

            <SubMenu className="dark:text-iron" title="Price Recommendation">
              <Menu.Item icon={<span>O</span>} key="autopilot-emails">
                <NavLink className="dark:text-iron" to="/api/autopilot-emails">
                  Autopilot Emails
                </NavLink>
              </Menu.Item>
              <Menu.Item icon={<span>O</span>} key="base-room-rate">
                <NavLink className="dark:text-iron" to="/api/base-room-rate">
                  Base Room Rate
                </NavLink>
              </Menu.Item>
              <Menu.Item icon={<span>O</span>} key="price-recommendation">
                <NavLink
                  className="dark:text-iron"
                  to="/api/price-recommendation">
                  Price Recommendation
                </NavLink>
              </Menu.Item>
            </SubMenu>

            <SubMenu className="dark:text-iron" title="Multi Property">
              <Menu.Item icon={<span>O</span>} key="historical-data">
                <NavLink className="dark:text-iron" to="/api/historical-data">
                  Historical Data
                </NavLink>
              </Menu.Item>
              <Menu.Item icon={<span>O</span>} key="activate-deactivate">
                <NavLink className="dark:text-iron" to="/api/activate-deactivate">
                  Activate/Deactivate
                </NavLink>
              </Menu.Item>
            </SubMenu>
          </SubMenu>

          <SubMenu
            className="dark:text-iron"
            key="submenu-ai"
            icon={<ApiOutlined className="dark:text-iron" />}
            title="AI">
            <SubMenu className="dark:text-iron" title="Booking Status Sets">
              <Menu.Item icon={<span>O</span>} key="booking-status-ai-sets">
                <NavLink
                  className="dark:text-iron"
                  to="/ai/booking-status-sets">
                  Booking Status Sets
                </NavLink>
              </Menu.Item>
            </SubMenu>

            <SubMenu className="dark:text-iron" title="Datasets">
              <Menu.Item icon={<span>O</span>} key="force-dataset">
                <NavLink className="dark:text-iron" to="/ai/force-dataset">
                  Force dataset
                </NavLink>
              </Menu.Item>
            </SubMenu>

            <SubMenu className="dark:text-iron" title="Outlier">
              <Menu.Item icon={<span>O</span>} key="Outlier">
                <NavLink className="dark:text-iron" to="/ai/outlier">
                  Outlier
                </NavLink>
              </Menu.Item>
            </SubMenu>

            <SubMenu className="dark:text-iron" title="Last import time">
              <Menu.Item icon={<span>O</span>} key="LastImportTime">
                <NavLink className="dark:text-iron" to="/ai/last-import-time">
                  Last import time
                </NavLink>
              </Menu.Item>
            </SubMenu>

            <SubMenu className="dark:text-iron" title="Forecast">
              <Menu.Item icon={<span>O</span>} key="ForecastHotelSetup">
                <NavLink
                  className="dark:text-iron"
                  to="/ai/forecast-hotel-setup">
                  Hotel Setup
                </NavLink>
              </Menu.Item>
            </SubMenu>

            <SubMenu className="dark:text-iron" title="Absolute Error">
              <Menu.Item icon={<span>O</span>} key="absolute-error-rn">
                <NavLink className="dark:text-iron" to="/ai/absolute-error-rn">
                  RN
                </NavLink>
              </Menu.Item>
              <Menu.Item icon={<span>O</span>} key="absolute-error-rrev">
                <NavLink
                  className="dark:text-iron"
                  to="/ai/absolute-error-rrev">
                  RREV
                </NavLink>
              </Menu.Item>
            </SubMenu>
          </SubMenu>
        </Menu>
      </Container>

      <HeaderComponent collapsed={collapsed} />
    </>
  );
};

export default MenuComponent;
