import { Select, Skeleton } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import Repository from '../../../../config/repository';
import { useUser } from '../../../../context/user';
import ModalAction from '../../../../components/modal-action';
import { AxiosResponse } from 'axios';
import moment from 'moment';

interface ILastMaintenanceMode {
  id: number;
  user_id: number;
  message?: string;
  status: 0 | 1;
  created_at: string;
  updated_at: string;
  user: {
    id: number;
    name: string;
  };
}

interface IPayloadMaintenanceMode {
  message?: string;
  status: 0 | 1;
}

export interface CustomError {
  response?: {
    data?: {
      message?: string;
    };
  };
}

const MaintenanceMode: React.FC = () => {
  const repository = new Repository();

  const { showAlert: showAlertError } = useUser();

  const [loading, setLoading] = useState(false);
  const [openModalAction, setOpenModalAction] = useState(false);
  const [maintenanceMode, setMaintenanceMode] =
    useState<Partial<ILastMaintenanceMode>>();
  const [payloadMaintenanceMode, setPayloadMaintenanceMode] =
    useState<IPayloadMaintenanceMode>({
      status: 0,
      message: '',
    });

  const maintenanceModeStatus = [
    {
      value: 0,
      option: 'Disable Maintenance Mode',
    },
    {
      value: 1,
      option: 'Enable Maintenance Mode',
    },
  ];

  const lastUpdateMessage = `Last update by ${
    maintenanceMode?.user?.name
  } at ${moment(maintenanceMode?.updated_at).format('LLL')} ${
    maintenanceMode?.status
      ? 'enabling the maintenance mode'
      : 'disabling the maintenance mode'
  } ${
    maintenanceMode?.message
      ? ` with the message ${maintenanceMode.message}.`
      : '.'
  }`;

  useEffect(() => {
    getLastMaintenanceMode();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const createMaintenanceMode = useCallback(
    async () => {
      setLoading(true);

      try {
        const response: AxiosResponse =
          await repository.maintenanceMode.createMaintenanceMode(
            payloadMaintenanceMode,
          );
        setMaintenanceMode(response.data);
        setPayloadMaintenanceMode((prevState) => ({
          ...prevState,
          message: '',
        }));
      } catch (error) {
        const errorMessage =
          (error as CustomError)?.response?.data?.message ?? '';
        showAlertError(errorMessage, 'error', 'OK');
      } finally {
        setLoading(false);
      }
    },
    [payloadMaintenanceMode], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const getLastMaintenanceMode = async () => {
    setLoading(true);

    try {
      const response: AxiosResponse =
        await repository.maintenanceMode.getLastMaintenanceMode();
      setMaintenanceMode(response.data);
      setPayloadMaintenanceMode((prevState) => ({
        ...prevState,
        status: response.data?.status || 0,
      }));
    } catch {
      showAlertError('Error while getting data', 'error', 'OK');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex bg-white p-4 overflow-hidden shadow-md dark:bg-ebonyClay relative">
      {loading ? (
        <Skeleton />
      ) : (
        <div className="flex flex-col w-full mb-4">
          <div className="mb-4 mt-1 sm:mt-0 flex flex-col">
            <label
              htmlFor="status"
              className="block text-xs font-medium mb-1 text-gray-700 sm:mt-px sm:pt-2 dark:text-iron">
              Status
            </label>
            <Select
              className="w-5/12"
              id="status"
              placeholder="Maintenance Mode"
              value={payloadMaintenanceMode.status}
              onChange={(value) => {
                setPayloadMaintenanceMode((prevState) => ({
                  ...prevState,
                  status: value,
                }));
              }}>
              {maintenanceModeStatus.map((item, index: number) => (
                <Select.Option value={item.value} key={index}>
                  {item.option}
                </Select.Option>
              ))}
            </Select>
            <label
              htmlFor="message"
              className="block text-xs font-medium mb-1 text-gray-700 sm:mt-px sm:pt-2 dark:text-iron">
              Message
            </label>
            <input
              className="block w-full shadow-sm sm:text-sm border-gray-300 dark:bg-transparent dark:text-white"
              type="text"
              id="message"
              maxLength={255}
              value={payloadMaintenanceMode.message}
              onChange={(event) => {
                setPayloadMaintenanceMode((prevState) => ({
                  ...prevState,
                  message: event.target.value,
                }));
              }}
            />
          </div>
          {maintenanceMode?.user && (
            <p className="block text-xs font-medium mb-1 text-gray-700 sm:mt-px sm:pt-2 dark:text-iron">
              {lastUpdateMessage}
            </p>
          )}
          <div className="flex justify-end">
            <button
              onClick={() => {
                payloadMaintenanceMode.status
                  ? setOpenModalAction(true)
                  : createMaintenanceMode();
              }}
              type="button"
              className={
                'border-indigo-600 bg-indigo-600 hover:bg-indigo-700 items-center outline-none px-3 py-2 ml-2 border text-xs leading-4 font-medium shadow text-white'
              }>
              Save
            </button>
          </div>
        </div>
      )}
      <ModalAction
        title={
          <div className="flex flex-col text-sm">
            <span>You will enable the maintenance mode.</span>
          </div>
        }
        message="Do you want to proceed?"
        open={openModalAction}
        setOpen={() => setOpenModalAction(!openModalAction)}
        onSave={() => {
          setOpenModalAction(!openModalAction);
          createMaintenanceMode();
        }}
        onCancel={() => setOpenModalAction(!openModalAction)}
        buttonSaveName="Save"
        type="success"
      />
    </div>
  );
};

export default MaintenanceMode;
