import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import BreadcrumbCreation, {
  Step,
} from '../../../../components/breadcrumb-creation';
import { Select } from 'antd';
import Repository from '../../../../config/repository';
import { useUser } from '../../../../context/user';
import ModalAction from '../../../../components/modal-action';
import { AxiosResponse } from 'axios';

interface Props {}

const repository = new Repository();

const BookingAgentsLinkage: React.FC<Props> = () => {
  const history = useHistory();
  const { clientHotels, showAlert } = useUser();
  const [currentStep, setCurrentStep] = useState(0);
  const [hotelId, setHotelId] = useState(undefined);
  const [bookingAgents, setBookingAgents] = useState<BookingAgentResponse[]>(
    [],
  );
  const [selectedBookingAgentsId, setSelectedBookingAgentsId] = useState<
    number[]
  >([]);
  const [openModalAction, setOpenModalAction] = useState(false);
  const [loading, setLoading] = useState(false);

  const hotelSelected = useMemo(
    () => clientHotels.find((hotel) => hotel.id === hotelId),
    [hotelId, clientHotels],
  );

  const bookingAgentsSelected = useMemo(() => {
    let data: BookingAgent[] = [];

    selectedBookingAgentsId.forEach((id) => {
      let currentBookingAgent = bookingAgents.filter(
        (bookingAgentItem) => bookingAgentItem?.booking_agent.id === id,
      );

      if (currentBookingAgent) {
        data.push(currentBookingAgent[0].booking_agent);
      }
    });

    return data;
  }, [bookingAgents, selectedBookingAgentsId]);

  const fetchAgents = useCallback(async (id) => {
    try {
      const response: AxiosResponse<BookingAgentResponse[]> =
        await repository.api.fetchBookingAgentsByHotelId(id, {
          'sort[by]': 'name',
          'sort[order]': 'asc',
        });

      setBookingAgents(response.data);

      const filteredActiveBookingAgents = response.data
        .filter((item) => item.active)
        .map((item) => item.booking_agent.id);

      setSelectedBookingAgentsId(filteredActiveBookingAgents);
    } catch {}
  }, []);

  useEffect(() => {
    if (!hotelId) return;

    fetchAgents(hotelId);
  }, [hotelId]); // eslint-disable-line react-hooks/exhaustive-deps

  const steps: Step[] = useMemo(
    () => [
      {
        title: 'Hotel',
        subTitle: 'Select a Hotel',
        content: (
          <Select
            className="w-6/12"
            showSearch
            placeholder="Please select the hotel"
            filterOption={(input, option) => {
              if (option?.label && typeof option.label === 'string') {
                return (option.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }
              return true;
            }}
            value={hotelId}
            onChange={(value) => setHotelId(value)}
            options={clientHotels.map((value) => {
              return {
                value: value.id,
                label: value.name,
              };
            })}
          />
        ),
      },
      {
        title: 'Booking Agents',
        subTitle: 'Select the booking agents by id',
        content: (
          <div className="flex flex-col w-full">
            {/* input */}
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <Select
                mode="multiple"
                className="w-8/12"
                placeholder="Please select"
                value={selectedBookingAgentsId}
                onChange={(value) => setSelectedBookingAgentsId(value)}>
                {bookingAgents.map((item, index: number) => (
                  <Select.Option value={item.booking_agent.id} key={index}>
                    {item.booking_agent.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
        ),
      },
      {
        title: 'Overhaul',
        subTitle: 'Review all the details',
        content: (
          <div className="flex flex-col">
            <span>
              <b>Hotel:</b> {hotelSelected?.name}
            </span>
            <span className="flex flex-col mt-2">
              <b>Booking Agents:</b>
              {bookingAgentsSelected?.map((item, index: number) => (
                <li className="ml-2 text-sm" key={index}>
                  {item?.name}
                </li>
              ))}
            </span>
          </div>
        ),
      },
      {
        title: 'Record',
        subTitle: 'Apply changes',
        content: <span>Save data</span>,
      },
    ],
    [
      hotelId,
      clientHotels,
      selectedBookingAgentsId,
      bookingAgents,
      hotelSelected,
      bookingAgentsSelected,
    ],
  );

  const saveData = useCallback(async () => {
    if (!hotelId) return;

    setLoading(true);

    let data: { booking_agent_id: number; link: boolean }[] = [];

    selectedBookingAgentsId.forEach((item) => {
      data.push({
        booking_agent_id: item,
        link: true,
      });
    });

    try {
      await repository.api.updateBookingAgent(hotelId, data);

      setLoading(false);
      showAlert('Booking Agent will be created', 'success', 'OK');

      history.push('/');
    } catch {
      setLoading(false);
      showAlert('Error while saving', 'error', 'OK');
    }
  }, [selectedBookingAgentsId, history, hotelId, showAlert]);

  return (
    <div className="flex bg-white p-4 overflow-hidden shadow-md dark:bg-ebonyClay relative">
      <BreadcrumbCreation
        currentStep={currentStep}
        steps={steps}
        loading={loading}
        onCancel={() => setCurrentStep((prevState) => prevState - 1)}
        onResolve={() => {
          if (currentStep === 0 && hotelId === undefined) {
            showAlert('Please select a hotel', 'error', 'OK');

            return;
          }

          setCurrentStep((prevState) => {
            if (prevState < steps.length - 1) {
              return prevState + 1;
            }

            return prevState;
          });

          if (currentStep === steps.length - 1) {
            setOpenModalAction(!openModalAction);
          }
        }}
      />

      <ModalAction
        title={
          <div className="flex flex-col text-sm">
            <span>{hotelSelected?.name}</span>
            <span>You will update the booking status ai sets.</span>
          </div>
        }
        message="You want to proceed with booking agent linkage for this hotel?"
        open={openModalAction}
        setOpen={() => setOpenModalAction(!openModalAction)}
        onSave={() => {
          setOpenModalAction(!openModalAction);
          saveData();
        }}
        onCancel={() => setOpenModalAction(!openModalAction)}
        buttonSaveName="Save"
        type="success"
      />
    </div>
  );
};

export default BookingAgentsLinkage;

//
// Utils
//

export interface BookingAgent {
  id: number;
  channel_manager_id: string;
  code: string;
  description: string;
  name: string;
  cm_group_id: number;
}

export interface BookingAgentResponse {
  active: true;
  booking_agent: BookingAgent;
}
