import React, { useCallback, useMemo, useState } from 'react';
import BreadcrumbCreation, {
  Step,
} from '../../../../components/breadcrumb-creation';
import { DatePicker, Select } from 'antd';
import Repository from '../../../../config/repository';
import { useUser } from '../../../../context/user';
import ModalAction from '../../../../components/modal-action';
import { useHistory } from 'react-router';
import { Moment } from 'moment';

const repository = new Repository();

const AbsoluteErrorRrev: React.FC = () => {
  const { clientHotels, showAlert } = useUser();
  const [currentStep, setCurrentStep] = useState(0);
  const [hotelId, setHotelId] = useState(undefined);
  const [form, setForm] = useState<{
    endDate: Moment | null;
    startDate: Moment | null;
  }>({
    endDate: null,
    startDate: null,
  });
  const [openModalAction, setOpenModalAction] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const hotelSelected = useMemo(
    () => clientHotels.find((hotel) => hotel.id === hotelId),
    [hotelId, clientHotels],
  );

  const generateAbsoluteErrorRrev = useCallback(async () => {
    if (!hotelId || !form.endDate || !form.startDate) {
      return;
    }

    const payload = {
      start_date: form.startDate.format('YYYY-MM-DD'),
      end_date: form.endDate.format('YYYY-MM-DD'),
    };

    setLoading(true);

    try {
      await repository.aiAbsoluteError.generateAbsoluteErrorRrev(
        hotelId,
        payload,
      );
      history.push('/');

      showAlert('Generated absolute error rrev with success', 'success', 'OK');
    } catch {
      showAlert('Error to generate absolute error rrev', 'error', 'OK');
    }
    setLoading(false);
  }, [history, hotelId, form, showAlert]);

  const steps: Step[] = useMemo(
    () => [
      {
        title: 'Hotel',
        subTitle: 'Select a Hotel',
        content: (
          <Select
            className="w-6/12"
            showSearch
            placeholder="Please select the hotel"
            filterOption={(input, option) => {
              if (option?.label && typeof option.label === 'string') {
                return (option.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }
              return true;
            }}
            value={hotelId}
            onChange={(value) => setHotelId(value)}
            options={clientHotels.map((value) => {
              return {
                value: value.id,
                label: value.name,
              };
            })}
          />
        ),
      },
      {
        title: 'Generate Absolute Error RREV CSV to S3',
        subTitle: 'Generate Absolute Error RREV',
        hiddenNext: true,
        content: (
          <div className="flex flex-col w-full mb-4">
            <div className="mb-2 flex gap-4">
              <div className="w-full" style={{ maxWidth: 150 }}>
                <label className="dark:text-white text-sm mr-4 font-bold">
                  Start Date:
                </label>

                <DatePicker
                  className="w-full text-sm"
                  placeholder="Select a start date"
                  value={form.startDate}
                  disabledDate={(currentDate) =>
                    form?.endDate?.isBefore(currentDate) ||
                    currentDate.isAfter(new Date())
                  }
                  onChange={(date) => {
                    setForm((prevState) => ({
                      ...prevState,
                      startDate: date,
                    }));
                  }}
                />
              </div>
              <div className="w-full" style={{ maxWidth: 150 }}>
                <label className="dark:text-white text-sm mr-4 font-bold">
                  End Date:
                </label>

                <DatePicker
                  className="w-full text-sm"
                  placeholder="Select a end date"
                  value={form.endDate}
                  disabledDate={(currentDate) =>
                    currentDate?.isBefore(form.startDate) ||
                    currentDate.isAfter(new Date())
                  }
                  onChange={(date) => {
                    setForm((prevState) => ({
                      ...prevState,
                      endDate: date,
                    }));
                  }}
                />
              </div>
              <div className="flex justify-end items-end pb-1">
                <button
                  onClick={() => setOpenModalAction((prevState) => !prevState)}
                  disabled={!form.endDate || !form.startDate}
                  className={`px-3 py-2 border text-xs leading-4 font-medium shadow-sm flex justify-end items-center ${
                    form.endDate && form.startDate
                      ? 'border-indigo-600 text-white bg-indigo-600 hover:bg-indigo-700'
                      : 'border-gray-300 bg-gray-300 text-gray-700 cursor-not-allowed'
                  }`}>
                  Generate
                </button>
              </div>
            </div>
          </div>
        ),
      },
    ],
    [hotelId, clientHotels, form],
  );

  return (
    <div className="flex bg-white p-4 overflow-hidden shadow-md dark:bg-ebonyClay relative">
      <BreadcrumbCreation
        currentStep={currentStep}
        steps={steps}
        loading={loading}
        onCancel={() => setCurrentStep((prevState) => prevState - 1)}
        onResolve={() => {
          if (currentStep === 0 && hotelId === undefined) {
            showAlert('Please select a hotel', 'error', 'OK');

            return;
          }

          setCurrentStep((prevState) => {
            if (prevState < steps.length - 1) {
              return prevState + 1;
            }

            return prevState;
          });
        }}
      />

      <ModalAction
        title={
          <div className="flex flex-col text-sm">
            <span>{hotelSelected?.name}</span>
            <span>
              You will generate Absolute Error RREV between the dates{' '}
              {form?.startDate?.format('YYYY-MM-DD')} and{' '}
              {form?.endDate?.format('YYYY-MM-DD')}.
            </span>
          </div>
        }
        message="Do you want to proceed?"
        open={openModalAction}
        setOpen={() => setOpenModalAction(!openModalAction)}
        onSave={() => {
          generateAbsoluteErrorRrev();
        }}
        onCancel={() => setOpenModalAction(!openModalAction)}
        buttonSaveName="Save"
        type="success"
      />
    </div>
  );
};

export default AbsoluteErrorRrev;
