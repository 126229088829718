import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon, XIcon } from '@heroicons/react/outline';
import classNames from '../../utils/classNames';

interface Props {
  open: boolean;
  setOpen: (value?: boolean) => void;
  title: string | React.ReactNode;
  message: string | React.ReactNode;
  onSave: () => void;
  onCancel: () => void;
  buttonSaveName?: string;
  buttonCancelName?: string;
  type?: 'success' | 'error';
}

const ModalAction: React.FC<Props> = ({
  open,
  setOpen,
  title,
  message,
  onSave,
  onCancel,
  buttonSaveName,
  buttonCancelName,
  type = 'error',
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={open}
        onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div className="inline-block align-bottom bg-white px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 dark:bg-ebonyClay">
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white text-gray-400 hover:text-gray-500 dark:bg-ebonyClay dark:text-white outline-none focus:outline-none"
                  onClick={() => setOpen()}>
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="sm:flex sm:items-start">
                <div
                  className={classNames(
                    type === 'success' ? 'bg-green-100' : 'bg-red-100',
                    'mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full  sm:mx-0 sm:h-10 sm:w-10',
                  )}>
                  <ExclamationIcon
                    className={classNames(
                      type === 'success' ? 'text-green-400' : 'text-red-600',
                      'h-6 w-6 ',
                    )}
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
                    {title}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500 dark:text-gray-200">
                      {message}
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className={classNames(
                    type === 'success'
                      ? 'bg-green-500 hover:bg-green-400'
                      : 'bg-red-600 hover:bg-red-700',
                    'w-full inline-flex justify-center border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm',
                  )}
                  onClick={() => onSave()}>
                  {buttonSaveName || 'Delete'}
                </button>
                <button
                  type="button"
                  className={classNames(
                    'mt-3 w-full inline-flex justify-center border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 sm:mt-0 sm:w-auto sm:text-sm',
                  )}
                  onClick={() => onCancel()}>
                  {buttonCancelName || 'Cancel'}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ModalAction;
