import ApiService from '../../../../api-service';

interface IPayload {
  rating_channel_id: string;
  rating_channel_internal_id: string;
  rating: number;
  user_reviews_count: number;
  price_level?: number;
}

export default class HotelRatingChannelRepository {
  api = ApiService;

  getHotelRatingByChannel(channelId: string) {
    return this.api.get(
      `api/support/hotel-rating-channel/channel/${channelId}`,
    );
  }

  getGoogleInfoByHotelName(hotelName: string) {
    return this.api.get(`api/support/hotel-rating-channel/list/google-info`, {
      params: {
        hotelName,
      },
    });
  }

  deleteHotelRatingChannel(id: string) {
    return this.api.delete(`api/support/hotel-rating-channel/${id}`);
  }

  createHotelRatingChannel(hotelId: string, data: IPayload) {
    return this.api.post(
      `api/support/hotel-rating-channel/hotel/${hotelId}`,
      data,
    );
  }
}
