import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import Confirmed from './confirmed';
import BreadcrumbCreation, {
  Step,
} from '../../../components/breadcrumb-creation';
import { Select } from 'antd';
import Repository from '../../../config/repository';
import { useUser } from '../../../context/user';
import { useTables } from '../../../context/tables';
import ModalAction from '../../../components/modal-action';
import { IPmsStatus } from '../../api/pages/booking-status-sets';

export interface IStatusCodeByHotel {
  id: string | number;
  label: string;
  name: string;
}

interface Props {}

const BookingStatusSets: React.FC<Props> = () => {
  const repository = new Repository();
  const { tables, setTables } = useTables();
  const history = useHistory();
  const { showAlert, clientHotels } = useUser();
  const [currentStep, setCurrentStep] = useState(0);
  const [form, setForm] = useState<{ hotel: number | undefined }>({
    hotel: undefined,
  });
  const [openModalAction, setOpenModalAction] = useState(false);
  const [loading, setLoading] = useState(false);
  const [statusCodesByHotel, setStatusCodesByHotel] = useState<
    IStatusCodeByHotel[]
  >([]);

  const hotelSelected = useMemo(
    () => clientHotels.find((hotel) => hotel.id === form.hotel),
    [form.hotel, clientHotels],
  );

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (hotelSelected) fetchStatusCodes();
  }, [hotelSelected]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = useCallback(async () => {
    try {
      const response = await repository.ai.fetchAiBookingStatusMappings();

      setTables((prevState) => ({
        ...prevState,
        bookingStatusSets: {
          data: response.data,
        },
      }));
    } catch {}
  }, [repository.ai, setTables]);

  const fetchStatusCodes = useCallback(async () => {
    if (!form.hotel) return;

    try {
      const response = await repository.api.fetchBookingStatusCodes(form.hotel);

      if (!response.data.length) return;

      setStatusCodesByHotel(() => {
        const statusCodes = [] as IStatusCodeByHotel[];
        response.data.forEach((item: IPmsStatus) => {
          statusCodes.push({
            id: item.code,
            label: item.code,
            name: item.name,
          });
        });
        return statusCodes;
      });
    } catch {
      showAlert('Error while getting data', 'error', 'OK');
    }
  }, [form.hotel, repository.api]); // eslint-disable-line react-hooks/exhaustive-deps

  const getSets = useCallback(
    (
      type: number,
    ): {
      created_at: string;
      hotel_id: number;
      id: number;
      name: string;
      pms_booking_statuses: string[];
      type: number;
      updated_at: string;
    } =>
      tables.bookingStatusSets?.data?.find(
        (item) => item.type === type && item.hotel_id === form.hotel,
      ),
    [form.hotel, tables.bookingStatusSets.data],
  );

  const steps: Step[] = useMemo(
    () => [
      {
        title: 'Hotel',
        subTitle: 'Select a Hotel',
        content: (
          <Select
            className="w-6/12"
            showSearch
            placeholder="Please select the hotel"
            filterOption={(input, option) => {
              if (option?.label && typeof option.label === 'string') {
                return (option.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }
              return true;
            }}
            value={form.hotel}
            onChange={(value) =>
              setForm((prevState) => ({
                ...prevState,
                hotel: value,
              }))
            }
            options={clientHotels.map((value) => {
              return {
                value: value.id,
                label: value.name,
              };
            })}
          />
        ),
      },
      {
        title: 'Booking status AI sets',
        subTitle: 'Select the sets by type',
        content: (
          <div className="flex flex-col w-full">
            <Confirmed
              hotelId={form.hotel || 0}
              pmsOptions={statusCodesByHotel}
            />
          </div>
        ),
      },
      {
        title: 'Overhaul',
        subTitle: 'Review all the details',
        content: (
          <div className="flex flex-col">
            <span>
              <b>Hotel:</b> {hotelSelected?.name}
            </span>
            <span className="flex flex-col">
              <b>Definitive:</b>
              {getSets(1)?.pms_booking_statuses?.map((item, index: number) => (
                <li className="ml-2 text-sm" key={index}>
                  {item}
                </li>
              ))}
            </span>
          </div>
        ),
      },
      {
        title: 'Dispatch',
        subTitle: 'Dispatch AI set',
        content: <span>Save data</span>,
      },
    ],
    [form.hotel, getSets, hotelSelected, clientHotels, statusCodesByHotel],
  );

  const saveData = useCallback(async () => {
    setLoading(true);

    const sets = [
      {
        type: 1,
        pms_booking_statuses: getSets(1)?.pms_booking_statuses || [],
      },
    ];

    try {
      await repository.ai.createAiBookingStatusMappingBulk({
        hotel_id: form.hotel,
        sets,
      });

      setLoading(false);
      showAlert('Statuses will be created', 'success', 'OK');
      history.push('/');
    } catch {
      showAlert('Error while saving', 'error', 'OK');
      setLoading(false);
    }
  }, [form.hotel, getSets, history, repository.ai, showAlert]);

  return (
    <div className="flex bg-white p-4 overflow-hidden shadow-md dark:bg-ebonyClay relative">
      <BreadcrumbCreation
        currentStep={currentStep}
        steps={steps}
        loading={loading}
        onCancel={() => setCurrentStep((prevState) => prevState - 1)}
        onResolve={() => {
          if (currentStep === 0 && form.hotel === undefined) {
            showAlert('Please select a hotel', 'error', 'OK');

            return;
          }

          setCurrentStep((prevState) => {
            if (prevState < steps.length - 1) {
              return prevState + 1;
            }

            return prevState;
          });

          if (currentStep === steps.length - 1) {
            setOpenModalAction(!openModalAction);
          }
        }}
      />

      <ModalAction
        title={
          <div className="flex flex-col text-sm">
            <span>{hotelSelected?.name}</span>
            <span>You will update the booking status ai sets.</span>
          </div>
        }
        message="Do you want to proceed?"
        open={openModalAction}
        setOpen={() => setOpenModalAction(!openModalAction)}
        onSave={() => {
          setOpenModalAction(!openModalAction);
          saveData();
        }}
        onCancel={() => setOpenModalAction(!openModalAction)}
        buttonSaveName="Save"
        type="success"
      />
    </div>
  );
};

export default BookingStatusSets;
