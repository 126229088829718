import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import Rows from './rows';
import BreadcrumbCreation, {
  Step,
} from '../../../../components/breadcrumb-creation';
import { Select } from 'antd';
import Repository from '../../../../config/repository';
import { useUser } from '../../../../context/user';
import { useTables } from '../../../../context/tables';
import ModalAction from '../../../../components/modal-action';
import { IStatusCodeByHotel } from '../../../ai/booking-status-sets';

interface Props {}

export interface IPmsStatus {
  id: number;
  code: string;
  name: string;
}

interface IStatusCodesByHotel {
  DEF: IStatusCodeByHotel[];
  TEN: IStatusCodeByHotel[];
  CXL: IStatusCodeByHotel[];
  BLQ: IStatusCodeByHotel[];
}

const BookingStatusSets: React.FC<Props> = () => {
  const repository = new Repository();
  const { tables, setTables } = useTables();
  const history = useHistory();
  const { showAlert, clientHotels } = useUser();
  const [currentStep, setCurrentStep] = useState(0);
  const [form, setForm] = useState<{ hotel: number | undefined }>({
    hotel: undefined,
  });
  const [openModalAction, setOpenModalAction] = useState(false);
  const [loading, setLoading] = useState(false);
  const [statusCodesByHotel, setStatusCodesByHotel] =
    useState<IStatusCodesByHotel>({
      DEF: [],
      TEN: [],
      BLQ: [],
      CXL: [],
    });

  const hotelSelected = useMemo(
    () => clientHotels.find((hotel) => hotel.id === form.hotel),
    [form.hotel, clientHotels],
  );

  const statusList: (keyof IStatusCodesByHotel)[] = useMemo(
    () => ['DEF', 'TEN', 'CXL', 'BLQ'],
    [],
  );

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (hotelSelected) fetchStatusCodes();
  }, [hotelSelected]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = useCallback(async () => {
    try {
      const response = await repository.api.fetchBookingStatus();

      setTables((prevState) => ({
        ...prevState,
        apiBookingStatusSets: {
          data: response.data,
        },
      }));
    } catch {}
  }, [repository.api, setTables]);

  const fetchStatusCodes = useCallback(async () => {
    if (!form.hotel) return;

    if (tables.apiBookingStatusSets?.data?.length === 0) return;

    try {
      const response = await repository.api.fetchBookingStatusCodes(form.hotel);

      if (!response.data.length) return;

      const getStatusCodes = (_name: keyof IStatusCodesByHotel) => {
        return response.data
          .map((item: IPmsStatus) => ({
            id: item.id,
            label: item.code,
            name: item.name,
          }))
          .sort((a: IPmsStatus, b: IPmsStatus) => a.name.localeCompare(b.name));
      };

      setStatusCodesByHotel(() => {
        const statusCodes = {
          DEF: getStatusCodes('DEF'),
          TEN: getStatusCodes('TEN'),
          CXL: getStatusCodes('CXL'),
          BLQ: getStatusCodes('BLQ'),
        } as IStatusCodesByHotel;

        return statusCodes;
      });
    } catch {}
  }, [form.hotel, repository.api, tables.apiBookingStatusSets?.data]);

  const getSets = useCallback(
    (
      type: number,
    ): {
      created_at: string;
      hotel_id: number;
      id: number;
      name: string;
      pms_statuses: number[];
      type: number;
      updated_at: string;
    } =>
      tables.apiBookingStatusSets?.data?.find(
        (item) => item.type === type && item.hotel_id === form.hotel,
      ),
    [form.hotel, tables.apiBookingStatusSets.data],
  );

  const SummaryStatus = useMemo(() => {
    return (
      <div className="flex flex-col mb-2">
        <span>
          <b>Hotel:</b> {hotelSelected?.name}
        </span>
        {statusList.map((name, index) => {
          return (
            <span className="flex flex-col" key={index}>
              <b>{name}: </b>
              <ul>
                {getSets(index)?.pms_statuses?.map((item, i: number) => {
                  const statusCode = statusCodesByHotel[name].find(
                    (element) => element.id === item,
                  );
                  return (
                    <li className="ml-2 text-sm" key={i}>
                      {`${statusCode?.label} - ${statusCode?.name} `}
                    </li>
                  );
                })}
              </ul>
            </span>
          );
        })}
      </div>
    );
  }, [hotelSelected?.name, statusCodesByHotel, getSets, statusList]);

  const steps: Step[] = useMemo(
    () => [
      {
        title: 'Hotel',
        subTitle: 'Select a Hotel',
        content: (
          <Select
            className="w-6/12"
            showSearch
            placeholder="Please select the hotel"
            filterOption={(input, option) => {
              if (option?.label && typeof option.label === 'string') {
                return (option.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }
              return true;
            }}
            value={form.hotel}
            onChange={(value) =>
              setForm((prevState) => ({
                ...prevState,
                hotel: value,
              }))
            }
            options={clientHotels.map((value) => {
              return {
                value: value.id,
                label: value.name,
              };
            })}
          />
        ),
      },
      {
        title: 'Booking status sets',
        subTitle: 'Select the sets by type',
        content: (
          <div className="flex flex-col w-full">
            <Rows
              hotelId={form.hotel || 0}
              title={'DEF'}
              type={0}
              codes={statusCodesByHotel.DEF}
            />
            <Rows
              hotelId={form.hotel || 0}
              title={'TEN'}
              type={1}
              codes={statusCodesByHotel.TEN}
            />
            <Rows
              hotelId={form.hotel || 0}
              title={'CXL'}
              type={2}
              codes={statusCodesByHotel.CXL}
            />
            <Rows
              hotelId={form.hotel || 0}
              title={'BLQ'}
              type={3}
              codes={statusCodesByHotel.BLQ}
            />
          </div>
        ),
      },
      {
        title: 'Overhaul',
        subTitle: 'Review all the details',
        content: SummaryStatus,
      },
      {
        title: 'Dispatch',
        subTitle: 'Dispatch AI set',
        content: <span>Save data</span>,
      },
    ],
    [statusCodesByHotel, form.hotel, clientHotels, SummaryStatus],
  );

  const saveData = useCallback(() => {
    setLoading(true);

    const sets = [
      {
        type: 0,
        pms_statuses: getSets(0)?.pms_statuses || [],
        id: getSets(0)?.id || null,
      },
      {
        type: 1,
        pms_statuses: getSets(1)?.pms_statuses || [],
        id: getSets(1)?.id || null,
      },
      {
        type: 2,
        pms_statuses: getSets(2)?.pms_statuses || [],
        id: getSets(2)?.id || null,
      },
      {
        type: 3,
        pms_statuses: getSets(3)?.pms_statuses || [],
        id: getSets(3)?.id || null,
      },
    ];

    sets.forEach(async (item, index) => {
      try {
        if (item.id) {
          await repository.api.updateBookingStatus(item.id, {
            hotel_id: form.hotel,
            ...item,
          });
        } else {
          await repository.api.createBookingStatus({
            hotel_id: form.hotel,
            ...item,
          });
        }

        if (index === 3) {
          setLoading(false);
          showAlert('Statuses will be created', 'success', 'OK');
          history.push('/');
        }
      } catch {
        showAlert('Error while saving', 'error', 'OK');
      }
    });
  }, [form.hotel, getSets, history, repository.api, showAlert]);

  return (
    <div className="flex bg-white p-4 overflow-hidden shadow-md dark:bg-ebonyClay relative">
      <BreadcrumbCreation
        currentStep={currentStep}
        steps={steps}
        loading={loading}
        onCancel={() => setCurrentStep((prevState) => prevState - 1)}
        onResolve={() => {
          if (currentStep === 0 && form.hotel === undefined) {
            showAlert('Please select a hotel', 'error', 'OK');

            return;
          }

          setCurrentStep((prevState) => {
            if (prevState < steps.length - 1) {
              return prevState + 1;
            }

            return prevState;
          });

          if (currentStep === steps.length - 1) {
            setOpenModalAction(!openModalAction);
          }
        }}
      />

      <ModalAction
        title={
          <div className="flex flex-col text-sm">
            <span>{hotelSelected?.name}</span>
            <span>You will update the booking status sets.</span>
          </div>
        }
        message="Do you want to proceed?"
        open={openModalAction}
        setOpen={() => setOpenModalAction(!openModalAction)}
        onSave={() => {
          setOpenModalAction(!openModalAction);
          saveData();
        }}
        onCancel={() => setOpenModalAction(!openModalAction)}
        buttonSaveName="Save"
        type="success"
      />
    </div>
  );
};

export default BookingStatusSets;
