import React, { useCallback, useState } from 'react';
import {
  DownloadIcon,
  LogoutIcon,
  MoonIcon,
  RefreshIcon,
  SunIcon,
} from '@heroicons/react/outline';
import { useAlert } from 'react-alert';
import { useUser } from '../../context/user';
import { Container, Icons } from './styles';
import { useTables } from '../../context/tables';
import { Tooltip } from 'antd';
import Modal from '../modal';

interface Props {
  collapsed: boolean;
}

const Header: React.FC<Props> = ({ collapsed }) => {
  const { isDark, setIsDark, logout, setRefresh } = useUser();
  const { setTables, tables } = useTables();
  const [openModal, setOpenModal] = useState(false);
  const [csvName, setCsvName] = useState('');
  const alert = useAlert();
  const getKey = useCallback(() => {
    let key = '';

    if (window.location.pathname === '/api/report/list') {
      key = 'reports';
    }

    if (window.location.pathname === '/api/files/list') {
      key = 'files';
    }

    if (window.location.pathname === '/api/market-segments/list') {
      key = 'marketSegments';
    }

    return key;
  }, []);

  const refreshTable = () => {
    const key = getKey();

    setTables({
      ...tables,
      [key]: {
        ...tables[key],
        data: [],
        pagination: { total: 10, pageSize: 10, current: 1 },
      },
    });

    setRefresh(Math.random() * 1000);
  };

  return (
    <>
      <Modal
        title="Export to csv"
        open={openModal}
        setOpen={(value: boolean) => {
          setOpenModal(value);

          if (!value) {
            setCsvName('');
          }
        }}>
        <div className="flex w-full flex-col">
          <div className="flex flex-col mt-4">
            <label
              htmlFor="code"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 dark:text-iron">
              Filename
            </label>
            <input
              type="text"
              name="csv"
              value={csvName}
              onChange={(event) => setCsvName(event?.target.value)}
              className="block w-full shadow-sm mt-1 sm:text-sm border-gray-300 dark:bg-transparent dark:text-white"
            />
          </div>

          <div className="flex justify-end mt-6">
            <button
              onClick={() => {
                setOpenModal(false);
                setCsvName('');
              }}
              type="button"
              className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-xs leading-4 font-medium text-gray-700 bg-white hover:bg-gray-50">
              Cancel
            </button>
            <button
              onClick={() => {
                alert.show('Generated csv will be sent by email', {
                  type: 'success',
                });
                setOpenModal(false);
                setCsvName('');
              }}
              type="button"
              className="inline-flex items-center px-3 py-2 ml-2 border border-indigo-600 text-xs leading-4 font-medium shadow-sm text-white bg-indigo-600 hover:bg-indigo-700">
              Export
            </button>
          </div>
        </div>
      </Modal>
      <Container
        className="dark:bg-ebonyClay"
        width={collapsed ? 'calc(100% - 70px)' : 'calc(100% - 260px)'}>
        <Icons>
          {getKey() && (
            <div className="flex">
              <Tooltip title="Download" placement="bottom">
                <DownloadIcon
                  onClick={() => setOpenModal(true)}
                  className="dark:text-iron"
                />
              </Tooltip>
              <Tooltip title="Refresh" placement="bottom">
                <RefreshIcon
                  onClick={() => refreshTable()}
                  className="dark:text-iron"
                />
              </Tooltip>
            </div>
          )}
          <div className="flex w-full justify-end">
            {isDark ? (
              <Tooltip title="Light mode" placement="bottom">
                <SunIcon
                  className="dark:text-iron"
                  onClick={() => setIsDark(!isDark)}
                />
              </Tooltip>
            ) : (
              <Tooltip title="Dark mode" placement="bottom">
                <MoonIcon
                  className="dark:text-iron"
                  onClick={() => setIsDark(!isDark)}
                />
              </Tooltip>
            )}
            <Tooltip title="Logout" placement="bottom">
              <LogoutIcon className="dark:text-iron" onClick={() => logout()} />
            </Tooltip>
          </div>
        </Icons>
      </Container>
    </>
  );
};

export default Header;
