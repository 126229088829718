import { CloudUploadIcon, TrashIcon } from '@heroicons/react/solid';
import React, { useCallback, useEffect, useState } from 'react';
import DragAndDrop from './DragAndDrop';

export interface FileUploadProps {
  limit?: number;
  limitSize?: number;
  extensions?: string[];
  onChange(files: File[]): void;
  onError?(errors: string[]): void;
}

const FileUpload: React.FC<FileUploadProps> = ({
  limit = 10,
  limitSize = 10,
  extensions = [],
  onChange,
  onError = () => false,
}) => {
  const [filesState, setFilesState] = useState([] as File[]);

  const [errors, setErrorsState] = useState([] as string[]);

  useEffect(() => {
    onError(errors);
  }, [onError, errors]);

  const deleteFile = useCallback(
    (file: File) => {
      const newState = filesState.filter((f) => f !== file);
      setFilesState(newState);
    },
    [filesState],
  );

  const renderNonImageFilePreview = useCallback(
    (file: File, index: number) => (
      <li key={index} className="block p-1">
        <article
          tabIndex={0}
          className="group rounded-md focus:outline-none focus:shadow-outline elative bg-gray-100 shadow-sm dark:bg-gray-700">
          <section className="flex items-center rounded-md text-xs break-words py-2 px-3 cursor-default">
            <span className="flex-1">{file.name}</span>
            <div className="flex items-center">
              <span className="p-1 size text-xs">{fileSize(file)}</span>
              <button
                className="delete ml-auto text-gray-600 focus:outline-none hover:bg-gray-300 p-1 rounded-md dark:text-white dark:hover:bg-gray-700"
                onClick={() => deleteFile(file)}>
                <TrashIcon className="h-5 w-5" />
              </button>
            </div>
          </section>
        </article>
      </li>
    ),
    [deleteFile],
  );

  const renderImageFilePreview = useCallback(
    (file: File, index: number) => (
      <li key={index} className="block p-1 w-32 h-24">
        <article
          tabIndex={0}
          className="group hasImage w-full h-full rounded-md focus:outline-none focus:shadow-outline bg-gray-100 relative text-transparent hover:text-white shadow-sm">
          <img
            alt="upload preview"
            src={URL.createObjectURL(file)}
            className="img-preview w-full h-full sticky object-cover rounded-md bg-fixed"
          />

          <section className="flex flex-col rounded-md text-xs break-words w-full h-full z-20 absolute top-0 py-2 px-3">
            <h1 className="flex-1">{file.name}</h1>
            <div className="flex">
              <span className="p-1">
                <i>
                  <svg
                    className="fill-current w-4 h-4 ml-auto pt-"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24">
                    <path d="M5 8.5c0-.828.672-1.5 1.5-1.5s1.5.672 1.5 1.5c0 .829-.672 1.5-1.5 1.5s-1.5-.671-1.5-1.5zm9 .5l-2.519 4-2.481-1.96-4 5.96h14l-5-8zm8-4v14h-20v-14h20zm2-2h-24v18h24v-18z" />
                  </svg>
                </i>
              </span>

              <p className="p-1 size text-xs">{fileSize(file)}</p>
              <button
                className="delete ml-auto focus:outline-none hover:bg-gray-300 p-1 rounded-md"
                onClick={() => deleteFile(file)}>
                <TrashIcon className="h-5 w-5 text-gray-600" />
              </button>
            </div>
          </section>
        </article>
      </li>
    ),
    [deleteFile],
  );

  const renderFilePreview = useCallback(
    (file: File, index: number) =>
      file.type.match('image.*')
        ? renderImageFilePreview(file, index)
        : renderNonImageFilePreview(file, index),
    [renderImageFilePreview, renderNonImageFilePreview],
  );

  const onFileSelection = useCallback(
    (files: FileList) => {
      const sizeErrs = [] as string[];
      const extErrs = [] as string[];

      if (filesState.length + files.length > limit) {
        setErrorsState([
          ...errors,
          `You have exceeded your file upload limit of ${limit}`,
        ]);
        return;
      }

      const newFiles = [] as File[];

      for (let i = 0; i < files.length; i++) {
        if (files[i].size >= limitSize * 1048576) {
          sizeErrs.push(files[i].name);
        } else if (
          extensions.length > 0 &&
          !extensions?.some((ext) => ext === files[i].name.split('.').pop())
        ) {
          extErrs.push(files[i].name);
        } else {
          newFiles.push(files[i]);
        }
      }

      const errs = [] as string[];
      if (sizeErrs.length) {
        errs.push(
          `Files: "${sizeErrs.join(
            ', ',
          )}" have exceeded file limit size of ${limitSize}MB`,
        );
      }
      if (extErrs.length) {
        errs.push(
          `Files: "${extErrs.join(
            ', ',
          )}" doesn't match any available file extentions ( ${extensions.join(
            ', ',
          )} )`,
        );
      }
      setErrorsState(errs);
      setFilesState([...filesState, ...newFiles]);
      onChange([...filesState, ...newFiles]);
    },
    [errors, extensions, filesState, limit, limitSize, onChange],
  );

  return (
    <DragAndDrop dropCallback={onFileSelection} DropOverlay={DropOverlay}>
      <div className="mt-1 sm:mt-0 sm:col-span-2 w-full">
        <div className="flex justify-center px-6 pt-5 pb-6 border-2 border-default border-dashed rounded-md">
          <div className="space-y-1 text-center">
            <CloudUploadIcon className="h-20 text-gray-400 mx-auto" />
            <div className="flex items-center text-sm">
              <label
                htmlFor="file-upload"
                className="relative flex items-center cursor-pointer text-white p-1 px-2 rounded-md font-medium dark:text-default focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 bg-gray-500 dark:bg-gray-800 dark:hover:text-white">
                <span>Select</span>
                <input
                  id="file-upload"
                  name="file-upload"
                  type="file"
                  accept={extensions
                    .map((extension) => `.${extension}`)
                    .join(',')}
                  className="sr-only"
                  onChange={(e: any) => onFileSelection(e.target.files)}
                />
              </label>
              <p className="pl-1 text-gray-600 dark:text-gray-200">
                or drag and drop a file
              </p>
            </div>
            <p className="text-xs text-gray-500 dark:text-gray-300">
              Maximum file size of {limitSize}MB
            </p>
          </div>
        </div>
      </div>
      {filesState.length > 0 && (
        <ul id="gallery" className="-m-1 mt-3">
          {Array.from(filesState).map(renderFilePreview)}
        </ul>
      )}
    </DragAndDrop>
  );
};

export default FileUpload;

//
// Utils
//

const fileSize = (file: File) =>
  file.size > 1024
    ? file.size > 1048576
      ? Math.round(file.size / 1048576) + 'mb'
      : Math.round(file.size / 1024) + 'kb'
    : file.size + 'b';

const DropOverlay = () => (
  <div
    style={{
      border: 'dashed grey 4px',
      backgroundColor: 'rgba(255,255,255,.8)',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 777,
    }}>
    <div
      style={{
        position: 'absolute',
        top: '50%',
        right: 0,
        left: 0,
        textAlign: 'center',
        color: 'grey',
        fontSize: 36,
      }}>
      {/* <div>drop here :)</div> */}
    </div>
  </div>
);
