import React, { useState, Fragment } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { NavLink } from 'react-router-dom';
import Cribs from './cribs';
import Custom1 from './custom-1';
import Custom2 from './custom-2';
import ExtraBeds from './extra-beds';
import PMSCharges from './pms-charges';
import Rrev from './rrev';
import Trev from './trev';

interface Props {
  match: any;
}

const ResourceInventory: React.FC<Props> = ({ match }) => {
  const [currentId] = useState('1');
  const routes = [
    {
      title: '',
      paths: [
        {
          to: `${match.url}/pms-charges/${currentId}`,
          label: 'PMS Charges',
        },
      ],
    },
    {
      title: 'Extra Charges configuration',
      paths: [
        {
          to: `${match.url}/extra-beds/${currentId}`,
          label: 'Extra Beds',
        },
        {
          to: `${match.url}/cribs/${currentId}`,
          label: 'Cribs',
        },
      ],
    },
    {
      title: 'Revenue Configuration',
      paths: [
        {
          to: `${match.url}/rrev/${currentId}`,
          label: 'RREV',
        },
        {
          to: `${match.url}/trev/${currentId}`,
          label: 'TREV',
        },
        {
          to: `${match.url}/custom-1/${currentId}`,
          label: 'Custom 1',
        },
        {
          to: `${match.url}/custom-2/${currentId}`,
          label: 'Custom 2',
        },
      ],
    },
  ];

  return (
    <div className="flex w-full relative items-start">
      <div className="flex flex-col w-3/12">
        {routes.map((item, index) => {
          return (
            <Fragment key={index}>
              {item.title && (
                <p className="font-semibold dark:text-white text-xs my-4">
                  {item.title}
                </p>
              )}
              <div className="flex flex-col">
                {item.paths.map(({ to, label }, index: number) => (
                  <NavLink
                    key={index}
                    activeClassName="bg-default text-white font-bold shadow-sm hover:bg-indigo-700"
                    className="inline-flex items-center px-2.5 py-2 border border-transparent text-xs hover:bg-default hover:text-white hover:font-bold dark:text-white"
                    to={to}>
                    {label}
                  </NavLink>
                ))}
              </div>
            </Fragment>
          );
        })}
      </div>

      <div className="ml-6 w-9/12">
        <Switch>
          <Redirect
            exact
            from={match.url}
            to={`${match.url}/pms-charges/${currentId}`}
          />

          <Route path={`${match.url}/pms-charges/:id`} component={PMSCharges} />
          <Route path={`${match.url}/cribs/:id`} component={Cribs} />
          <Route path={`${match.url}/extra-beds/:id`} component={ExtraBeds} />
          <Route path={`${match.url}/trev/:id`} component={Trev} />
          <Route path={`${match.url}/rrev/:id`} component={Rrev} />
          <Route path={`${match.url}/custom-1/:id`} component={Custom1} />
          <Route path={`${match.url}/custom-2/:id`} component={Custom2} />
        </Switch>
      </div>
    </div>
  );
};

export default ResourceInventory;
