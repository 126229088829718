import ApiService from '../../../../api-service';

interface Payload {
  emails: string[];
}

export default class AutopilotEmailsRepository {
  api = ApiService;

  getAutopilotEmails(hotelId: number) {
    return this.api.get(
      `api/support/price-recommendation/autopilot/${hotelId}/emails`,
    );
  }

  addAutopilotEmails(hotelId: number, payload: Payload) {
    return this.api.put(
      `api/support/price-recommendation/autopilot/${hotelId}/emails`,
      payload,
    );
  }
}
