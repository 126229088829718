import React, { useCallback, useMemo, useState } from 'react';
import BreadcrumbCreation, {
  Step,
} from '../../../../components/breadcrumb-creation';
import { Select, Skeleton, Switch } from 'antd';
import Repository from '../../../../config/repository';
import { Hotel, useUser } from '../../../../context/user';
import ModalAction from '../../../../components/modal-action';
import { useHistory } from 'react-router';
import { AxiosResponse } from 'axios';

const PriceRecommendation: React.FC = () => {
  const repository = new Repository();
  const { clientHotels, showAlert } = useUser();
  const history = useHistory();

  const [currentStep, setCurrentStep] = useState(0);
  const [hotelId, setHotelId] = useState(undefined);
  const [priceRecommendation, setPriceRecommendation] = useState(false);
  const [openModalAction, setOpenModalAction] = useState(false);
  const [loading, setLoading] = useState(true);

  const hotelOptions = useMemo(
    () => clientHotels.map((value) => ({ value: value.id, label: value.name })),
    [clientHotels],
  );

  const hotelSelected = useMemo(
    () => clientHotels.find((hotel) => hotel.id === hotelId),
    [hotelId, clientHotels],
  );

  const steps: Step[] = useMemo(
    () => [
      {
        title: 'Hotel',
        subTitle: 'Select a Hotel',
        content: (
          <Select
            className="w-6/12"
            showSearch
            placeholder="Please select the hotel"
            filterOption={(input, option) => {
              if (option?.label && typeof option.label === 'string') {
                return (option.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }
              return true;
            }}
            value={hotelId}
            onChange={(value) => setHotelId(value)}
            options={hotelOptions}
          />
        ),
      },
      {
        title: 'Price Recommendation',
        subTitle: 'Activate or Deactivate Price Recommendation',
        content: (
          <>
            {loading ? (
              <Skeleton />
            ) : (
              <div className="flex flex-col w-full">
                <span className="pb-2">
                  Activate/Deactivate Price Recommendation
                </span>
                <div className="flex items-center pb-4">
                  <Switch
                    checked={priceRecommendation}
                    checkedChildren="Active"
                    unCheckedChildren="Inactive"
                    onChange={(event) => setPriceRecommendation(event)}
                  />
                </div>
              </div>
            )}
          </>
        ),
      },
    ],
    [hotelId, hotelOptions, loading, priceRecommendation],
  );

  const save = useCallback(
    async () => {
      try {
        if (!hotelId) return;

        const payload = {
          price_recommendation: priceRecommendation,
        };

        await repository.priceRecommendationRepository.updatePriceRecommendation(
          hotelId,
          payload,
        );

        const value = priceRecommendation ? 'activated' : 'deactivated';
        showAlert(
          `Price Recommendation will be ${value} to this hotel`,
          'success',
          'OK',
        );
        history.push('/');
      } catch (error: any) {
        showAlert(
          error.response ? error.response.data.message : 'Error saving changes',
          'error',
          'OK',
        );
        setCurrentStep(0);
      }
    },
    [hotelId, priceRecommendation, history], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const fetchHotel = async (hotelId: string) => {
    try {
      setPriceRecommendation(false);
      setLoading(true);

      const response: AxiosResponse<Hotel> = await repository.global.fetchHotel(
        hotelId,
      );

      setPriceRecommendation(response.data.price_recommendation);
    } catch {}
    setLoading(false);
  };

  return (
    <div className="flex bg-white p-4 overflow-hidden shadow-md dark:bg-ebonyClay relative">
      <BreadcrumbCreation
        currentStep={currentStep}
        steps={steps}
        onCancel={() => setCurrentStep((prevState) => prevState - 1)}
        onResolve={() => {
          if (currentStep === 0 && hotelId === undefined) {
            showAlert('Please select a hotel', 'error', 'OK');

            return;
          }

          setCurrentStep((prevState) => {
            if (prevState < steps.length - 1) {
              hotelId && fetchHotel(hotelId);

              return prevState + 1;
            }

            return prevState;
          });

          if (currentStep === steps.length - 1) {
            setOpenModalAction(!openModalAction);
          }
        }}
      />

      <ModalAction
        title={
          <div className="flex flex-col text-sm">
            <span>{hotelSelected?.name}</span>
            <span>
              You will {priceRecommendation ? 'activate' : 'deactivate'} the
              price recommendation to this hotel.
            </span>
          </div>
        }
        message="Do you want to proceed?"
        open={openModalAction}
        setOpen={() => setOpenModalAction(!openModalAction)}
        onSave={() => {
          setOpenModalAction(!openModalAction);
          save();
        }}
        onCancel={() => setOpenModalAction(!openModalAction)}
        buttonSaveName="Save"
        type="success"
      />
    </div>
  );
};

export default PriceRecommendation;
