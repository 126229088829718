/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { PencilIcon, TrashIcon } from '@heroicons/react/outline';
import { Alert } from 'antd';

import Repository from '../../../../config/repository';

import Table from '../../../../components/table';
import ModalAction from '../../../../components/modal-action';
import Filter from '../../../../components/filter';
import { useUser } from '../../../../context/user';
import { useTables } from '../../../../context/tables';

interface Columun {
  filter_key: string;
  sort_key: string;
  data_index: string;
  dataIndex: string;
  title: string;
  is_searchable: boolean;
  items: any[];
  search_type: string;
  value: any;
  cls: string;
  render: (record: any) => React.ReactNode;
}

interface Props {
  history: any;
}

const List: React.FC<Props> = ({ history }) => {
  const { refresh } = useUser();
  const { tables, currentTable, setTables, setCurrentTable } = useTables();
  const repository = new Repository();
  const [columns, setColumns] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [showAlert, setShowAlert] = useState(false);
  const [currentItem, setCurrentItem] = useState({ id: '', name: '' });
  const [openModalAction, setOpenModalAction] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 10,
  });
  const [currentQueryParams, setCurrentQueryParams] = useState({});

  const getMarketSegmentsSchema = useCallback(() => {
    repository.api.getMarketSegmentsSchema().then((res) => {
      res.data.map((item: Columun) => {
        item.dataIndex = item.data_index;

        if (item.data_index === 'hotel_ids') {
          item.dataIndex = 'hotel';

          item.render = (record) => {
            return <span>{record.name}</span>;
          };
        }

        if (item.search_type === 'toggle_switch') {
          item.cls = 'w-20 order-last';

          return item;
        }

        if (item.search_type === 'checkbox') {
          item.value = [];
        }

        if (item.data_index === 'is_group') {
          item.render = (record) => {
            return <span>{record ? 'Group' : 'Individual'}</span>;
          };
        }

        if (item.data_index === 'status') {
          item.render = (record) => {
            return <span>{record === 1 ? 'Active' : 'Inactive'}</span>;
          };
        }

        item.cls = 'w-4/12';

        return item;
      });

      setColumns(res.data);
    });
  }, [columns]);

  const getMarketSegments = useCallback(
    (
      current = pagination.current,
      pageSize = pagination.pageSize,
      queryParams = {},
    ) => {
      setCurrentQueryParams(queryParams);

      repository.api.getMarketSegments(current, pageSize, queryParams).then(
        (res) => {
          setPagination({
            ...res.data.meta.pagination,
            current: res.data.meta.pagination.current_page,
            pageSize: res.data.meta.pagination.per_page,
            total: res.data.meta.pagination.total || 10,
          });
          setData(res.data.data);
          setLoading(false);
        },
        () => {
          setLoading(false);
        },
      );
    },
    [data, pagination],
  );

  const onchangeTable = useCallback(
    (value: any, filter: any, sort: any) => {
      let by = null;
      let order = null;

      if (sort.column) {
        by = sort.column.sort_key;
        order = sort.order === 'ascend' ? 'asc' : 'desc';
      }

      setPagination(value);
      setLoading(true);
      getMarketSegments(value.current, value.pageSize, {
        ...currentQueryParams,
        'sort[by]': by,
        'sort[order]': order,
      });
    },
    [pagination],
  );

  const renderAction = (record: any) => {
    return (
      <div className="flex justify-center">
        <PencilIcon
          onClick={() => history.push(`/api/market-segments/${record.id}`)}
          className="w-4 h-4 cursor-pointer"
        />
        <TrashIcon
          onClick={() => {
            setCurrentItem(record);
            setOpenModalAction(true);
          }}
          className="w-4 h-4 cursor-pointer ml-2"
        />
      </div>
    );
  };

  const deleteMarketSegment = () => {
    setOpenModalAction(false);

    repository.api.deleteMarketSegments(currentItem.id).then(
      () => {
        alertChange();
        setLoading(true);
        getMarketSegments(
          tables.marketSegments.pagination.current,
          tables.marketSegments.pagination.pageSize,
          currentQueryParams,
        );
      },
      () => {
        //
      },
    );
  };

  const alertChange = () => {
    setShowAlert(true);

    setTimeout(() => {
      setShowAlert(false);
    }, 1400);
  };

  const changeColumn = useCallback(
    (value: any, column: any) => {
      setColumns(
        columns.filter((item: any) => {
          if (item.filter_key !== column.filter_key) {
            return item;
          }

          if (item.filter_key === 'status' || item.filter_key === 'is_group') {
            item.value[value.position] = { ...value };
          } else {
            item.value = value;
          }

          return item;
        }),
      );
    },
    [columns],
  );

  const search = () => {
    let queryParams: any = {};

    columns.map((item: any) => {
      if (!item.value || item.value.length === 0) {
        delete queryParams[item.filter_key];

        return item;
      }

      queryParams = {
        ...queryParams,
        [item.filter_key]: item.value,
      };

      if (item.filter_key === 'created_at') {
        queryParams['created_at[start_date]'] = formatDate(item.value[0]);

        queryParams['created_at[end_date]'] = formatDate(item.value[1]);

        delete queryParams[item.filter_key];
      }

      if (item.filter_key === 'status' || item.filter_key === 'is_group') {
        queryParams[item.filter_key] = [];

        item.value.map((value: any) => {
          if (value.checked) {
            queryParams[item.filter_key].push(value.value);
          }

          return value;
        });
      }

      return item;
    });

    setLoading(true);
    setTables({
      ...tables,
      marketSegments: { ...tables.marketSegments, columns },
    });
    getMarketSegments(1, 10, queryParams);
  };

  const reset = () => {
    setColumns(
      columns.filter((item: any) => {
        if (item.search_type === 'checkbox') {
          item.value = [];

          return item;
        }

        if (item.value) {
          delete item.value;
        }

        return item;
      }),
    );

    setLoading(true);
    getMarketSegments();
  };

  const formatDate = (value: string) => {
    return moment(value).format('Y-MM-DD');
  };

  useEffect(() => {
    setColumns(tables.marketSegments.columns);
    setPagination(tables.marketSegments.pagination);
    setData(tables.marketSegments.data);
    setLoading(false);

    if (tables.marketSegments.columns.length === 0) {
      getMarketSegmentsSchema();
    }

    if (tables.marketSegments.data.length === 0) {
      setLoading(true);
      getMarketSegments(
        tables.marketSegments.pagination.current,
        tables.marketSegments.pagination.pageSize,
        currentQueryParams,
      );
    }
  }, [refresh]);

  useEffect(() => {
    setTables({
      ...tables,
      marketSegments: {
        columns,
        data,
        pagination,
      },
    });
  }, [columns, data, pagination]);

  useEffect(() => {
    if (currentTable !== 'marketSegments') return;

    setLoading(true);
    getMarketSegments(
      tables.marketSegments.pagination.current,
      tables.marketSegments.pagination.pageSize,
      currentQueryParams,
    );
    setCurrentTable('');
  }, [currentTable]);

  return (
    <div className="relative bg-white p-4 pb-0 overflow-hidden shadow-md dark:bg-ebonyClay">
      <div className="-mx-2">
        <Filter
          inputs={columns}
          sendValue={changeColumn}
          onReset={reset}
          onSearch={search}
        />
      </div>

      <Table
        data={data}
        columns={columns}
        pagination={pagination}
        loading={loading}
        rowKey="id"
        onChange={onchangeTable}
        renderTableActions={(record: any) => renderAction(record)}
        hasAddnew={true}
        history={history}
        addNewRouter={'/api/market-segments/new'}
      />

      <ModalAction
        title={`You will delete the item - ${currentItem.name}`}
        message="Do you confirm the action?"
        open={openModalAction}
        setOpen={() => setOpenModalAction(!openModalAction)}
        onSave={() => deleteMarketSegment()}
        onCancel={() => setOpenModalAction(!openModalAction)}
      />
      {showAlert && (
        <Alert
          className="fixed right-0 top-0 mt-20 mr-4"
          message="Saved Changes"
          type="success"
          showIcon
        />
      )}
    </div>
  );
};

export default List;
