import React, { useCallback, useContext, useMemo, useState } from 'react';
import Papa from 'papaparse';
import { useHistory, useLocation } from 'react-router';

interface Props {}

export interface TablesMap {
  reports: Table;
  files: Table;
  marketSegments: Table;
  bookingStatusSets: Omit<Table, 'pagination' | 'columns'>;
  apiBookingStatusSets: Omit<Table, 'pagination' | 'columns'>;
  rateShopper: Table;
  [type: string]: Partial<Table>;
}

export type ContextValue = {
  tables: TablesMap;
  currentTable: string;
  setCurrentTable: React.Dispatch<React.SetStateAction<string>>;
  refreshTable: (type: string) => void;
  setTables: React.Dispatch<React.SetStateAction<TablesMap>>;
  downloadCsv: (currentTable: string, fileName: string) => void;
};

export const TablesContext = React.createContext<ContextValue | undefined>(
  undefined,
);

export const TablesProvider: React.FC<Props> = (props) => {
  const location = useLocation();
  const history = useHistory();
  const [currentTable, setCurrentTable] = useState('');
  const [tables, setTables] = useState<TablesMap>({
    reports: {
      data: [],
      columns: [],
      pagination: { current: 1, pageSize: 10, total: 0 },
    },
    files: {
      data: [],
      columns: [],
      pagination: { current: 1, pageSize: 10, total: 0 },
    },
    marketSegments: {
      data: [],
      columns: [],
      pagination: { current: 1, pageSize: 10, total: 0 },
    },
    bookingStatusSets: {
      data: [],
    },
    apiBookingStatusSets: {
      data: [],
    },
    rateShopper: {
      data: [],
      columns: [],
      pagination: { current: 1, pageSize: 10, total: 0 },
    },
  });

  const downloadCsv = useCallback(
    (currentTable: string, fileName: string) => {
      const contactsCsvContent = Papa.unparse(
        tables[currentTable].data as any[],
        {
          delimiter: ';',
        },
      );

      const a = document.createElement('a');

      let downloadLink = window.URL.createObjectURL(
        new Blob([contactsCsvContent], {
          type: 'text/csv',
        }),
      );

      a.href = downloadLink;
      a.download = `${fileName}.csv`;
      a.click();
    },
    [tables],
  );

  const refreshTable = useCallback(
    (type: string) => {
      setCurrentTable(type);

      const splitPath = location.pathname.split('/');

      if (splitPath[splitPath.length - 1] !== 'list') {
        history.push(`/${splitPath[1]}/${splitPath[2]}/list`);
      }
    },
    [history, location.pathname],
  );

  const value = useMemo(
    () => ({
      tables,
      setTables,
      downloadCsv,
      refreshTable,
      currentTable,
      setCurrentTable,
    }),
    [
      tables,
      setTables,
      downloadCsv,
      refreshTable,
      currentTable,
      setCurrentTable,
    ],
  );

  return <TablesContext.Provider value={value} {...props} />;
};

export const useTables = (): ContextValue => {
  const context = useContext(TablesContext);

  if (context === undefined) {
    throw new Error('useTables must be used within an TablesProvider');
  }

  return context;
};

//
// Utils
//

interface Table {
  data: any[];
  columns: any[];
  pagination: { current: number; pageSize: number; total: number };
}
