import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronRightIcon,
} from '@heroicons/react/outline';
import { Spin } from 'antd';
import React from 'react';
import classNames from '../../utils/classNames';

interface Props {
  currentStep: number;
  steps: Step[];
  onCancel: () => void;
  onResolve: () => void;
  loading?: boolean;
}

export interface Step {
  title: string;
  subTitle: string;
  content: React.ReactNode;
  hiddenPrev?: boolean;
  hiddenNext?: boolean;
  disabledPrev?: boolean;
  disabledNext?: boolean;
}

const BreadcrumbCreation: React.FC<Props> = ({
  steps,
  currentStep,
  onCancel,
  onResolve,
  loading,
}) => {
  return (
    <div className="flex w-full flex-col">
      {loading && (
        <span className="absolute w-full h-full flex justify-center items-center left-0 top-0 z-20">
          <Spin className="z-10" size="large" />
          <span className="w-full h-full bg-gray-400 opacity-40 absolute" />
        </span>
      )}
      <div className="flex items-center">
        {steps.map((item, index) => (
          <div
            className="flex flex-1 items-center justify-between mr-4"
            key={index}>
            <div className="flex items-center">
              <span
                className={classNames(
                  currentStep >= index
                    ? 'bg-default'
                    : 'bg-gray-400 dark:bg-gray-700',
                  'flex justify-center text-white font-semibold items-center px-4 flex-shrink-0 rounded-sm h-10',
                )}>
                {index + 1}
              </span>
              <div className="flex flex-col ml-2">
                <span
                  className={classNames(
                    currentStep >= index
                      ? 'dark:text-default text-default'
                      : 'dark:text-gray-400',
                    'font-bold text-sm',
                  )}>
                  {item.title}
                </span>
                <span className="text-xs font-medium">{item.subTitle}</span>
              </div>
            </div>

            {index !== steps.length - 1 && (
              <ChevronRightIcon className="dark:text-gray-500 w-4" />
            )}
          </div>
        ))}
      </div>

      <div className="mt-6">{steps[currentStep].content}</div>

      <div className={classNames('flex justify-between mt-4')}>
        {!steps[currentStep].hiddenPrev && (
          <button
            onClick={() => (!steps[currentStep].disabledPrev && currentStep > 0) && onCancel()}
            type="button"
            className={classNames(
              (currentStep === 0 || steps[currentStep].disabledPrev) &&
                'cursor-not-allowed bg-transparent opacity-75 dark:bg-transparent dark:text-white text-black',
              'inline-flex items-center px-3 outline-none hover:opacity-90 py-2 border border-gray-300 shadow-sm text-xs leading-4 font-medium text-gray-700 bg-white hover:bg-gray-50',
            )}>
            <ArrowLeftIcon className="w-3 h-3 mr-1" /> Back
          </button>
        )}

        {!steps[currentStep].hiddenNext && (
          <button
            onClick={() => !loading && onResolve()}
            type="button"
            disabled={steps[currentStep].disabledNext}
            className={classNames(
              loading && currentStep === steps.length - 1 && 'opacity-75',
              currentStep === steps.length - 1
                ? 'bg-green-400 border-green-400 hover:opacity-90'
                : 'border-indigo-600 bg-indigo-600 hover:bg-indigo-700',
              'inline-flex items-center outline-none px-3 py-2 ml-2 border text-xs leading-4 font-medium shadow text-white',
            )}>
            {currentStep === steps.length - 1 ? (
              loading ? (
                'Saving...'
              ) : (
                'Save'
              )
            ) : (
              <>
                Next <ArrowRightIcon className="w-3 h-3 ml-1" />
              </>
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default BreadcrumbCreation;
