import React, { useCallback, useEffect, useMemo, useState } from 'react';
import BreadcrumbCreation, {
  Step,
} from '../../../components/breadcrumb-creation';
import { DatePicker, Select } from 'antd';
import Repository from '../../../config/repository';
import { useUser } from '../../../context/user';
import FileUpload from '../../../components/FileUpload';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { Moment } from 'moment';
import { useHistory } from 'react-router';

interface Props {}

enum CollectionSection {
  Bookings = 1,
  BookingsCharges = 2,
  BookingsChargesFinal = 3,
  Events = 4,
  Availability = 5,
  Outlier = 6,
  Rooms = 7,
  RoomRateValues = 8,
  Competitors = 9,
}

enum TrainSection {
  RoomNightsInputSubDatasets = 1,
  RoomNightsInputDataset = 2,
  AverageRoomRevenueInputSubDatasets = 3,
  AverageRoomRevenueInputDataset = 4,
  PriceRecommendationInputDataset = 5,
}

enum ForecastSection {
  RoomNightsInputDataset = 1,
  AverageRoomRevenueInputDataset = 2,
  PriceRecommendationInputDataset = 3,
}

enum MetricsSection {
  RoomNightsAbsoluteError = 1,
  AverageRoomRevenueAbsoluteError = 2,
  RoomNightsCentralMeasure = 3,
  AverageRoomRevenueCentralMeasure = 4,
}

const ForecastHotelSetup: React.FC<Props> = () => {
  const repository = new Repository();
  const history = useHistory();
  const { hotels, showAlert } = useUser();
  const MySwal = withReactContent(Swal);

  const [currentStep, setCurrentStep] = useState(0);
  const [nextStep, setNextStep] = useState(0);
  const [form, setForm] = useState<{
    hotel: number | undefined;
    file: File | null;
  }>({
    hotel: undefined,
    file: null,
  });
  const [canProceed, setCanProceed] = useState(true);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState<null | Moment>(null);
  const [inputBookingStatusSet, setInputBookingStatusSet] = useState<any>([]);
  const [error, setError] = useState(false);

  const filteredHotels = useMemo(
    () => hotels.filter((hotel) => hotel.is_client),
    [hotels],
  );

  const initialCollectionState = useMemo(() => {
    return [
      { section: CollectionSection.Bookings, status: 0 },
      { section: CollectionSection.BookingsCharges, status: 0 },
      { section: CollectionSection.BookingsChargesFinal, status: 0 },
      { section: CollectionSection.Events, status: 0 },
      { section: CollectionSection.Availability, status: 0 },
      { section: CollectionSection.Outlier, status: 0 },
      { section: CollectionSection.Rooms, status: 0 },
      { section: CollectionSection.RoomRateValues, status: 0 },
      { section: CollectionSection.Competitors, status: 0 },
    ];
  }, []);
  const initialTrainState = useMemo(() => {
    return [
      { section: TrainSection.RoomNightsInputSubDatasets, status: 0 },
      { section: TrainSection.RoomNightsInputDataset, status: 0 },
      { section: TrainSection.AverageRoomRevenueInputSubDatasets, status: 0 },
      { section: TrainSection.AverageRoomRevenueInputDataset, status: 0 },
      { section: TrainSection.PriceRecommendationInputDataset, status: 0 },
    ];
  }, []);
  const initialForecastState = useMemo(() => {
    return [
      { section: ForecastSection.RoomNightsInputDataset, status: 0 },
      { section: ForecastSection.AverageRoomRevenueInputDataset, status: 0 },
      { section: ForecastSection.PriceRecommendationInputDataset, status: 0 },
    ];
  }, []);
  const initialMetricsState = useMemo(() => {
    return [
      { section: MetricsSection.RoomNightsAbsoluteError, status: 0 },
      { section: MetricsSection.AverageRoomRevenueAbsoluteError, status: 0 },
      { section: MetricsSection.RoomNightsCentralMeasure, status: 0 },
      { section: MetricsSection.AverageRoomRevenueCentralMeasure, status: 0 },
    ];
  }, []);

  const [collectionStateButtons, setCollectionStateButtons] = useState([
    ...initialCollectionState,
  ]);
  const [readyStateButtons, setReadyStateButtons] = useState([
    {
      section: 1,
      status: 0,
    },
  ]);
  const [trainStateButtons, setTrainStateButtons] = useState([
    ...initialTrainState,
  ]);
  const [forecastStateButtons, setForecastStateButtons] = useState([
    ...initialForecastState,
  ]);
  const [metricsStateButtons, setMetricsStateButtons] = useState([
    ...initialMetricsState,
  ]);

  const optionsButton = useMemo(() => {
    return [
      { id: 0, name: 'Generate' },
      { id: 1, name: 'Generating...' },
      { id: 2, name: 'Generated' },
    ];
  }, []);
  const optionsButtonActivate = useMemo(() => {
    return [
      { id: 0, name: 'Activate' },
      { id: 1, name: 'Activating...' },
      { id: 2, name: 'Activated' },
    ];
  }, []);

  const hotelSelected = useMemo(
    () => hotels.find((hotel) => hotel.id === form.hotel),
    [form.hotel, hotels],
  );

  useEffect(() => {
    if (hotelSelected && currentStep === 0) {
      setCanProceed(false);
      getCurrentStatusSetup(hotelSelected.id);
    }
  }, [hotelSelected, currentStep]); // eslint-disable-line react-hooks/exhaustive-deps

  const getCurrentStatusSetup = useCallback(async (hotelSelected: number) => {
    try {
      setLoading(true);
      const response = await repository.aiForecast.getCurrentStatusSetup(
        hotelSelected,
      );

      const inProgress = canGoNext(response);
      setNextStep(response.data?.step - 1);

      if (response.data?.progress) {
        response.data?.step === 4 &&
          setCollectionStateButtons([...response.data?.progress]);
        response.data?.step === 5 &&
          setReadyStateButtons([...response.data?.progress]);
        response.data?.step === 6 &&
          setTrainStateButtons([...response.data?.progress]);
        response.data?.step === 7 &&
          setForecastStateButtons([...response.data?.progress]);
        response.data?.step === 8 &&
          setMetricsStateButtons([...response.data?.progress]);
      }

      if (response.data.hotel_id) {
        MySwal.fire({
          background: '#283045',
          color: 'mirage',
          position: 'center',
          title: (
            <span className="text-sm block">
              This hotel already has an process running. Do you want to proceed?
            </span>
          ),
          confirmButtonText: 'Continue',
          showCancelButton: true,
          cancelButtonText: 'Restart',
          allowOutsideClick: false,
        }).then((result: any) => {
          if (result.isConfirmed) {
            // Continue
            setCurrentStep(response.data.step - 1);
          } else if (result.isDismissed) {
            // Restart
            restartProccess(hotelSelected);
          }
        });
      }
      setCanProceed(true);
      setLoading(false);
      inProgress && setError(true);
    } catch {}
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const canGoNext = useCallback((response: any) => {
    let inProgress = true;
    if (!response.data.length) inProgress = true;
    if (response.data?.progress) {
      inProgress = response.data?.progress.some((it: any) => it.status === 1);
    }
    return inProgress;
  }, []);

  const restartProccess = useCallback(async (hotelSelected: number) => {
    try {
      const response = await repository.aiForecast.restartProccess(
        hotelSelected,
      );

      setCurrentStep(response.data.step - 1);
      setCollectionStateButtons([...initialCollectionState]);
    } catch (error: any) {
      setError(true);
      showAlert(error?.response?.data?.message, 'error', 'OK');
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const startProccess = useCallback(
    async (
      hotelSelected: number,
      payload: any,
      setState?: Function,
      section?: CollectionSection,
    ) => {
      try {
        setLoading(true);
        const response = await repository.aiForecast.startProccess(
          hotelSelected,
          payload,
        );

        const inProgress = canGoNext(response);
        inProgress ? setError(true) : setError(false);

        if (currentStep === 0) {
          setCurrentStep(nextStep ? nextStep : response.data?.step - 1);
        } else {
          setCurrentStep(response.data?.step - 1);
        }

        section &&
          setState &&
          setState((prevState: any) => [
            ...prevState.map((it: any) => {
              if (it.section === section) it.status = 1;
              return it;
            }),
          ]);

        setLoading(false);
        setDate(null);
      } catch (error: any) {
        setError(true);
        setLoading(false);
        showAlert(error?.response?.data?.message, 'error', 'OK');
      }
    },
    [nextStep, currentStep, repository.aiForecast, canGoNext, showAlert],
  ); // eslint-disable-line react-hooks/exhaustive-deps

  const showNameButton = useCallback(
    (key, options = optionsButton) => {
      const optionButton = options.filter((it: any) => it.id === key);
      return optionButton[0].name;
    },
    [optionsButton],
  );

  const onClick = useCallback(
    (section, setState) => {
      hotelSelected &&
        startProccess(
          hotelSelected.id,
          {
            step: currentStep + 1,
            section: section,
            from_date: date?.format('Y-MM-DD'),
          },
          setState,
          section,
        );
    },
    [currentStep, date, hotelSelected, startProccess],
  );

  const steps: Step[] = useMemo(
    () => [
      {
        title: 'Hotel',
        subTitle: 'Select a Hotel',
        content: (
          <Select
            className="w-6/12"
            showSearch
            placeholder="Please select the hotel"
            filterOption={(input, option) => {
              if (option?.label && typeof option.label === 'string') {
                return (option.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }
              return true;
            }}
            value={form.hotel}
            onChange={(value) =>
              setForm((prevState) => ({
                ...prevState,
                hotel: value,
              }))
            }
            options={filteredHotels.map((value) => {
              return {
                value: value.id,
                label: value.name,
              };
            })}
          />
        ),
        disabledPrev: true,
        disabledNext: !canProceed,
      },
      {
        title: 'Configuration',
        subTitle: 'Basic Inicial Configuration',
        content: (
          <div className="flex flex-col w-full">
            <span className="pb-2">Outlier</span>
            <FileUpload
              onChange={(files) => {
                setForm((prevState) => ({
                  ...prevState,
                  file: files[0],
                }));
              }}
              limit={1}
              limitSize={10}
              extensions={['csv']}
            />

            <span className="pt-6 pb-2">Booking Status Set</span>
            <Select
              mode="tags"
              style={{ width: '100%' }}
              placeholder="Please select"
              dropdownStyle={{ display: 'none' }}
              onChange={(event: any) => {
                setInputBookingStatusSet(event);
              }}></Select>

            <span className="text-base mr-4 pt-4 pb-2">Opening Date:</span>
            <DatePicker
              className="w-6/12 text-sm"
              placeholder="Select a date"
              value={date}
              onChange={(date) => {
                setDate(date);
              }}
            />
          </div>
        ),
        disabledPrev: true,
      },
      {
        title: 'Events',
        subTitle: 'Upload CSV file',
        content: (
          <div className="flex flex-col w-full">
            <span className="pt-4 pb-2">Events</span>
            <FileUpload
              onChange={(files) => {
                setForm((prevState) => ({
                  ...prevState,
                  file: files[1] || files[0],
                }));
              }}
              limit={2}
              limitSize={10}
              extensions={['csv']}
            />
          </div>
        ),
        disabledPrev: true,
      },
      {
        title: 'Collections',
        subTitle: 'Generate Collections from API and ETL',
        content: (
          <div className="flex flex-col">
            <div>
              <span className="text-base mr-4">
                <b>From Date:</b>
              </span>
              <DatePicker
                className="w-6/12 text-sm"
                placeholder="Select a date"
                value={date}
                onChange={(date) => {
                  setDate(date);
                }}
              />
            </div>
            <span className="text-base pt-4">
              <b>ETL</b>
            </span>
            <span className="flex flex-col">
              <span className="pb-2 pt-3">
                Bookings
                <button
                  onClick={() =>
                    onClick(
                      CollectionSection.Bookings,
                      setCollectionStateButtons,
                    )
                  }
                  type="button"
                  className={`px-3 py-2 border text-xs font-medium float-right text-white ${
                    collectionStateButtons[CollectionSection.Bookings - 1]
                      .status === 0 &&
                    'border-indigo-600 bg-indigo-600 hover:bg-indigo-700 outline-none'
                  } ${
                    collectionStateButtons[CollectionSection.Bookings - 1]
                      .status === 1 &&
                    'border-red-500 bg-red-500 outline-none pointer-events-none'
                  } ${
                    collectionStateButtons[CollectionSection.Bookings - 1]
                      .status === 2 &&
                    'border-green-600 bg-green-600 pointer-events-none'
                  }`}>
                  {showNameButton(
                    collectionStateButtons[CollectionSection.Bookings - 1]
                      .status,
                  )}
                </button>
              </span>
              <span className="pb-2">
                Bookings Charges
                <button
                  onClick={() =>
                    onClick(
                      CollectionSection.BookingsCharges,
                      setCollectionStateButtons,
                    )
                  }
                  type="button"
                  className={`px-3 py-2 border text-xs font-medium float-right text-white ${
                    collectionStateButtons[
                      CollectionSection.BookingsCharges - 1
                    ]?.status === 0 &&
                    'border-indigo-600 bg-indigo-600 hover:bg-indigo-700 outline-none'
                  } ${
                    collectionStateButtons[
                      CollectionSection.BookingsCharges - 1
                    ].status === 1 &&
                    'border-red-500 bg-red-500 outline-none pointer-events-none'
                  } ${
                    collectionStateButtons[
                      CollectionSection.BookingsCharges - 1
                    ].status === 2 &&
                    'border-green-600 bg-green-600 pointer-events-none'
                  }`}>
                  {showNameButton(
                    collectionStateButtons[
                      CollectionSection.BookingsCharges - 1
                    ].status,
                  )}
                </button>
              </span>
              <span className="pb-2">
                Bookings Charges Final
                <button
                  onClick={() =>
                    onClick(
                      CollectionSection.BookingsChargesFinal,
                      setCollectionStateButtons,
                    )
                  }
                  type="button"
                  className={`px-3 py-2 border text-xs font-medium float-right text-white ${
                    collectionStateButtons[
                      CollectionSection.BookingsChargesFinal - 1
                    ].status === 0 &&
                    'border-indigo-600 bg-indigo-600 hover:bg-indigo-700 outline-none'
                  } ${
                    collectionStateButtons[
                      CollectionSection.BookingsChargesFinal - 1
                    ].status === 1 &&
                    'border-red-500 bg-red-500 outline-none pointer-events-none'
                  } ${
                    collectionStateButtons[
                      CollectionSection.BookingsChargesFinal - 1
                    ].status === 2 &&
                    'border-green-600 bg-green-600 pointer-events-none'
                  }`}>
                  {showNameButton(
                    collectionStateButtons[
                      CollectionSection.BookingsChargesFinal - 1
                    ].status,
                  )}
                </button>
              </span>
            </span>
            <span className="text-base pt-2">
              <b>API</b>
            </span>
            <span className="flex flex-col">
              <span className="pb-2 pt-3">
                Events
                <button
                  onClick={() =>
                    onClick(CollectionSection.Events, setCollectionStateButtons)
                  }
                  type="button"
                  className={`px-3 py-2 border text-xs font-medium float-right text-white ${
                    collectionStateButtons[CollectionSection.Events - 1]
                      .status === 0 &&
                    'border-indigo-600 bg-indigo-600 hover:bg-indigo-700 outline-none'
                  } ${
                    collectionStateButtons[CollectionSection.Events - 1]
                      .status === 1 &&
                    'border-red-500 bg-red-500 outline-none pointer-events-none'
                  } ${
                    collectionStateButtons[CollectionSection.Events - 1]
                      .status === 2 &&
                    'border-green-600 bg-green-600 pointer-events-none'
                  }`}>
                  {showNameButton(
                    collectionStateButtons[CollectionSection.Events - 1].status,
                  )}
                </button>
              </span>
              <span className="pb-2">
                Availability
                <button
                  onClick={() =>
                    onClick(
                      CollectionSection.Availability,
                      setCollectionStateButtons,
                    )
                  }
                  type="button"
                  className={`px-3 py-2 border text-xs font-medium float-right text-white ${
                    collectionStateButtons[CollectionSection.Availability - 1]
                      .status === 0 &&
                    'border-indigo-600 bg-indigo-600 hover:bg-indigo-700 outline-none'
                  } ${
                    collectionStateButtons[CollectionSection.Availability - 1]
                      .status === 1 &&
                    'border-red-500 bg-red-500 outline-none pointer-events-none'
                  } ${
                    collectionStateButtons[CollectionSection.Availability - 1]
                      .status === 2 &&
                    'border-green-600 bg-green-600 pointer-events-none'
                  }`}>
                  {showNameButton(
                    collectionStateButtons[CollectionSection.Availability - 1]
                      .status,
                  )}
                </button>
              </span>
              <span className="pb-2">
                Outlier
                <button
                  onClick={() =>
                    onClick(
                      CollectionSection.Outlier,
                      setCollectionStateButtons,
                    )
                  }
                  type="button"
                  className={`px-3 py-2 border text-xs font-medium float-right text-white ${
                    collectionStateButtons[CollectionSection.Outlier - 1]
                      .status === 0 &&
                    'border-indigo-600 bg-indigo-600 hover:bg-indigo-700 outline-none'
                  } ${
                    collectionStateButtons[CollectionSection.Outlier - 1]
                      .status === 1 &&
                    'border-red-500 bg-red-500 outline-none pointer-events-none'
                  } ${
                    collectionStateButtons[CollectionSection.Outlier - 1]
                      .status === 2 &&
                    'border-green-600 bg-green-600 pointer-events-none'
                  }`}>
                  {showNameButton(
                    collectionStateButtons[CollectionSection.Outlier - 1]
                      .status,
                  )}
                </button>
              </span>
              <span className="pb-2">
                Rooms
                <button
                  onClick={() =>
                    onClick(CollectionSection.Rooms, setCollectionStateButtons)
                  }
                  type="button"
                  className={`px-3 py-2 border text-xs font-medium float-right text-white ${
                    collectionStateButtons[CollectionSection.Rooms - 1]
                      .status === 0 &&
                    'border-indigo-600 bg-indigo-600 hover:bg-indigo-700 outline-none'
                  } ${
                    collectionStateButtons[CollectionSection.Rooms - 1]
                      .status === 1 &&
                    'border-red-500 bg-red-500 outline-none pointer-events-none'
                  } ${
                    collectionStateButtons[CollectionSection.Rooms - 1]
                      .status === 2 &&
                    'border-green-600 bg-green-600 pointer-events-none'
                  }`}>
                  {showNameButton(
                    collectionStateButtons[CollectionSection.Rooms - 1].status,
                  )}
                </button>
              </span>
              <span className="pb-2">
                Rooms Rate Values
                <button
                  onClick={() =>
                    onClick(
                      CollectionSection.RoomRateValues,
                      setCollectionStateButtons,
                    )
                  }
                  type="button"
                  className={`px-3 py-2 border text-xs font-medium float-right text-white ${
                    collectionStateButtons[CollectionSection.RoomRateValues - 1]
                      .status === 0 &&
                    'border-indigo-600 bg-indigo-600 hover:bg-indigo-700 outline-none'
                  } ${
                    collectionStateButtons[CollectionSection.RoomRateValues - 1]
                      .status === 1 &&
                    'border-red-500 bg-red-500 outline-none pointer-events-none'
                  } ${
                    collectionStateButtons[CollectionSection.RoomRateValues - 1]
                      .status === 2 &&
                    'border-green-600 bg-green-600 pointer-events-none'
                  }`}>
                  {showNameButton(
                    collectionStateButtons[CollectionSection.RoomRateValues - 1]
                      .status,
                  )}
                </button>
              </span>
              <span className="pb-2">
                Competitors
                <button
                  onClick={() =>
                    onClick(
                      CollectionSection.Competitors,
                      setCollectionStateButtons,
                    )
                  }
                  type="button"
                  className={`px-3 py-2 border text-xs font-medium float-right text-white ${
                    collectionStateButtons[CollectionSection.Competitors - 1]
                      .status === 0 &&
                    'border-indigo-600 bg-indigo-600 hover:bg-indigo-700 outline-none'
                  } ${
                    collectionStateButtons[CollectionSection.Competitors - 1]
                      .status === 1 &&
                    'border-red-500 bg-red-500 outline-none pointer-events-none'
                  } ${
                    collectionStateButtons[CollectionSection.Competitors - 1]
                      .status === 2 &&
                    'border-green-600 bg-green-600 pointer-events-none'
                  }`}>
                  {showNameButton(
                    collectionStateButtons[CollectionSection.Competitors - 1]
                      .status,
                  )}
                </button>
              </span>
            </span>
          </div>
        ),
        disabledPrev: true,
      },
      {
        title: 'Ready',
        subTitle: 'Allow daily sync',
        content: (
          <button
            onClick={() => onClick(1, setReadyStateButtons)}
            type="button"
            className={`px-3 py-2 border text-xs font-medium text-white ${
              readyStateButtons[0].status === 0 &&
              'border-indigo-600 bg-indigo-600 hover:bg-indigo-700 outline-none'
            } ${
              readyStateButtons[0].status === 1 &&
              'border-red-500 bg-red-500 outline-none pointer-events-none'
            } ${
              readyStateButtons[0].status === 2 &&
              'border-green-600 bg-green-600 pointer-events-none'
            }`}>
            {showNameButton(readyStateButtons[0].status, optionsButtonActivate)}
          </button>
        ),
        disabledPrev: true,
        disabledNext: !canProceed,
      },
      {
        title: 'Train',
        subTitle: 'Generate Datasets',
        content: (
          <div className="flex flex-col w-full">
            <div>
              <span className="text-base mr-4">
                <b>From Date:</b>
              </span>
              <DatePicker
                className="w-6/12 text-sm"
                placeholder="Select a date"
                value={date}
                onChange={(date) => {
                  setDate(date);
                }}
              />
            </div>
            <span className="flex flex-col">
              <span className="pb-2 pt-4">
                Room Nights Input Sub-Datasets
                <button
                  onClick={() =>
                    onClick(
                      TrainSection.RoomNightsInputSubDatasets,
                      setTrainStateButtons,
                    )
                  }
                  type="button"
                  className={`px-3 py-2 border text-xs font-medium float-right text-white ${
                    trainStateButtons[
                      TrainSection.RoomNightsInputSubDatasets - 1
                    ].status === 0 &&
                    'border-indigo-600 bg-indigo-600 hover:bg-indigo-700 outline-none'
                  } ${
                    trainStateButtons[
                      TrainSection.RoomNightsInputSubDatasets - 1
                    ].status === 1 &&
                    'border-red-500 bg-red-500 outline-none pointer-events-none'
                  } ${
                    trainStateButtons[
                      TrainSection.RoomNightsInputSubDatasets - 1
                    ].status === 2 &&
                    'border-green-600 bg-green-600 pointer-events-none'
                  }`}>
                  {showNameButton(
                    trainStateButtons[
                      TrainSection.RoomNightsInputSubDatasets - 1
                    ].status,
                  )}
                </button>
              </span>
              <span className="pb-2">
                Room Nights Input Dataset
                <button
                  onClick={() =>
                    onClick(
                      TrainSection.RoomNightsInputDataset,
                      setTrainStateButtons,
                    )
                  }
                  type="button"
                  className={`px-3 py-2 border text-xs font-medium float-right text-white ${
                    trainStateButtons[TrainSection.RoomNightsInputDataset - 1]
                      .status === 0 &&
                    'border-indigo-600 bg-indigo-600 hover:bg-indigo-700 outline-none'
                  } ${
                    trainStateButtons[TrainSection.RoomNightsInputDataset - 1]
                      .status === 1 &&
                    'border-red-500 bg-red-500 outline-none pointer-events-none'
                  } ${
                    trainStateButtons[TrainSection.RoomNightsInputDataset - 1]
                      .status === 2 &&
                    'border-green-600 bg-green-600 pointer-events-none'
                  }`}>
                  {showNameButton(
                    trainStateButtons[TrainSection.RoomNightsInputDataset - 1]
                      .status,
                  )}
                </button>
              </span>
              <span className="pb-2">
                Average Room Revenue Input Sub-Datasets
                <button
                  onClick={() =>
                    onClick(
                      TrainSection.AverageRoomRevenueInputSubDatasets,
                      setTrainStateButtons,
                    )
                  }
                  type="button"
                  className={`px-3 py-2 border text-xs font-medium float-right text-white ${
                    trainStateButtons[
                      TrainSection.AverageRoomRevenueInputSubDatasets - 1
                    ].status === 0 &&
                    'border-indigo-600 bg-indigo-600 hover:bg-indigo-700 outline-none'
                  } ${
                    trainStateButtons[
                      TrainSection.AverageRoomRevenueInputSubDatasets - 1
                    ].status === 1 &&
                    'border-red-500 bg-red-500 outline-none pointer-events-none'
                  } ${
                    trainStateButtons[
                      TrainSection.AverageRoomRevenueInputSubDatasets - 1
                    ].status === 2 &&
                    'border-green-600 bg-green-600 pointer-events-none'
                  }`}>
                  {showNameButton(
                    trainStateButtons[
                      TrainSection.AverageRoomRevenueInputSubDatasets - 1
                    ].status,
                  )}
                </button>
              </span>
              <span className="pb-2">
                Average Room Revenue Input Dataset
                <button
                  onClick={() =>
                    onClick(
                      TrainSection.AverageRoomRevenueInputDataset,
                      setTrainStateButtons,
                    )
                  }
                  type="button"
                  className={`px-3 py-2 border text-xs font-medium float-right text-white ${
                    trainStateButtons[
                      TrainSection.AverageRoomRevenueInputDataset - 1
                    ].status === 0 &&
                    'border-indigo-600 bg-indigo-600 hover:bg-indigo-700 outline-none'
                  } ${
                    trainStateButtons[
                      TrainSection.AverageRoomRevenueInputDataset - 1
                    ].status === 1 &&
                    'border-red-500 bg-red-500 outline-none pointer-events-none'
                  } ${
                    trainStateButtons[
                      TrainSection.AverageRoomRevenueInputDataset - 1
                    ].status === 2 &&
                    'border-green-600 bg-green-600 pointer-events-none'
                  }`}>
                  {showNameButton(
                    trainStateButtons[
                      TrainSection.AverageRoomRevenueInputDataset - 1
                    ].status,
                  )}
                </button>
              </span>
              <span className="pb-2">
                Price Recommendation Input Dataset
                <button
                  onClick={() =>
                    onClick(
                      TrainSection.PriceRecommendationInputDataset,
                      setTrainStateButtons,
                    )
                  }
                  type="button"
                  className={`px-3 py-2 border text-xs font-medium float-right text-white ${
                    trainStateButtons[
                      TrainSection.PriceRecommendationInputDataset - 1
                    ].status === 0 &&
                    'border-indigo-600 bg-indigo-600 hover:bg-indigo-700 outline-none'
                  } ${
                    trainStateButtons[
                      TrainSection.PriceRecommendationInputDataset - 1
                    ].status === 1 &&
                    'border-red-500 bg-red-500 outline-none pointer-events-none'
                  } ${
                    trainStateButtons[
                      TrainSection.PriceRecommendationInputDataset - 1
                    ].status === 2 &&
                    'border-green-600 bg-green-600 pointer-events-none'
                  }`}>
                  {showNameButton(
                    trainStateButtons[
                      TrainSection.PriceRecommendationInputDataset - 1
                    ].status,
                  )}
                </button>
              </span>
            </span>
          </div>
        ),
        disabledPrev: true,
      },
      {
        title: 'Forecast',
        subTitle: 'Generate Datasets',
        content: (
          <div className="flex flex-col w-full">
            <div>
              <span className="text-base mr-4">
                <b>From Date:</b>
              </span>
              <DatePicker
                className="w-6/12 text-sm"
                placeholder="Select a date"
                value={date}
                onChange={(date) => {
                  setDate(date);
                }}
              />
            </div>
            <span className="flex flex-col">
              <span className="pb-2 pt-4">
                Room Nights Input Dataset
                <button
                  onClick={() =>
                    onClick(
                      ForecastSection.RoomNightsInputDataset,
                      setForecastStateButtons,
                    )
                  }
                  type="button"
                  className={`px-3 py-2 border text-xs font-medium float-right text-white ${
                    forecastStateButtons[
                      ForecastSection.RoomNightsInputDataset - 1
                    ].status === 0 &&
                    'border-indigo-600 bg-indigo-600 hover:bg-indigo-700 outline-none'
                  } ${
                    forecastStateButtons[
                      ForecastSection.RoomNightsInputDataset - 1
                    ].status === 1 &&
                    'border-red-500 bg-red-500 outline-none pointer-events-none'
                  } ${
                    forecastStateButtons[
                      ForecastSection.RoomNightsInputDataset - 1
                    ].status === 2 &&
                    'border-green-600 bg-green-600 pointer-events-none'
                  }`}>
                  {showNameButton(
                    forecastStateButtons[
                      ForecastSection.RoomNightsInputDataset - 1
                    ].status,
                  )}
                </button>
              </span>
              <span className="pb-2">
                Average Room Revenue Input Dataset
                <button
                  onClick={() =>
                    onClick(
                      ForecastSection.AverageRoomRevenueInputDataset,
                      setForecastStateButtons,
                    )
                  }
                  type="button"
                  className={`px-3 py-2 border text-xs font-medium float-right text-white ${
                    forecastStateButtons[
                      ForecastSection.AverageRoomRevenueInputDataset - 1
                    ].status === 0 &&
                    'border-indigo-600 bg-indigo-600 hover:bg-indigo-700 outline-none'
                  } ${
                    forecastStateButtons[
                      ForecastSection.AverageRoomRevenueInputDataset - 1
                    ].status === 1 &&
                    'border-red-500 bg-red-500 outline-none pointer-events-none'
                  } ${
                    forecastStateButtons[
                      ForecastSection.AverageRoomRevenueInputDataset - 1
                    ].status === 2 &&
                    'border-green-600 bg-green-600 pointer-events-none'
                  }`}>
                  {showNameButton(
                    forecastStateButtons[
                      ForecastSection.AverageRoomRevenueInputDataset - 1
                    ].status,
                  )}
                </button>
              </span>
              <span className="pb-2">
                Price Recommendation Input Dataset
                <button
                  onClick={() =>
                    onClick(
                      ForecastSection.PriceRecommendationInputDataset,
                      setForecastStateButtons,
                    )
                  }
                  type="button"
                  className={`px-3 py-2 border text-xs font-medium float-right text-white ${
                    forecastStateButtons[
                      ForecastSection.PriceRecommendationInputDataset - 1
                    ].status === 0 &&
                    'border-indigo-600 bg-indigo-600 hover:bg-indigo-700 outline-none'
                  } ${
                    forecastStateButtons[
                      ForecastSection.PriceRecommendationInputDataset - 1
                    ].status === 1 &&
                    'border-red-500 bg-red-500 outline-none pointer-events-none'
                  } ${
                    forecastStateButtons[
                      ForecastSection.PriceRecommendationInputDataset - 1
                    ].status === 2 &&
                    'border-green-600 bg-green-600 pointer-events-none'
                  }`}>
                  {showNameButton(
                    forecastStateButtons[
                      ForecastSection.PriceRecommendationInputDataset - 1
                    ].status,
                  )}
                </button>
              </span>
            </span>
          </div>
        ),
        disabledPrev: true,
      },
      {
        title: 'Metrics',
        subTitle: 'Generate Metrics',
        content: (
          <div className="flex flex-col w-full">
            <div>
              <span className="text-base mr-4">
                <b>From Date:</b>
              </span>
              <DatePicker
                className="w-6/12 text-sm"
                placeholder="Select a date"
                value={date}
                onChange={(date) => {
                  setDate(date);
                }}
              />
            </div>
            <span className="flex flex-col">
              <span className="pb-2 pt-4">
                Room Nights Absolute Error
                <button
                  onClick={() =>
                    onClick(
                      MetricsSection.RoomNightsAbsoluteError,
                      setMetricsStateButtons,
                    )
                  }
                  type="button"
                  className={`px-3 py-2 border text-xs font-medium float-right text-white ${
                    metricsStateButtons[
                      MetricsSection.RoomNightsAbsoluteError - 1
                    ].status === 0 &&
                    'border-indigo-600 bg-indigo-600 hover:bg-indigo-700 outline-none'
                  } ${
                    metricsStateButtons[
                      MetricsSection.RoomNightsAbsoluteError - 1
                    ].status === 1 &&
                    'border-red-500 bg-red-500 outline-none pointer-events-none'
                  } ${
                    metricsStateButtons[
                      MetricsSection.RoomNightsAbsoluteError - 1
                    ].status === 2 &&
                    'border-green-600 bg-green-600 pointer-events-none'
                  }`}>
                  {showNameButton(
                    metricsStateButtons[
                      MetricsSection.RoomNightsAbsoluteError - 1
                    ].status,
                  )}
                </button>
              </span>
              <span className="pb-2">
                Average Room Revenue Absolute Error
                <button
                  onClick={() =>
                    onClick(
                      MetricsSection.AverageRoomRevenueAbsoluteError,
                      setMetricsStateButtons,
                    )
                  }
                  type="button"
                  className={`px-3 py-2 border text-xs font-medium float-right text-white ${
                    metricsStateButtons[
                      MetricsSection.AverageRoomRevenueAbsoluteError - 1
                    ].status === 0 &&
                    'border-indigo-600 bg-indigo-600 hover:bg-indigo-700 outline-none'
                  } ${
                    metricsStateButtons[
                      MetricsSection.AverageRoomRevenueAbsoluteError - 1
                    ].status === 1 &&
                    'border-red-500 bg-red-500 outline-none pointer-events-none'
                  } ${
                    metricsStateButtons[
                      MetricsSection.AverageRoomRevenueAbsoluteError - 1
                    ].status === 2 &&
                    'border-green-600 bg-green-600 pointer-events-none'
                  }`}>
                  {showNameButton(
                    metricsStateButtons[
                      MetricsSection.AverageRoomRevenueAbsoluteError - 1
                    ].status,
                  )}
                </button>
              </span>
              <span className="pb-2">
                Room Nights Central Measure
                <button
                  onClick={() =>
                    onClick(
                      MetricsSection.RoomNightsCentralMeasure,
                      setMetricsStateButtons,
                    )
                  }
                  type="button"
                  className={`px-3 py-2 border text-xs font-medium float-right text-white ${
                    metricsStateButtons[
                      MetricsSection.RoomNightsCentralMeasure - 1
                    ].status === 0 &&
                    'border-indigo-600 bg-indigo-600 hover:bg-indigo-700 outline-none'
                  } ${
                    metricsStateButtons[
                      MetricsSection.RoomNightsCentralMeasure - 1
                    ].status === 1 &&
                    'border-red-500 bg-red-500 outline-none pointer-events-none'
                  } ${
                    metricsStateButtons[
                      MetricsSection.RoomNightsCentralMeasure - 1
                    ].status === 2 &&
                    'border-green-600 bg-green-600 pointer-events-none'
                  }`}>
                  {showNameButton(
                    metricsStateButtons[
                      MetricsSection.RoomNightsCentralMeasure - 1
                    ].status,
                  )}
                </button>
              </span>
              <span className="pb-2">
                Average Room Revenue Central Measure
                <button
                  onClick={() =>
                    onClick(
                      MetricsSection.AverageRoomRevenueCentralMeasure,
                      setMetricsStateButtons,
                    )
                  }
                  type="button"
                  className={`px-3 py-2 border text-xs font-medium float-right text-white ${
                    metricsStateButtons[
                      MetricsSection.AverageRoomRevenueCentralMeasure - 1
                    ].status === 0 &&
                    'border-indigo-600 bg-indigo-600 hover:bg-indigo-700 outline-none'
                  } ${
                    metricsStateButtons[
                      MetricsSection.AverageRoomRevenueCentralMeasure - 1
                    ].status === 1 &&
                    'border-red-500 bg-red-500 outline-none pointer-events-none'
                  } ${
                    metricsStateButtons[
                      MetricsSection.AverageRoomRevenueCentralMeasure - 1
                    ].status === 2 &&
                    'border-green-600 bg-green-600 pointer-events-none'
                  }`}>
                  {showNameButton(
                    metricsStateButtons[
                      MetricsSection.AverageRoomRevenueCentralMeasure - 1
                    ].status,
                  )}
                </button>
              </span>
            </span>
          </div>
        ),
        disabledPrev: true,
      },
    ],
    [
      form.hotel,
      filteredHotels,
      canProceed,
      date,
      collectionStateButtons,
      showNameButton,
      readyStateButtons,
      optionsButtonActivate,
      trainStateButtons,
      forecastStateButtons,
      metricsStateButtons,
      onClick,
    ],
  );

  const aiOutlier = useCallback(async (hotel: number, formData: FormData) => {
    try {
      await repository.ai.createAiOutlier(hotel, formData);
    } catch (error: any) {
      showAlert(error?.response?.data?.message, 'error', 'OK');
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const setBookingStatus = useCallback(
    async (hotel: number) => {
      try {
        const sets = [
          {
            type: 1,
            pms_booking_statuses: inputBookingStatusSet,
          },
        ];

        await repository.ai.createAiBookingStatusMappingBulk({
          hotel_id: hotel,
          sets,
        });
      } catch (error: any) {
        showAlert(error?.response?.data?.message, 'error', 'OK');
      }
    },
    [inputBookingStatusSet], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const openingDate = useCallback(
    async (hotel: number) => {
      try {
        const openingDate = date?.format('Y-MM-DD');
        if (openingDate) {
          await repository.aiForecast.storeOpeningDate(hotel, {
            opening_date: date?.format('Y-MM-DD'),
          });
        }
      } catch (error: any) {
        showAlert(error?.response?.data?.message, 'error', 'OK');
      }
    },
    [date], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const setEvents = useCallback(async (hotel: number, formData: FormData) => {
    try {
      await repository.aiForecast.setEvents(hotel, formData);
    } catch (error: any) {
      showAlert(error?.response?.data?.message, 'error', 'OK');
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onResolve = useCallback(() => {
    let payload = {};

    if (currentStep === 0 && form.hotel === undefined) {
      showAlert('Please select a hotel', 'error', 'OK');
      setCanProceed(false);

      return;
    }

    if (currentStep === 1 && form.hotel) {
      if (form.file) {
        const formData = new FormData();
        formData.set('csv', form.file);
        aiOutlier(form.hotel, formData);
      }

      inputBookingStatusSet.length && setBookingStatus(form.hotel);
      date && openingDate(form.hotel);
    }

    if (currentStep === 2 && form.file && form.hotel) {
      const formData = new FormData();
      formData.set('csv', form.file);
      setEvents(form.hotel, formData);
    }

    if (error && currentStep > 2) {
      showAlert('Finish the process before continuing please', 'error', 'OK');

      return;
    }

    if (currentStep === 7) {
      showAlert('You have finished the process!', 'success', 'OK');

      history.push('/dashboard');
      return;
    }

    const step = currentStep + 1;
    payload = { step };
    if (hotelSelected && step > 2) {
      step === 3 &&
        startProccess(hotelSelected.id, payload, setCollectionStateButtons);
      step === 4 &&
        startProccess(hotelSelected.id, payload, setReadyStateButtons);
      step === 5 &&
        startProccess(hotelSelected.id, payload, setTrainStateButtons);
      step === 6 &&
        startProccess(hotelSelected.id, payload, setForecastStateButtons);
      step === 7 &&
        startProccess(hotelSelected.id, payload, setMetricsStateButtons);
    } else if (hotelSelected) {
      startProccess(hotelSelected.id, payload);
    }
    setCanProceed(true);

    currentStep !== 0 &&
      setCurrentStep((prevState) => {
        if (prevState < steps.length - 1) {
          return prevState + 1;
        }

        return prevState;
      });
  }, [
    aiOutlier,
    currentStep,
    date,
    error,
    form.file,
    form.hotel,
    history,
    hotelSelected,
    inputBookingStatusSet,
    openingDate,
    setBookingStatus,
    setEvents,
    showAlert,
    startProccess,
    steps.length,
  ]);

  return (
    <div className="flex bg-white p-4 overflow-hidden shadow-md dark:bg-ebonyClay relative">
      <BreadcrumbCreation
        currentStep={currentStep}
        steps={steps}
        loading={loading}
        onCancel={() => setCurrentStep((prevState) => prevState - 1)}
        onResolve={() => onResolve()}
      />
    </div>
  );
};

export default ForecastHotelSetup;
