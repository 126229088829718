import React, { useCallback, useMemo, useState } from 'react';
import BreadcrumbCreation, {
  Step,
} from '../../../../components/breadcrumb-creation';
import { Select } from 'antd';
import Repository from '../../../../config/repository';
import { useUser } from '../../../../context/user';
import ModalAction from '../../../../components/modal-action';
import { useHistory } from 'react-router';

interface Props {}

const repository = new Repository();

const UnblockedHotels: React.FC<Props> = () => {
  const history = useHistory();
  const { clientHotels, showAlert } = useUser();
  const [currentStep, setCurrentStep] = useState(0);
  const [hotelId, setHotelId] = useState(undefined);
  const [openModalAction, setOpenModalAction] = useState(false);
  const [loading, setLoading] = useState(false);

  const hotelSelected = useMemo(
    () => clientHotels.find((hotel) => hotel.id === hotelId),
    [hotelId, clientHotels],
  );

  const unblockedHotels = useCallback(
    async (id) => {
      setLoading(true);
      try {
        await repository.api.unblockedHotels(id);
        setLoading(false);
        showAlert('Hotel is unblocked', 'success', 'OK');
        history.push('/');
      } catch {
        setLoading(false);
        showAlert('Error unblocking hotel', 'error', 'OK');
      }
    },
    [history, showAlert],
  );

  const steps: Step[] = useMemo(
    () => [
      {
        title: 'Hotel',
        subTitle: 'Select a Hotel',
        content: (
          <Select
            className="w-6/12"
            showSearch
            placeholder="Please select the hotel"
            filterOption={(input, option) => {
              if (option?.label && typeof option.label === 'string') {
                return (option.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }
              return true;
            }}
            value={hotelId}
            onChange={(value) => setHotelId(value)}
            options={clientHotels.map((value) => {
              return {
                value: value.id,
                label: value.name,
              };
            })}
          />
        ),
      },
    ],
    [hotelId, clientHotels],
  );

  return (
    <div className="flex bg-white p-4 overflow-hidden shadow-md dark:bg-ebonyClay relative">
      <BreadcrumbCreation
        currentStep={currentStep}
        loading={loading}
        steps={steps}
        onCancel={() => setCurrentStep((prevState) => prevState - 1)}
        onResolve={() => {
          if (currentStep === 0 && hotelId === undefined) {
            showAlert('Please select a hotel', 'error', 'OK');
            return;
          }

          setCurrentStep((prevState) => {
            if (prevState < steps.length - 1) {
              return prevState + 1;
            }

            return prevState;
          });

          if (currentStep === steps.length - 1) {
            setOpenModalAction(!openModalAction);
          }
        }}
      />

      <ModalAction
        title={
          <div className="flex flex-col text-sm">
            <span>{hotelSelected?.name}</span>
            <span>You will unblocked this hotel.</span>
          </div>
        }
        message="Do you want to proceed?"
        open={openModalAction}
        setOpen={() => setOpenModalAction(!openModalAction)}
        onSave={() => {
          setOpenModalAction(!openModalAction);
          unblockedHotels(hotelId);
        }}
        onCancel={() => setOpenModalAction(!openModalAction)}
        buttonSaveName="Save"
        type="success"
      />
    </div>
  );
};

export default UnblockedHotels;
