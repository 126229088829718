import ApiService from '../../../../api-service';

interface Payload {
  price_recommendation: boolean;
}

export default class PriceRecommendationRepository {
  api = ApiService;

  updatePriceRecommendation(hotelId: number, payload: Payload) {
    return this.api.patch(`/api/support/hotels/${hotelId}`, payload);
  }
}
