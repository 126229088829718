import React, { useCallback, useState, useMemo } from 'react';
import ModalAction from '../../../../components/modal-action';
import { PlusIcon } from '@heroicons/react/outline';
import CustomRows, { Row } from '../../../../components/custom-rows';
import moment from 'moment';

interface Props {}

const Events: React.FC<Props> = () => {
  const [currentItem, setCurrentItem] = useState<Row | undefined>(undefined);
  const [openModalAction, setOpenModalAction] = useState(false);
  const [pmsFields, setPmsFields] = useState<Row[]>([]);

  const addMockedRow = useCallback(
    (state, values = {}) => {
      if (state === 'pmsFields') {
        const data = {
          state: 'pmsFields',
          id: Math.random().toString(),
          fields: [
            {
              type: 'text',
              value: 'Teste',
              label: 'Name',
              key: 'name',
              cls: 'w-2/12',
            },
            {
              type: 'select',
              value: 1,
              label: 'Impact',
              options: [
                { id: 1, label: 'High Negative' },
                { id: 2, label: 'Medium Negative' },
                { id: 3, label: 'Low Negative' },
                { id: 4, label: 'Neutral' },
                { id: 5, label: 'Low Positive' },
                { id: 6, label: 'Medium Positive' },
                { id: 7, label: 'High Positive' },
              ],
              key: 'impact',
              cls: 'w-3/12',
            },
            {
              type: 'select',
              value: 2,
              label: 'Type',
              options: [
                { id: 1, label: 'Same Day Events' },
                { id: 2, label: 'Different Day Events' },
                { id: 3, label: 'One Times Events' },
              ],
              key: 'type',
              cls: 'w-3/12',
            },
            {
              type: 'text',
              value: 'asdasdsadsa',
              label: 'Comments',
              key: 'comments',
              cls: 'w-3/12',
            },
            {
              type: 'date',
              value: [moment(), moment()],
              label: 'Period',
              key: 'period_1',
              cls: 'w-3/12 mt-2',
            },
          ],
          ...values,
        };

        setPmsFields([data, ...pmsFields]);

        return data;
      }
    },
    [pmsFields],
  );

  const deleteHotelOnboard = () => {
    setOpenModalAction(false);

    setPmsFields(pmsFields.filter((item) => item.id !== currentItem?.id));
    setCurrentItem(undefined);
  };

  const renderRows = useMemo(
    () => (
      <CustomRows
        rows={pmsFields}
        currentRow={currentItem}
        onChangeRow={(item) => setCurrentItem(item)}
        onDeleteRow={(item) => {
          setCurrentItem(item);
          setOpenModalAction(true);
        }}
        onCancelRow={() => {
          setPmsFields(pmsFields.filter((row) => !row.new));
          setCurrentItem(undefined);
        }}
        onSaveRow={() => setCurrentItem(undefined)}
        addPeriod={(row) =>
          setPmsFields((prevState) => {
            prevState.map((item) => {
              if (item.id === row.id) {
                item.fields.push({
                  type: 'date',
                  value: [moment(), moment()],
                  label: 'Period',
                  key: `period_${Math.random().toFixed(1)}`,
                  cls: 'w-3/12 mt-2',
                });
              }

              return [...prevState, item];
            });

            return prevState;
          })
        }
        removePeriod={(row, field) =>
          setPmsFields((prevState) => {
            prevState.map((item) => {
              if (item.id === row.id) {
                item.fields = item.fields.filter(
                  (itemField) => itemField.key !== field.key,
                );
              }

              return [...prevState, item];
            });

            return prevState;
          })
        }
        handleInput={(row, field, newValue) => {
          setPmsFields([
            ...pmsFields.map((item) => {
              if (item.id === row.id) {
                return {
                  ...row,
                  fields: [
                    ...row.fields.map((item) => {
                      if (item.key === field.key) {
                        return {
                          ...item,
                          value: newValue,
                        };
                      }

                      return item;
                    }),
                  ],
                };
              }

              return item;
            }),
          ]);
        }}
      />
    ),
    [currentItem, pmsFields],
  );

  return (
    <div>
      <ModalAction
        title={`You will delete the item`}
        message="Do you confirm the action?"
        open={openModalAction}
        setOpen={() => setOpenModalAction(!openModalAction)}
        onSave={() => deleteHotelOnboard()}
        onCancel={() => setOpenModalAction(!openModalAction)}
      />

      <div>
        <div className="flex justify-between">
          <p className="font-bold dark:text-white">PMS Events</p>
          <span
            onClick={() => {
              if (currentItem) {
                return;
              }

              setCurrentItem(addMockedRow('pmsFields', { new: true }));
            }}
            className={`flex items-center text-xs dark:text-iron ${
              currentItem ? 'opacity-50' : 'cursor-pointer'
            }`}>
            Add new <PlusIcon className="w-5 h-5" />
          </span>
        </div>
        {pmsFields.length > 0 ? (
          <>{renderRows}</>
        ) : (
          <p className="w-full mt-5 text-center dark:text-white">No results</p>
        )}
      </div>
    </div>
  );
};

export default Events;
