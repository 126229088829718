import { Select, DatePicker } from 'antd';
import { Moment } from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import BreadcrumbCreation, {
  Step,
} from '../../../components/breadcrumb-creation';
import ModalAction from '../../../components/modal-action';
import Repository from '../../../config/repository';
import { useUser } from '../../../context/user';

const ForceDatasetCreation: React.FC = () => {
  const repository = new Repository();
  const history = useHistory();
  const { clientHotels, showAlert } = useUser();
  const [currentStep, setCurrentStep] = useState(0);
  const [form, setForm] = useState<{
    hotel: number | undefined;
    date: null | Moment;
  }>({
    hotel: undefined,
    date: null,
  });
  const [openModalAction, setOpenModalAction] = useState(false);
  const [loading, setLoading] = useState(false);

  const hotelSelected = useMemo(
    () => clientHotels.find((hotel) => hotel.id === form.hotel),
    [form.hotel, clientHotels],
  );

  const steps: Step[] = [
    {
      title: 'Hotel',
      subTitle: 'Select a Hotel',
      content: (
        <Select
          className="w-6/12"
          showSearch
          placeholder="Please select the hotel"
          filterOption={(input, option) => {
            if (option?.label && typeof option.label === 'string') {
              return (option.label ?? '')
                .toLowerCase()
                .includes(input.toLowerCase());
            }
            return true;
          }}
          value={form.hotel}
          onChange={(value) =>
            setForm((prevState) => ({
              ...prevState,
              hotel: value,
            }))
          }
          options={clientHotels.map((value) => {
            return {
              value: value.id,
              label: value.name,
            };
          })}
        />
      ),
    },
    {
      title: 'Date',
      subTitle: 'Select a date',
      content: (
        <DatePicker
          className="w-6/12 text-sm"
          placeholder="Select a date"
          value={form.date}
          disabledDate={(currentDate) => currentDate.isAfter(new Date())}
          onChange={(date) => {
            setForm((prevState) => ({
              ...prevState,
              date: date,
            }));
          }}
        />
      ),
    },
    {
      title: 'Overhaul',
      subTitle: 'Review all the details',
      content: (
        <div className="flex flex-col">
          <span className="flex items-center">
            <b className="mr-1">Hotel:</b>
            {hotelSelected?.name}
          </span>
          <span className="flex-center mt-1">
            <b className="mr-1">Date:</b>
            {form.date?.format('DD-MM-YYYY')}
          </span>
        </div>
      ),
    },
    {
      title: 'Dispatch',
      subTitle: 'Dispatch dataset',
      content: <span>Save data</span>,
    },
  ];

  const saveData = useCallback(async () => {
    setLoading(true);
    try {
      await repository.ai.createAiDispatcher({
        hotel_id: form.hotel,
        date: form.date?.format('YYYY-MM-DD'),
      });

      showAlert('Dataset will be created', 'success', 'OK');

      setLoading(false);
      history.push('/');
    } catch {
      setLoading(false);
      showAlert('Error while saving', 'error', 'OK');
    }
  }, [form.date, form.hotel, history, repository.ai, showAlert]);

  return (
    <div className="flex bg-white p-4 overflow-hidden shadow-md dark:bg-ebonyClay relative">
      <BreadcrumbCreation
        currentStep={currentStep}
        loading={loading}
        steps={steps}
        onCancel={() => setCurrentStep((prevState) => prevState - 1)}
        onResolve={() => {
          if (currentStep === 0 && form.hotel === undefined) {
            showAlert('Please select a hotel', 'error', 'OK');

            return;
          }

          if (currentStep === 1 && !form.date) {
            showAlert('Please select a date', 'error', 'OK');

            return;
          }

          setCurrentStep((prevState) => {
            if (prevState < steps.length - 1) {
              return prevState + 1;
            }

            return prevState;
          });

          if (currentStep === steps.length - 1) {
            setOpenModalAction(!openModalAction);
          }
        }}
      />

      <ModalAction
        title={
          <div className="flex flex-col text-sm">
            <span>{hotelSelected?.name}</span>
            <span>You will force the dataset creation for the day</span>
            <span>{form.date?.format('YYYY-MM-DD')}</span>
          </div>
        }
        message="Do you want to proceed?"
        open={openModalAction}
        setOpen={() => setOpenModalAction(!openModalAction)}
        onSave={() => {
          setOpenModalAction(!openModalAction);
          saveData();
        }}
        onCancel={() => setOpenModalAction(!openModalAction)}
        buttonSaveName="Save"
        type="success"
      />
    </div>
  );
};

export default ForceDatasetCreation;
