import ApiService from '../../../api-service';

export default class AiForecastRepository {
  api = ApiService;

  getCurrentStatusSetup(hotelId: string | number) {
    return this.api.get(`api/support/ai-hotel-setup/${hotelId}/status`);
  }

  restartProccess(hotelId: string | number) {
    return this.api.put(`api/support/ai-hotel-setup/${hotelId}/restart`);
  }

  startProccess(hotelId: string | number, payload: any) {
    return this.api.post(`api/support/ai-hotel-setup/${hotelId}/start`, payload);
  }

  setWizardEventsData(hotelId: string | number) {
    return this.api.post(`api/support/ai-hotel-setup/${hotelId}/events`);
  }

  storeOpeningDate(hotelId: string | number, payload = {}) {
    return this.api.post(`api/support/ai-hotel-setup/${hotelId}/store-opening-date`, payload);
  }

  setEvents(hotelId: string | number, payload = {}) {
    return this.api.post(`api/support/ai-hotel-setup/${hotelId}/events`, payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  }
}
