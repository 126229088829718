import React, { useCallback, useEffect, useMemo, useState } from 'react';
import BreadcrumbCreation, {
  Step,
} from '../../../../components/breadcrumb-creation';
import { Empty, Radio, Select } from 'antd';
import { useUser } from '../../../../context/user';
import ModalAction from '../../../../components/modal-action';
import Repository from '../../../../config/repository';

interface IHotelRatingChannel {
  id: number;
  hotel_id: number;
  hotel_name: string;
  rating_channel_internal_id: string;
}

interface IGoogleRatingPlaceOptions {
  name: string;
  place_id: string;
  rating: number;
  user_ratings_total: number;
  price_level?: number;
}

const GoogleRatings: React.FC = () => {
  const { hotels, showAlert } = useUser();
  const repository = new Repository();
  const GOOGLE_CHANNEL_ID = '1';

  const [currentStep, setCurrentStep] = useState(0);
  const [hotelId, setHotelId] = useState(undefined);
  const [modalActionStatus, setModalActionStatus] = useState({
    isOpenModalRemove: false,
    isOpenModalAlreadyConfigured: false,
  });
  const [loading, setLoading] = useState(false);
  const [googleRatingPlaceOptions, setGoogleRatingPlaceOptions] =
    useState<IGoogleRatingPlaceOptions[]>();
  const [selectedGooglePlaceId, setSelectedGooglePlaceId] = useState();
  const [hotelOptions, setHotelOptions] = useState(
    hotels.map((value) => {
      return {
        value: value.id,
        label: value.name,
      };
    }),
  );
  const [hotelsAlreadyConfigured, setHotelsAlreadyConfigured] =
    useState<IHotelRatingChannel[]>();
  const [searchName, setSearchName] = useState<string>();

  const hotelSelected = useMemo(
    () => hotels.find((hotel) => hotel.id === hotelId),
    [hotelId, hotels],
  );

  const getGoogleInfoByHotelName = useCallback(async (hotelName: string) => {
    setLoading(true);
    try {
      const response =
        await repository.hotelRatingChannelRepository.getGoogleInfoByHotelName(
          hotelName,
        );

      setGoogleRatingPlaceOptions(response.data.candidates);
    } catch {
      showAlert('Error while getting data', 'error', 'OK');
    }
    setLoading(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const steps: Step[] = useMemo(
    () => [
      {
        title: 'Hotel',
        subTitle: 'Select a Hotel',
        content: (
          <>
            <Select
              className="w-6/12"
              showSearch
              placeholder="Please select the hotel"
              filterOption={(input, option) => {
                if (option?.label && typeof option.label === 'string') {
                  return (option.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }
                return true;
              }}
              value={hotelId}
              onChange={(value) => setHotelId(value)}
              options={hotelOptions}
            />
            {hotelsAlreadyConfigured?.some(
              (hotel) => hotel.hotel_id === hotelSelected?.id,
            ) && (
              <button
                className="inline-flex items-center outline-none px-3 py-2 ml-2 border text-xs leading-4 font-medium shadow text-white border-red-400 bg-red-400 hover:bg-red-700"
                onClick={() =>
                  setModalActionStatus({
                    isOpenModalRemove: true,
                    isOpenModalAlreadyConfigured: false,
                  })
                }>
                Remove Hotel Configuration
              </button>
            )}
          </>
        ),
      },
      {
        title: 'Google Place Id',
        subTitle: 'Select the hotel with the correct reviews',
        content: (
          <div className="flex flex-col gap-4">
            <h3 className="font-bold dark:text-white">Hotels with ratings</h3>
            {googleRatingPlaceOptions?.length === 0 ? (
              <>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </>
            ) : (
              <>
                <Radio.Group
                  onChange={(e) => setSelectedGooglePlaceId(e.target.value)}
                  value={selectedGooglePlaceId}>
                  {googleRatingPlaceOptions?.map((googleRatingPlace) => {
                    return (
                      <Radio value={googleRatingPlace.place_id}>
                        <div className="flex flex-col">
                          <strong>{googleRatingPlace.name}</strong>
                          <p>
                            <strong>Rating: </strong>
                            {googleRatingPlace.rating}
                          </p>
                          <span>
                            <strong>User Review Count: </strong>
                            {googleRatingPlace.user_ratings_total}
                          </span>
                        </div>
                      </Radio>
                    );
                  })}
                </Radio.Group>
                <a
                  href={`https://www.google.com/search?q=${hotelSelected?.name}`}
                  target="_blank"
                  rel="noreferrer"
                  className="text-gray-600 dark:text-gray-200 underline">
                  Search for {hotelSelected?.name} on Google
                </a>
              </>
            )}
            <h3 className="dark:text-white">
              If the correct hotel is not listed, please try searching using a
              different name. Use the following input to refine your search and
              find the desired hotel
            </h3>
            <form
              className="flex gap-2"
              id="search-hotel"
              onSubmit={(e) => {
                e.preventDefault();
                if (searchName) getGoogleInfoByHotelName(searchName);
              }}>
              <input
                type="text"
                name="name"
                value={searchName}
                onChange={(event) => setSearchName(event?.target.value)}
                placeholder="Search hotel ratings by name"
                className="block w-full shadow-sm sm:text-sm border-gray-300 dark:bg-transparent dark:text-white"
              />
              <button
                type="submit"
                form="search-hotel"
                className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-xs leading-4 font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none">
                Search
              </button>
            </form>
          </div>
        ),
      },
    ],
    [
      selectedGooglePlaceId,
      hotelSelected,
      googleRatingPlaceOptions,
      hotelId,
      hotelOptions,
      searchName,
      hotelsAlreadyConfigured,
      getGoogleInfoByHotelName,
    ],
  );

  useEffect(() => {
    if (hotels.length) {
      getHotelRatings();
    }
  }, [hotels]); // eslint-disable-line react-hooks/exhaustive-deps

  const getHotelRatings = useCallback(async () => {
    try {
      const response =
        await repository.hotelRatingChannelRepository.getHotelRatingByChannel(
          GOOGLE_CHANNEL_ID,
        );
      const { data } = response;
      setHotelsAlreadyConfigured(data);
      const hotelsOptions = hotels.map((hotel) => {
        const isHotelAlreadyConfigured = data.some(
          (h: IHotelRatingChannel) => h.hotel_id === hotel.id,
        );
        return {
          value: hotel.id,
          label: `${hotel.name} ${isHotelAlreadyConfigured ? '✅' : '❌'}`,
        };
      });

      setHotelOptions(hotelsOptions);
    } catch {
      showAlert('Error while getting data', 'error', 'OK');
    }
    setLoading(false);
  }, [hotels]); // eslint-disable-line react-hooks/exhaustive-deps

  const createHotelRatingChannel = useCallback(async () => {
    if (!hotelId) return;

    const selectedGoogleInfo = googleRatingPlaceOptions?.find(
      (googleRatingPlaceOption) => {
        return googleRatingPlaceOption.place_id === selectedGooglePlaceId;
      },
    );

    if (!selectedGoogleInfo) {
      return;
    }

    const payload = {
      rating_channel_id: GOOGLE_CHANNEL_ID,
      rating_channel_internal_id: selectedGoogleInfo.place_id,
      user_reviews_count: selectedGoogleInfo.user_ratings_total,
      rating: selectedGoogleInfo.rating,
      price_level: selectedGoogleInfo?.price_level,
    };

    setLoading(true);
    try {
      await repository.hotelRatingChannelRepository.createHotelRatingChannel(
        hotelId,
        payload,
      );
      showAlert('Hotel Rating channel created', 'success', 'OK');
    } catch {
      showAlert('Error while creating', 'error', 'OK');
    }
    setLoading(false);
  }, [hotelId, googleRatingPlaceOptions, selectedGooglePlaceId]); // eslint-disable-line react-hooks/exhaustive-deps

  const deleteHotelRatingChannel = useCallback(async () => {
    if (!hotelId) return;

    const hotelRatingSelected = hotelsAlreadyConfigured?.find(
      (hotel) => hotel.hotel_id === hotelId,
    );

    if (!hotelRatingSelected?.id) return;

    setLoading(true);
    try {
      await repository.hotelRatingChannelRepository.deleteHotelRatingChannel(
        hotelRatingSelected.id.toString(),
      );

      showAlert('Hotel Rating channel deleted', 'success', 'OK');
      getHotelRatings();
    } catch {
      showAlert('Error while deleting', 'error', 'OK');
    }
    setLoading(false);
  }, [hotelId, hotelsAlreadyConfigured]); // eslint-disable-line react-hooks/exhaustive-deps

  const getSteps = useCallback(async () => {
    if (currentStep === 0 && hotelId === undefined) {
      showAlert('Please select a hotel', 'error', 'OK');

      return;
    }

    const hotelAlreadyConfigured = hotelsAlreadyConfigured?.some(
      (hotel) => hotel.hotel_id === hotelId,
    );

    if (currentStep === 0 && hotelAlreadyConfigured) {
      setModalActionStatus({
        isOpenModalAlreadyConfigured:
          !modalActionStatus.isOpenModalAlreadyConfigured,
        isOpenModalRemove: false,
      });

      return;
    }

    if (currentStep === 0 && hotelSelected?.name) {
      getGoogleInfoByHotelName(hotelSelected.name);
    }

    if (currentStep === 1 && !selectedGooglePlaceId) {
      showAlert('Please select a hotel', 'error', 'OK');
    }

    if (currentStep === 1) {
      await createHotelRatingChannel();
    }

    setCurrentStep((prevState) => {
      if (prevState < steps.length - 1) {
        return prevState + 1;
      }

      getHotelRatings();

      return 0;
    });
  }, // eslint-disable-next-line
  [
    currentStep,
    hotelId,
    currentStep,
    hotelsAlreadyConfigured,
    selectedGooglePlaceId,
    modalActionStatus.isOpenModalAlreadyConfigured,
  ]);

  return (
    <div className="flex bg-white p-4 overflow-hidden shadow-md dark:bg-ebonyClay relative">
      <BreadcrumbCreation
        currentStep={currentStep}
        steps={steps}
        loading={loading}
        onCancel={() => setCurrentStep((prevState) => prevState - 1)}
        onResolve={getSteps}
      />

      <ModalAction
        title={
          <div className="flex flex-col text-sm">
            <span>{hotelSelected?.name}</span>
            <span>Remove Google Place ID Configured</span>
          </div>
        }
        message="This hotel already has the Google Place ID configured. If you continue, you will delete all Google Rating reviews data for this hotel. Do you want to proceed?"
        open={modalActionStatus.isOpenModalRemove}
        setOpen={() =>
          setModalActionStatus({
            isOpenModalRemove: !modalActionStatus.isOpenModalRemove,
            isOpenModalAlreadyConfigured: false,
          })
        }
        onSave={() => {
          setModalActionStatus({
            isOpenModalRemove: !modalActionStatus.isOpenModalRemove,
            isOpenModalAlreadyConfigured: false,
          });
          deleteHotelRatingChannel();
        }}
        onCancel={() =>
          setModalActionStatus({
            isOpenModalRemove: !modalActionStatus.isOpenModalRemove,
            isOpenModalAlreadyConfigured: false,
          })
        }
        buttonSaveName="Yes"
        type="success"
      />

      <ModalAction
        title={
          <div className="flex flex-col text-sm">
            <span>{hotelSelected?.name}</span>
            <span>Google Place ID already configured</span>
          </div>
        }
        message="This hotel is already configured with a Google Place ID. You need to remove the actual configuration before proceeding."
        open={modalActionStatus.isOpenModalAlreadyConfigured}
        setOpen={() =>
          setModalActionStatus({
            isOpenModalAlreadyConfigured:
              !modalActionStatus.isOpenModalAlreadyConfigured,
            isOpenModalRemove: false,
          })
        }
        onSave={() => {
          setModalActionStatus({
            isOpenModalAlreadyConfigured:
              !modalActionStatus.isOpenModalAlreadyConfigured,
            isOpenModalRemove: false,
          });
        }}
        onCancel={() =>
          setModalActionStatus({
            isOpenModalAlreadyConfigured:
              !modalActionStatus.isOpenModalAlreadyConfigured,
            isOpenModalRemove: false,
          })
        }
        buttonSaveName="Ok"
        type="success"
      />
    </div>
  );
};

export default GoogleRatings;
