import React, { useCallback, useMemo, useState } from 'react';
import BreadcrumbCreation, {
  Step,
} from '../../../../components/breadcrumb-creation';
import { Select } from 'antd';
import Repository from '../../../../config/repository';
import { Hotel, useUser } from '../../../../context/user';
import ModalAction from '../../../../components/modal-action';
import { useHistory } from 'react-router';
import { AxiosResponse } from 'axios';

const RateShopperProvider: React.FC = () => {
  const repository = new Repository();
  const { clientHotels, showAlert } = useUser();
  const history = useHistory();

  const [currentStep, setCurrentStep] = useState(0);
  const [provider, setProvider] = useState('');
  const [hotelId, setHotelId] = useState(undefined);
  const [openModalAction, setOpenModalAction] = useState(false);
  const [loading, setLoading] = useState(false);

  const hotelSelected = useMemo(
    () => clientHotels.find((hotel) => hotel.id === hotelId),
    [hotelId, clientHotels],
  );

  const steps: Step[] = useMemo(
    () => [
      {
        title: 'Hotel',
        subTitle: 'Select a Hotel',
        content: (
          <Select
            className="w-6/12"
            showSearch
            placeholder="Please select the hotel"
            filterOption={(input, option) => {
              if (option?.label && typeof option.label === 'string') {
                return (option.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }
              return true;
            }}
            value={hotelId}
            onChange={(value) => setHotelId(value)}
            options={clientHotels.map((value) => {
              return {
                value: value.id,
                label: value.name,
              };
            })}
          />
        ),
      },
      {
        title: 'Provider',
        subTitle: 'Add a provider',
        content: (
          <div className="flex flex-col">
            <label
              htmlFor="provider"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 dark:text-iron">
              Provider
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <input
                type="text"
                id="provider"
                value={provider}
                onChange={(event) => setProvider(event.target.value)}
                className="max-w-lg block w-full shadow-sm sm:text-sm border-gray-300 dark:bg-transparent dark:text-white"
              />
            </div>
          </div>
        ),
      },
    ],
    [hotelId, clientHotels, provider],
  );

  const save = useCallback(async () => {
    if (!hotelId) return;
    setLoading(true);

    try {
      const payload = { provider };
      await repository.api.updateHotelInfo(hotelId, payload);

      showAlert('Provider successfully saved', 'success', 'OK');
      history.push('/');
    } catch {
      showAlert('Error on saving provider', 'error', 'OK');
    }
    setLoading(false);
  }, [history, hotelId, provider]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchHotel = async (hotelId: string) => {
    try {
      setProvider('');
      setLoading(true);

      const response: AxiosResponse<Hotel> = await repository.global.fetchHotel(
        hotelId,
      );

      setProvider(response.data.provider);
    } catch {}
    setLoading(false);
  };

  return (
    <div className="flex bg-white p-4 overflow-hidden shadow-md dark:bg-ebonyClay relative">
      <BreadcrumbCreation
        currentStep={currentStep}
        steps={steps}
        loading={loading}
        onCancel={() => setCurrentStep((prevState) => prevState - 1)}
        onResolve={() => {
          if (currentStep === 0 && hotelId === undefined) {
            showAlert('Please select a hotel', 'error', 'OK');

            return;
          }

          setCurrentStep((prevState) => {
            if (prevState < steps.length - 1) {
              hotelId && fetchHotel(hotelId);

              return prevState + 1;
            }

            return prevState;
          });

          if (currentStep === steps.length - 1) {
            if (!provider.length) {
              showAlert('Please insert a provider', 'error', 'OK');
              return;
            }

            setOpenModalAction(!openModalAction);
          }
        }}
      />

      <ModalAction
        title={
          <div className="flex flex-col text-sm">
            <span>{hotelSelected?.name}</span>
          </div>
        }
        message={`Do you want to proceed with this provider ${provider} to this hotel?`}
        open={openModalAction}
        setOpen={() => setOpenModalAction(!openModalAction)}
        onSave={() => {
          setOpenModalAction(!openModalAction);
          save();
        }}
        onCancel={() => setOpenModalAction(!openModalAction)}
        buttonSaveName="Save"
        type="success"
      />
    </div>
  );
};

export default RateShopperProvider;
