import React, { useCallback, useMemo, useState } from 'react';
import BreadcrumbCreation, {
  Step,
} from '../../../components/breadcrumb-creation';
import { Select } from 'antd';
import Repository from '../../../config/repository';
import { useUser } from '../../../context/user';
import ModalAction from '../../../components/modal-action';
import { useHistory } from 'react-router';

interface Props {}

const repository = new Repository();

const LastImport: React.FC<Props> = () => {
  const { clientHotels, showAlert } = useUser();
  const [currentStep, setCurrentStep] = useState(0);
  const [hotelId, setHotelId] = useState(undefined);
  const [lastImport, setLastImport] = useState('');
  const [openModalAction, setOpenModalAction] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const hotelSelected = useMemo(
    () => clientHotels.find((hotel) => hotel.id === hotelId),
    [hotelId, clientHotels],
  );

  const connect = useCallback(async () => {
    if (!hotelId) return;

    setLoading(true);

    try {
      await repository.ai.updateLastImport(hotelId, lastImport);
      setLoading(false);
      showAlert('Updated last import with success', 'success', 'OK');
      history.push('/');
    } catch {
      setLoading(false);
      showAlert('Error to updated', 'error', 'OK');
    }
  }, [history, hotelId, lastImport, showAlert]);

  const steps: Step[] = useMemo(
    () => [
      {
        title: 'Hotel',
        subTitle: 'Select a Hotel',
        content: (
          <Select
            className="w-6/12"
            showSearch
            placeholder="Please select the hotel"
            filterOption={(input, option) => {
              if (option?.label && typeof option.label === 'string') {
                return (option.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }
              return true;
            }}
            value={hotelId}
            onChange={(value) => setHotelId(value)}
            options={clientHotels.map((value) => {
              return {
                value: value.id,
                label: value.name,
              };
            })}
          />
        ),
      },
      {
        title: 'Last Import Time Configuration',
        subTitle: 'Last Import Time',
        hiddenNext: true,
        content: (
          <div className="flex flex-col w-full mb-4">
            <div className="mb-4 mt-1 sm:mt-0 sm:col-span-2 flex items-center">
              <input
                className="w-5/12 bg-transparent border pl-2 py-2 dark:text-iron text-sm"
                placeholder="hh:mm"
                value={lastImport}
                type="time"
                onChange={(e) => setLastImport(e.target.value)}
              />
            </div>

            <div className="flex justify-between mr-auto">
              <button
                onClick={() => connect()}
                className="inline-flex items-center px-3 py-2 border border-indigo-600 text-xs leading-4 font-medium shadow-sm text-white bg-indigo-600 hover:bg-indigo-700">
                Save
              </button>
            </div>
          </div>
        ),
      },
    ],
    [connect, hotelId, clientHotels, lastImport],
  );

  return (
    <div className="flex bg-white p-4 overflow-hidden shadow-md dark:bg-ebonyClay relative">
      <BreadcrumbCreation
        currentStep={currentStep}
        steps={steps}
        loading={loading}
        onCancel={() => setCurrentStep((prevState) => prevState - 1)}
        onResolve={() => {
          if (currentStep === 0 && hotelId === undefined) {
            showAlert('Please select a hotel', 'error', 'OK');

            return;
          }

          setCurrentStep((prevState) => {
            if (prevState < steps.length - 1) {
              return prevState + 1;
            }

            return prevState;
          });

          if (currentStep === steps.length - 1) {
            setOpenModalAction(!openModalAction);
          }
        }}
      />

      <ModalAction
        title={
          <div className="flex flex-col text-sm">
            <span>{hotelSelected?.name}</span>
            <span>You will update the booking status ai sets.</span>
          </div>
        }
        message="Do you want to proceed?"
        open={openModalAction}
        setOpen={() => setOpenModalAction(!openModalAction)}
        onSave={() => {
          setOpenModalAction(!openModalAction);
        }}
        onCancel={() => setOpenModalAction(!openModalAction)}
        buttonSaveName="Save"
        type="success"
      />
    </div>
  );
};

export default LastImport;
