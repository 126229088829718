import ApiService from '../../../api-service';

interface IGenerateAbsoluteErrorPayload {
  start_date: string;
  end_date: string;
}

export default class AiAbsoluteErrorRepository {
  api = ApiService;

  generateAbsoluteErrorRn(
    hotelId: string | number,
    payload: IGenerateAbsoluteErrorPayload,
  ) {
    return this.api.post(
      `api/support/ai-generate-absolute-error-rn/${hotelId}`,
      payload,
    );
  }

  generateAbsoluteErrorRrev(
    hotelId: string | number,
    payload: IGenerateAbsoluteErrorPayload,
  ) {
    return this.api.post(
      `api/support/ai-generate-absolute-error-rrev/${hotelId}`,
      payload,
    );
  }
}
