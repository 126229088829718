import React, { useEffect, useMemo } from 'react';
import CustomRows from '../../../../../components/custom-rows';
import { useTables } from '../../../../../context/tables';

interface Props {
  hotelId: number;
  title: string;
  type: number;
  codes: { id: string | number; label: string }[];
}

const Rows: React.FC<Props> = ({ hotelId, title, type, codes }) => {
  const { setTables, tables } = useTables();

  const data = useMemo(
    () =>
      tables.apiBookingStatusSets.data
        .map((item) => {
          if (item.type === type && item.hotel_id === hotelId) {
            return {
              ...item,
              fields: [
                {
                  type: 'multipleSelect',
                  value: item.pms_statuses,
                  key: 'pms_statuses',
                  cls: 'w-8/12',
                },
              ],
            };
          }

          return null;
        })
        .filter((item) => item),
    [hotelId, tables.apiBookingStatusSets.data, type],
  );

  const currentItem = useMemo(() => data[0] ?? null, [data]);

  useEffect(() => {
    if (data.length > 0) return;

    setTables((prevState) => ({
      ...prevState,
      apiBookingStatusSets: {
        data: [
          ...prevState.apiBookingStatusSets.data,
          {
            type: type,
            id: null,
            hotel_id: hotelId,
            fields: [
              {
                type: 'multipleSelect',
                value: [],
                key: 'pms_statuses',
                cls: 'w-8/12',
              },
            ],
          },
        ],
      },
    }));
  }, [data.length]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <div className="flex justify-between">
        <p className="font-bold dark:text-white">{title}</p>
      </div>
      <CustomRows
        className="-mt-2 mb-4"
        rows={data}
        currentRow={currentItem}
        pmsOptions={codes}
        handleInput={(row, field, newValue) => {
          setTables((prevState) => ({
            ...prevState,
            apiBookingStatusSets: {
              data: prevState.apiBookingStatusSets?.data?.map((item) =>
                item.type === type && item.hotel_id === hotelId
                  ? { ...item, pms_statuses: newValue }
                  : item,
              ),
            },
          }));
        }}
      />
    </div>
  );
};

export default Rows;
