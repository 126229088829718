import styled from 'styled-components';

export const Container: any = styled.div`
  width: 100%;

  .dark {
    .ant-table {
      .ant-table-thead {
        .ant-table-cell {
          background-color: #283045 !important;
        }
      }
    }
  }

  .ant-table-thead {
    .ant-table-cell {
      background-color: #f3f2f7;
      padding: 0.54rem 1rem;
      color: #6e6b7b;
      font-weight: 700;
      font-size: 12px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
    }
  }

  .ant-table-tbody {
    .ant-table-cell {
      padding: 0.8rem 1rem;
      font-size: 12px;
    }
  }

  .ant-pagination {
    background-color: #f3f2f7;
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    width: max-content;
    align-items: flex-start;
    height: 26px;
    min-height: 26px;
    border-radius: 2px;
    margin-right: ${(props: any) =>
      props.hasAddnew ? '85px !important' : null};

    .ant-select-selection-item {
      font-size: 12px !important;
    }

    .ant-select {
      outline: 0 !important;
    }

    .ant-pagination-options {
      margin-left: 0px !important;
      height: 100% !important;
      display: flex !important;
      align-items: center !important;

      .ant-select {
        height: 100%;
        display: flex;
        align-items: center;
      }
    }

    .ant-select-selector {
      border: 0 !important;
      background-color: transparent !important;
    }

    .ant-pagination-prev {
      margin-right: 3px;
    }

    .ant-pagination-next {
      margin-left: -3px;
    }

    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-item {
      height: 100%;
      border: none;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        background-color: transparent;
      }
    }

    .ant-pagination-item {
      border-radius: 50px;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 28px;
      margin-right: 3px;

      a {
        color: #7367f0;
      }
    }

    .ant-pagination-item-active {
      background-color: #7367f0;

      a {
        color: #fff;
        font-weight: bold;
      }
    }
  }
`;
