import React, { useState, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import Accounts from './accounts';
import Events from './events';
import General from './general';
import MarketSegments from './market-segments';
import MarketSubSegments from './market-sub-segments';
import Nationalities from './nationalities';
import Rates from './rates';
import ResourceInventory from './resource-inventrory';
import RoomType from './room-type';
import Status from './status';

interface Props {
  match: any;
}

const Settings: React.FC<Props> = ({ match }) => {
  const [currentId, setCurrentId] = useState('');
  const location = useLocation();
  const routes = [
    {
      to: currentId
        ? `${match.url}/general/${currentId}`
        : `${match.url}/general/new`,
      label: 'General',
    },
    {
      to: `${match.url}/resource-invetory/${currentId}`,
      label: 'Resource Invetory',
    },
    {
      to: `${match.url}/accounts/${currentId}`,
      label: 'Accounts',
    },
    {
      to: `${match.url}/nationalities/${currentId}`,
      label: 'Nationalities',
    },
    {
      to: `${match.url}/room-type/${currentId}`,
      label: 'Room Type',
    },
    {
      to: `${match.url}/status/${currentId}`,
      label: 'Status',
    },
    {
      to: `${match.url}/market-segments/${currentId}`,
      label: 'Market Segments',
    },
    {
      to: `${match.url}/market-sub-segments/${currentId}`,
      label: 'Market Sub Segments',
    },
    {
      to: `${match.url}/events/${currentId}`,
      label: 'Events',
    },
  ];

  useEffect(() => {
    const split = location.pathname.split('/');
    const lastPath = split[split.length - 1];

    if (lastPath !== 'new') {
      setCurrentId(lastPath);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="flex w-full py-4 relative items-start">
      <div className="flex flex-col w-2/12">
        {routes.map(({ to, label }, index: number) => (
          <NavLink
            key={index}
            activeClassName={`${
              currentId || to === `${match.url}/general/new`
                ? 'bg-default text-white font-bold shadow-sm hover:bg-indigo-700'
                : ''
            }`}
            className={`${
              currentId || to === `${match.url}/general/new`
                ? 'hover:bg-default hover:text-white hover:font-bold'
                : 'opacity-40 cursor-default'
            } inline-flex items-center px-2.5 py-2 border border-transparent text-xs dark:text-white`}
            to={to}>
            {label}
          </NavLink>
        ))}
      </div>

      <div className="ml-8 w-10/12 p-6 bg-white shadow-md dark:bg-ebonyClay">
        <Switch>
          <Route path={`${match.url}/general/new`} component={General} />
          <Route path={`${match.url}/general/:id`} component={General} />

          {currentId && (
            <>
              <Route
                path={`${match.url}/market-segments/:id`}
                component={MarketSegments}
              />
              <Route
                path={`${match.url}/market-sub-segments/:id`}
                component={MarketSubSegments}
              />
              <Route path={`${match.url}/room-type/:id`} component={RoomType} />
              <Route path={`${match.url}/status/:id`} component={Status} />
              <Route path={`${match.url}/accounts/:id`} component={Accounts} />
              <Route
                path={`${match.url}/nationalities/:id`}
                component={Nationalities}
              />
              <Route path={`${match.url}/reates/:id`} component={Rates} />
              <Route path={`${match.url}/events/:id`} component={Events} />
              <Route
                path={`${match.url}/resource-invetory/:id`}
                component={ResourceInventory}
              />
            </>
          )}
        </Switch>
      </div>
    </div>
  );
};

export default Settings;
