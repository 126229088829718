import { Alert, Skeleton } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import Repository from '../../../../config/repository';
import { useUser } from '../../../../context/user';

interface Props {
  history: any;
  match: any;
}

export interface IChannelManagerForm {
  name: string;
  description: string;
}

export interface IChannelManagerFormUpdate extends IChannelManagerForm {
  id?: number;
}

const Form: React.FC<Props> = ({ history, match }) => {
  const repository = new Repository();
  const [showAlert, setShowAlert] = useState(false);
  const { showAlert: showAlertError } = useUser();

  const [loading, setLoading] = useState(true);
  const [form, setForm] = useState({
    id: undefined,
    name: '',
    description: '',
  });
  const [formErrors, setFormErrors] = useState({
    name: false,
    description: false,
  });

  const getChannelManager = useCallback(
    (id: string | number) => {
      repository.api
        .fetchChannelManagerById(id)
        .then((res) => {
          const { data } = res;
          setForm({
            id: data.id,
            name: data.name,
            description: data.description,
          });
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          showAlertError('Error while getting data', 'error', 'OK');
        });
    },
    [repository.api, showAlertError],
  );

  const goBack = useCallback(() => {
    history.goBack();
    resetForm();
  }, [history]);

  const alertChange = useCallback(() => {
    setShowAlert(true);

    setTimeout(() => {
      setShowAlert(false);
      goBack();
    }, 600);
  }, [goBack]);

  const editChannelManager = useCallback(
    (data: IChannelManagerFormUpdate) => {
      if (!data.id) return;

      repository.api
        .updateChannelManager(data.id, data)
        .then(() => {
          alertChange();
        })
        .catch((error) => {
          showAlertError(error?.response?.data?.message, 'error', 'OK');
        });
    },
    [alertChange, repository.api, showAlertError],
  );

  const saveChannelManager = useCallback(
    (data: IChannelManagerForm) => {
      repository.api
        .createChannelManager(data)
        .then(() => {
          alertChange();
        })
        .catch((error) => {
          showAlertError(error?.response?.data?.message, 'error', 'OK');
        });
    },
    [alertChange, repository.api, showAlertError],
  );

  const save = useCallback(() => {
    if (!form.name.trim() || !form.description.trim()) {
      setFormErrors({
        name: !form.name.trim(),
        description: !form.description.trim(),
      });
      return;
    }

    setFormErrors({
      name: false,
      description: false,
    });

    if (form.id) {
      editChannelManager(form);
      return;
    }

    saveChannelManager(form);
  }, [editChannelManager, form, saveChannelManager]);

  const resetForm = () => {
    setForm({
      id: undefined,
      name: '',
      description: '',
    });
  };

  useEffect(() => {
    resetForm();

    if (match.params.id) {
      getChannelManager(match.params.id);

      return;
    }

    setLoading(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="bg-white p-4 overflow-hidden shadow-md dark:bg-ebonyClay">
      <div>
        <div className="space-y-6">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-iron">
              {form.id ? form.name : 'New Channel Manager'}
            </h3>
          </div>
          {loading ? (
            <Skeleton />
          ) : (
            <div className="space-y-6 sm:space-y-5">
              <div className="flex flex-col">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 dark:text-iron">
                  Name
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="text"
                    name="name"
                    value={form.name}
                    onChange={(event) => {
                      setForm({ ...form, name: event?.target.value });
                      setFormErrors((old) => ({
                        ...old,
                        name: !event?.target.value.trim(),
                      }));
                    }}
                    className={`max-w-lg block w-full shadow-sm sm:text-sm ${
                      formErrors.name ? 'border-red-500' : 'border-gray-300'
                    }  dark:bg-transparent dark:text-white`}
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="description"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 dark:text-iron">
                  Description
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="text"
                    name="description"
                    value={form.description}
                    maxLength={254}
                    onChange={(event) => {
                      setForm({ ...form, description: event?.target.value });
                      setFormErrors((old) => ({
                        ...old,
                        description: !event?.target.value.trim(),
                      }));
                    }}
                    className={`block max-w-lg w-full shadow-sm sm:text-sm ${
                      formErrors.description
                        ? 'border-red-500'
                        : 'border-gray-300'
                    } dark:bg-transparent dark:text-white`}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <button
            onClick={() => goBack()}
            type="button"
            className="bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50">
            Cancel
          </button>
          <button
            onClick={() => save()}
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700">
            Save
          </button>
        </div>
      </div>
      {showAlert && (
        <Alert
          className="fixed right-0 top-0 mt-20 mr-4"
          message="Saved Changes"
          type="success"
          showIcon
        />
      )}
    </div>
  );
};

export default Form;
