import ApiService from '../../../api-service';

export default class MaintenanceModeRepository {
  api = ApiService;

  getLastMaintenanceMode() {
    return this.api.get('api/support/maintenance-mode');
  }

  createMaintenanceMode(data = {}) {
    return this.api.post(`api/support/maintenance-mode`, data);
  }
}
