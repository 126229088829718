import { Select } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import BreadcrumbCreation, {
  Step,
} from '../../../../components/breadcrumb-creation';
import Repository from '../../../../config/repository';
import { useUser } from '../../../../context/user';
import ModalAction from '../../../../components/modal-action';

interface Props {}

const repository = new Repository();

const RatesType: React.FC<Props> = () => {
  const { clientHotels, showAlert, updateHotelRateType } = useUser();
  const [currentStep, setCurrentStep] = useState(0);
  const [hotelId, setHotelId] = useState(undefined);

  const [loading, setLoading] = useState(false);
  const [openModalAction, setOpenModalAction] = useState(false);

  const history = useHistory();

  const hotelSelected = useMemo(
    () => clientHotels.find((hotel) => hotel.id === hotelId),
    [hotelId, clientHotels],
  );

  const [rateType, setRateType] = useState<0 | 1>();
  useEffect(() => {
    if (hotelId) {
      setRateType(hotelSelected?.rates_type);
    }
  }, [hotelId]); // eslint-disable-line react-hooks/exhaustive-deps

  const saveChanges = useCallback(async () => {
    if (!hotelId) return;

    setLoading(true);

    try {
      const data = {
        rates_type: rateType,
      };
      await repository.api.updateHotelInfo(hotelId, data);
      if (rateType !== undefined) {
        updateHotelRateType(hotelId, rateType);
      }
      setLoading(false);
      showAlert('Rate type changed successfully', 'success', 'OK');
      history.push('/');
    } catch {
      setLoading(false);
      showAlert('Error on changing rates type', 'error', 'OK');
    }
  }, [history, hotelId, showAlert, rateType, updateHotelRateType]);

  const rateTypeOptions = useMemo(
    () => [
      {
        value: 0,
        option: 'Default',
      },
      {
        value: 1,
        option: 'OCC',
      },
    ],
    [],
  );

  const steps: Step[] = useMemo(
    () => [
      {
        title: 'Hotel',
        subTitle: 'Select a Hotel',
        content: (
          <Select
            className="w-6/12"
            showSearch
            placeholder="Please select the hotel"
            filterOption={(input, option) => {
              if (option?.label && typeof option.label === 'string') {
                return (option.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }
              return true;
            }}
            value={hotelId}
            onChange={(value) => setHotelId(value)}
            options={clientHotels.map((value) => ({
              value: value.id,
              label: value.name,
            }))}
          />
        ),
      },
      {
        title: 'Rates type',
        subTitle: 'Select a Rate Type',
        content: (
          <div className="flex flex-col w-full mb-4">
            <div className="mb-4 mt-1 sm:mt-0 sm:col-span-2 flex items-center">
              <Select
                className="w-5/12"
                placeholder="Channel Manager"
                value={rateType}
                onChange={(value) => {
                  setRateType(value);
                }}>
                {rateTypeOptions.map((item, index: number) => (
                  <Select.Option value={item.value} key={index}>
                    {item.option}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
        ),
      },
    ],
    [hotelId, clientHotels, rateType, rateTypeOptions],
  );

  return (
    <div className="flex bg-white p-4 overflow-hidden shadow-md dark:bg-ebonyClay relative">
      <BreadcrumbCreation
        currentStep={currentStep}
        steps={steps}
        loading={loading}
        onCancel={() => setCurrentStep((prevState) => prevState - 1)}
        onResolve={() => {
          if (currentStep === 0 && hotelId === undefined) {
            showAlert('Please select a hotel', 'error', 'OK');

            return;
          }

          setCurrentStep((prevState) => {
            if (prevState < steps.length - 1) {
              return prevState + 1;
            }

            return prevState;
          });

          if (currentStep === steps.length - 1) {
            setOpenModalAction(!openModalAction);
          }
        }}
      />

      <ModalAction
        title={
          <div className="flex flex-col text-sm">
            <span>{hotelSelected?.name}</span>
            <span>You will update the rate type.</span>
          </div>
        }
        message="Do you want to proceed?"
        open={openModalAction}
        setOpen={() => setOpenModalAction(!openModalAction)}
        onSave={() => {
          setOpenModalAction(!openModalAction);
          saveChanges();
        }}
        onCancel={() => setOpenModalAction(!openModalAction)}
        buttonSaveName="Save"
        type="success"
      />
    </div>
  );
};

export default RatesType;
