import {
  CheckCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
  XIcon,
} from '@heroicons/react/outline';
import React, { useMemo } from 'react';

interface Props {
  style: any;
  message: any;
  options: any;
  close: () => void;
}

const Alert: React.FC<Props> = ({ style, message, options, close }) => {
  const getProps = useMemo(() => {
    let data = {
      messageColor: 'text-yellow-400',
      bgColor: 'bg-yellow-50',
      labelColor: 'text-yellow-500',
      buttonColor: 'text-yellow-500 hover:bg-yellow-100',
      typeMessage: 'Info',
      icon: <InformationCircleIcon className={`h-5 w-5 text-yellow-500`} />,
    };

    if (options.type === 'success') {
      data = {
        messageColor: 'text-green-700',
        bgColor: 'bg-green-50',
        labelColor: 'text-green-800',
        buttonColor: 'text-green-500 hover:bg-green-100',
        typeMessage: 'Success',
        icon: <CheckCircleIcon className={`h-5 w-5 text-green-800`} />,
      };
    }

    if (options.type === 'error') {
      data = {
        messageColor: 'text-red-700',
        bgColor: 'bg-red-50',
        labelColor: 'text-red-800',
        buttonColor: 'text-red-500 hover:bg-red-100',
        typeMessage: 'Error',
        icon: <XCircleIcon className={`h-5 w-5 text-red-800`} />,
      };
    }

    return data;
  }, [options.type]);

  return (
    <div
      style={{ minWidth: '300px', ...style }}
      className={`flex min-w-30 flex-col rounded-md p-4 mt-12 mr-4 ${getProps.bgColor}`}>
      <div className="flex">
        <div className="flex-shrink-0">{getProps.icon}</div>
        <div className="ml-3">
          <p className={`text-sm font-medium ${getProps.labelColor}`}>
            {getProps.typeMessage}
          </p>
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              onClick={close}
              type="button"
              className={`${getProps.bgColor} ${getProps.buttonColor} inline-flex rounded-md p-1.5 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2"`}>
              <XIcon className="h-5 w-5 cursor-pointer" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
      <div className="flex w-10/12 pl-1 mt-2">
        <p className={`text-sm font-medium ${getProps.messageColor}`}>
          {message}
        </p>
      </div>
    </div>
  );
};

export default Alert;
