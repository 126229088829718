import React, { useMemo } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router';
import Breadcrumb from '../../components/breadcrumb';
import BookingStatusSets from './booking-status-sets';
import ForceDatasetCreation from './force-dataset-creation';
import LastImport from './last-import';
import Outlier from './outlier';
import ForecastHotelSetup from './forecast-hotel-setup';
import AbsoluteErrorRn from './absolute-error/rn';
import AbsoluteErrorRrev from './absolute-error/rrev';

interface Props {
  history: any;
  match: any;
}

const Ai: React.FC<Props> = ({ match }) => {
  const history = useHistory();

  const currentTitle = useMemo(() => {
    if (history.location.pathname.includes('/ai/force-dataset')) {
      return 'Datasets';
    }

    return;
  }, [history.location.pathname]);

  return (
    <div className="w-full h-full">
      <div className="flex justify-between items-center mb-2">
        <Breadcrumb title={currentTitle} />
      </div>

      <Switch>
        <Redirect exact from={match.url} to={`${match.url}/list`} />

        <Route
          path={`${match.url}/booking-status-sets`}
          component={BookingStatusSets}
        />

        <Route
          path={`${match.url}/force-dataset`}
          component={ForceDatasetCreation}
        />

        <Route path={`${match.url}/last-import-time`} component={LastImport} />

        <Route path={`${match.url}/outlier`} component={Outlier} />

        <Route
          path={`${match.url}/forecast-hotel-setup`}
          component={ForecastHotelSetup}
        />

        <Route
          path={`${match.url}/absolute-error-rn`}
          component={AbsoluteErrorRn}
        />

        <Route
          path={`${match.url}/absolute-error-rrev`}
          component={AbsoluteErrorRrev}
        />
      </Switch>
    </div>
  );
};

export default Ai;
