/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';

import Repository from '../../../../config/repository';

import Table from '../../../../components/table';
import Filter from '../../../../components/filter';
import { useUser } from '../../../../context/user';
import { useTables } from '../../../../context/tables';

interface Columun {
  filter_key: string;
  sort_key: string;
  action_date: string;
  data_index: string;
  dataIndex: string;
  title: string;
  is_searchable: boolean;
  items: any[];
  search_type: string;
  cls: string;
}

const Files: React.FC = () => {
  const { refresh } = useUser();
  const { tables, setTables } = useTables();
  const repository = new Repository();
  const [columns, setColumns] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 10,
  });
  const [currentQueryParams, setCurrentQueryParams] = useState({});

  const getLogFilesSchema = useCallback(() => {
    repository.api.getLogFilesSchema().then((res) => {
      res.data.map((item: Columun) => {
        item.dataIndex = item.data_index;

        item.cls = 'w-4/12';

        return item;
      });

      setColumns(res.data);
    });
  }, [columns]);

  const getLogFiles = useCallback(
    (
      current = pagination.current,
      pageSize = pagination.pageSize,
      queryParams = {},
    ) => {
      setCurrentQueryParams(queryParams);

      repository.api.getLogFiles(current, pageSize, queryParams).then(
        (res) => {
          setPagination({
            ...res.data.meta.pagination,
            current: res.data.meta.pagination.current_page,
            pageSize: res.data.meta.pagination.per_page,
            total: res.data.meta.pagination.total || 10,
          });
          setData(res.data.data);
          setLoading(false);
        },
        () => {
          setLoading(false);
        },
      );
    },
    [data, pagination],
  );

  const onchangeTable = useCallback(
    (pagination: any, filter: any, sort: any) => {
      let by = null;
      let order = null;

      if (sort.column) {
        by = sort.column.sort_key;
        order = sort.order === 'ascend' ? 'asc' : 'desc';
      }

      setPagination(pagination);
      setLoading(true);
      getLogFiles(pagination.current, pagination.pageSize, {
        ...currentQueryParams,
        'sort[by]': by,
        'sort[order]': order,
      });
    },
    [pagination],
  );

  const changeColumn = useCallback(
    (value: any, column: any) => {
      setColumns(
        columns.filter((item: any) => {
          if (item.filter_key === column.filter_key) {
            item.value = value;
          }

          return item;
        }),
      );
    },
    [columns],
  );

  const search = () => {
    let queryParams: any = {};

    columns.map((item: any) => {
      if (!item.value || item.value.length === 0) {
        delete queryParams[item.filter_key];

        return item;
      }

      queryParams = {
        ...queryParams,
        [item.filter_key]: item.value,
      };

      if (item.filter_key === 'created_at') {
        queryParams['created_at[start_date]'] = formatDate(item.value[0]);

        queryParams['created_at[end_date]'] = formatDate(item.value[1]);

        delete queryParams[item.filter_key];
      }

      return item;
    });

    setLoading(true);
    setTables({
      ...tables,
      files: { ...tables.files, columns },
    });
    getLogFiles(1, 10, queryParams);
  };

  const reset = () => {
    setColumns(
      columns.filter((item: any) => {
        if (item.value) {
          delete item.value;
        }

        return item;
      }),
    );

    setLoading(true);
    getLogFiles();
  };

  const formatDate = (value: string) => {
    return moment(value).format('Y-MM-DD');
  };

  useEffect(() => {
    setColumns(tables.files.columns);
    setPagination(tables.files.pagination);
    setData(tables.files.data);
    setLoading(false);

    if (tables.files.columns.length === 0) {
      getLogFilesSchema();
    }

    if (tables.files.data.length === 0) {
      setLoading(true);
      getLogFiles(
        tables.files.pagination.current,
        tables.files.pagination.pageSize,
        currentQueryParams,
      );
    }
  }, [refresh]);

  useEffect(() => {
    setTables({
      ...tables,
      files: {
        columns,
        data,
        pagination,
      },
    });
  }, [columns, data, pagination]);

  return (
    <div className="table-report relative bg-white p-4 pb-0 overflow-hidden shadow-md dark:bg-ebonyClay">
      <div className="-mx-2">
        <Filter
          inputs={columns}
          sendValue={changeColumn}
          onReset={reset}
          onSearch={search}
        />
      </div>

      <Table
        data={data}
        columns={columns}
        pagination={pagination}
        loading={loading}
        rowKey="id"
        onChange={onchangeTable}
      />
    </div>
  );
};

export default Files;
