import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import { useUser } from '../context/user';
import Routes from '../routes';
import Menu from '../components/menu';
import { ArrowUpIcon } from '@heroicons/react/outline';

interface Props {}

const Default: React.FC<Props> = () => {
  const { isDark, token } = useUser();
  const [collapsed, setCollapsed] = useState(false);
  const [showButton, setShowButton] = useState(false);

  if (isDark) {
    document.getElementsByTagName('body')[0].classList.add('dark');
  }

  const goToTop = () => {
    document.getElementById('layout')?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    document.getElementById('layout')?.addEventListener('scroll', (e: any) => {
      if (e.target?.scrollTop > 100) {
        setShowButton(true);
        return;
      }

      setShowButton(false);
    });
  }, []);

  return (
    <Layout className={`flex flex-row`}>
      {token && <Menu isCollapsed={setCollapsed} />}
      <Layout
        id="layout"
        style={{
          width: `${collapsed || !token ? '100%' : 'calc(100% - 260px)'}`,
          marginTop: '4.5rem',
        }}
        className="site-layout dark:bg-mirage h-screen overflow-auto">
        <div className="flex w-full mx-auto px-8">
          <Routes />
        </div>
      </Layout>
      <div
        onClick={() => goToTop()}
        style={{ opacity: showButton ? 1 : 0 }}
        id="buttonGoTop">
        <ArrowUpIcon />
      </div>
    </Layout>
  );
};

export default Default;
