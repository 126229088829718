import React, { useState, useEffect, createRef } from "react";

interface Props {
  dropCallback: Function;
  DropOverlay: () => JSX.Element;
}

const DragAndDrop: React.FC<Props> = ({
  dropCallback,
  DropOverlay,
  children
}) => {
  const [dragging, setDrag] = useState(false);
  const [dragCounter, setCounter] = useState(0);

  const dropRef = createRef<HTMLDivElement>();

  useEffect(() => {
    const handleDrag = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const handleDragIn = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setCounter(dragCounter + 1);
      if (e.dataTransfer?.items && e.dataTransfer.items.length > 0) {
        setDrag(true);
      }
    };

    const handleDragOut = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setCounter(dragCounter - 1);
      if (dragCounter <= 1) {
        setDrag(false);
      }
    };

    const handleDrop = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setDrag(false);
      if (e.dataTransfer?.files && e.dataTransfer.files.length > 0) {
        dropCallback(e.dataTransfer.files);
        // e.dataTransfer.clearData()
        setCounter(0);
      }
    };

    const container = dropRef.current;
    container?.addEventListener("dragenter", handleDragIn);
    container?.addEventListener("dragleave", handleDragOut);
    container?.addEventListener("dragover", handleDrag);
    container?.addEventListener("drop", handleDrop);
    return () => {
      container?.removeEventListener("dragenter", handleDragIn);
      container?.removeEventListener("dragleave", handleDragOut);
      container?.removeEventListener("dragover", handleDrag);
      container?.removeEventListener("drop", handleDrop);
    };
  }, [dropRef, dragCounter, dropCallback]);

  return (
    <div className="relative inline-block w-full" ref={dropRef}>
      {dragging && <DropOverlay></DropOverlay>}
      {children}
    </div>
  );
};

export default DragAndDrop;
