import styled from 'styled-components';

export const Container: any = styled.div`
  position: fixed;
  width: ${(props: any) => props.width};
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  height: 62px;
  background-color: #fff;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  padding: 10px 25px;
  z-index: 5;
  transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background 0s;
`;

export const InfosUser = styled.div`
  display: flex;
  text-align: right;
  flex-direction: column;
  margin-top: 2px;
  margin-right: 12px;
`;

export const NameUser = styled.p`
  color: #6e6b7b;
  font-weight: 600;
  margin: 0;
  line-height: 15px;
`;

export const TypeUser = styled.span`
  color: #6e6b7b;
  font-size: 12px;
  margin-top: 2px;
  font-weight: 500;
`;

export const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

export const Icons = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 50%;

  .border-r {
    color: #6e6b7b;
  }

  svg {
    margin: 0px 5px;
    cursor: pointer;
    width: 21px;
    color: #6e6b7b;
  }
`;
