import React from 'react';
import {
  Select,
  DatePicker,
  Switch,
  InputNumber,
  Checkbox,
  Collapse,
} from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;
const { Panel } = Collapse;

interface IOption {
  name: string;
  id: number;
}

const Filter: React.FC<any> = ({ inputs, sendValue, onReset, onSearch }) => {
  const handleChangeInput = (value: any, input: any) => {
    sendValue(value, input);
  };

  return (
    <Collapse defaultActiveKey={['1']}>
      <Panel header="Filter" key="1" className="dark:text-iron">
        <div className="flex flex-col w-full">
          <div className="flex flex-wrap">
            {inputs.map((item: any) => {
              if (!item.is_searchable) {
                return null;
              }

              return (
                <div
                  key={item.data_index}
                  className={`${item.cls} flex flex-col px-2 mb-3 dark:bg-dark-input`}>
                  <span className="text-xs mb-1 dark:text-iron">
                    {item.title}
                  </span>

                  {item.search_type === 'search_box' && (
                    <Select
                      className="w-full"
                      mode="tags"
                      value={item.value}
                      dropdownMatchSelectWidth={false}
                      onChange={(e) => handleChangeInput(e, item)}
                      filterOption={(input, option) =>
                        option?.props.children
                          .toLowerCase()
                          .indexOf(input?.toLowerCase()) >= 0
                      }>
                      {item.items &&
                        item.items.map((option: any, i: number) => (
                          <Select.Option
                            key={i}
                            value={option[item.data_index]}>
                            {option.name ??
                              option.hotel_name ??
                              option[item.data_index]}
                          </Select.Option>
                        ))}
                    </Select>
                  )}

                  {item.search_type === 'simpleSelect' && (
                    <Select
                      className="w-full"
                      value={item.value}
                      dropdownMatchSelectWidth={false}
                      onChange={(e) => handleChangeInput(e, item)}>
                      {item.items &&
                        item.items.map((option: any, i: number) => (
                          <Select.Option key={i} value={option.id}>
                            {option.name}
                          </Select.Option>
                        ))}
                    </Select>
                  )}

                  {item.search_type === 'searchSelect' && (
                    <Select
                      className="w-6/12"
                      showSearch
                      placeholder={item?.placeholder}
                      filterOption={(input, option) => {
                        if (option?.label && typeof option.label === 'string') {
                          return (option.label ?? '')
                            .toLowerCase()
                            .includes(input.toLowerCase());
                        }
                        return true;
                      }}
                      value={item.value}
                      onChange={(e) => handleChangeInput(e, item)}
                      options={
                        item.items &&
                        item.items.map((option: IOption) => {
                          return {
                            value: option.id,
                            label: option.name,
                          };
                        })
                      }
                    />
                  )}

                  {item.search_type === 'interval_date_picker' && (
                    <RangePicker
                      value={item.value}
                      format={'DD/MM/YYYY'}
                      defaultValue={undefined}
                      onChange={(e) => handleChangeInput(e, item)}
                    />
                  )}
                  {item.search_type === 'toggle_switch' && (
                    <Switch
                      className="w-full mt-2"
                      checked={item.value}
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      onChange={(e) => handleChangeInput(e ? 1 : 0, item)}
                    />
                  )}
                  {item.search_type === 'input_number' && (
                    <InputNumber
                      className="w-full"
                      value={item.value}
                      defaultValue={undefined}
                      onChange={(e) => handleChangeInput(e, item)}
                    />
                  )}
                  {item.search_type === 'checkbox' && (
                    <div className="flex">
                      <Checkbox
                        className="w-full"
                        checked={item.value[0]?.checked}
                        onChange={(e) =>
                          handleChangeInput(
                            {
                              ...item.items[0],
                              checked: e.target.checked,
                              position: 0,
                            },
                            item,
                          )
                        }>
                        {item.items[0].name}
                      </Checkbox>
                      <Checkbox
                        className="w-full"
                        checked={item.value[1]?.checked}
                        onChange={(e) =>
                          handleChangeInput(
                            {
                              ...item.items[1],
                              checked: e.target.checked,
                              position: 1,
                            },
                            item,
                          )
                        }>
                        {item.items[1].name}
                      </Checkbox>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          {inputs.length > 0 && (
            <div className="flex justify-end mr-2">
              <button
                onClick={() => onReset()}
                type="button"
                className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-xs leading-4 font-medium text-gray-700 bg-white hover:bg-gray-50">
                Reset
              </button>
              <button
                onClick={() => onSearch()}
                type="button"
                className="inline-flex items-center px-3 py-2 ml-2 border border-indigo-600 text-xs leading-4 font-medium shadow-sm text-white bg-indigo-600 hover:bg-indigo-700">
                Search
              </button>
            </div>
          )}

          <hr className="mt-5 mb-1 mx-2" />
        </div>
      </Panel>
    </Collapse>
  );
};

export default Filter;
