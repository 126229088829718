import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import BreadcrumbCreation, {
  Step,
} from '../../../../components/breadcrumb-creation';
import { InputNumber, Select, Skeleton, Slider } from 'antd';
import Repository from '../../../../config/repository';
import { useUser } from '../../../../context/user';
import ModalAction from '../../../../components/modal-action';
import { useHistory } from 'react-router';

interface RoomData {
  id: number;
  name: string;
  rate_id: number;
  rate_name: string;
  room_id: number;
  room_name: string;
  is_derived: boolean;
  value: number;
  yield_room_rate_id: number | null;
  derived_option: string | null;
}

const BaseRoomRate: React.FC = () => {
  const repository = new Repository();
  const { clientHotels, showAlert } = useUser();
  const history = useHistory();

  const [currentStep, setCurrentStep] = useState(0);
  const [hotelId, setHotelId] = useState(undefined);
  const [roomRates, setRoomRates] = useState<RoomData[]>([]);
  const [baseRoomRate, setBaseRoomRate] = useState(undefined);
  const [strategicAlignment, setStrategicAlignment] = useState(0);
  const [openModalAction, setOpenModalAction] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditOrRemove, setIsEditOrRemove] = useState(false);

  const prevLoadingRef = useRef(loading);

  const hotelOptions = useMemo(
    () => clientHotels.map((value) => ({ value: value.id, label: value.name })),
    [clientHotels],
  );

  const roomRatesOptions = useMemo(
    () => roomRates.map((value) => ({ label: value.name, value: value.id })),
    [roomRates],
  );

  const hotelSelected = useMemo(
    () => clientHotels.find((hotel) => hotel.id === hotelId),
    [hotelId, clientHotels],
  );

  const sliderMarks = useMemo(
    () => ({
      0: {
        label: (
          <p className="text-xs text-78849E absolute left-0 -ml-9 -mt-4">OCC</p>
        ),
      },
      100: {
        label: (
          <p className="text-xs text-78849E absolute right-0 -mr-9 -mt-4">
            ARR
          </p>
        ),
      },
    }),
    [],
  );

  const steps: Step[] = useMemo(
    () => [
      {
        title: 'Hotel',
        subTitle: 'Select a Hotel',
        content: (
          <Select
            className="w-6/12"
            showSearch
            placeholder="Please select the hotel"
            filterOption={(input, option) => {
              if (option?.label && typeof option.label === 'string') {
                return (option.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }
              return true;
            }}
            value={hotelId}
            onChange={(value) => setHotelId(value)}
            options={hotelOptions}
          />
        ),
        disabledNext: error,
      },
      {
        title: 'Base Room Rate',
        subTitle: 'Configure base room rate',
        content: (
          <>
            {loading ? (
              <Skeleton />
            ) : (
              <div>
                <div className="flex flex-col w-full mb-4">
                  <span className="pb-2">Room Rate Associations</span>
                  <div className="mb-4 mt-1 sm:mt-0 sm:col-span-2 flex items-center">
                    <Select
                      className="max-w-lg block w-full shadow-sm sm:text-sm dark:bg-transparent"
                      placeholder="Please select the base room rate"
                      showSearch
                      filterOption={(input, option) => {
                        if (option?.label && typeof option.label === 'string') {
                          return (option.label ?? '')
                            .toLowerCase()
                            .includes(input.toLowerCase());
                        }
                        return true;
                      }}
                      options={roomRatesOptions}
                      value={baseRoomRate}
                      onChange={(e) => setBaseRoomRate(e)}
                    />
                  </div>
                </div>

                <div className="flex flex-col w-full">
                  <span className="pb-2">Strategic Aligment</span>
                  <div className="flex items-center ml-8">
                    <Slider
                      className="w-3/12 sm:w-6/12"
                      min={0}
                      max={100}
                      marks={sliderMarks}
                      onChange={(value) => setStrategicAlignment(value)}
                      value={strategicAlignment}
                    />
                    <InputNumber
                      className="w-16 ml-10 -mt-4"
                      placeholder="0"
                      min={0}
                      max={100}
                      value={strategicAlignment}
                      defaultValue={undefined}
                      onChange={(e) => {
                        setStrategicAlignment(e);
                      }}
                    />
                    <p className="text-xs -mt-4 ml-4 text-78849E sm:hidden">
                      (Insert manual percentage)
                    </p>
                  </div>
                </div>
              </div>
            )}
          </>
        ),
      },
    ],
    [
      baseRoomRate,
      error,
      hotelId,
      hotelOptions,
      loading,
      roomRatesOptions,
      sliderMarks,
      strategicAlignment,
    ],
  );

  useEffect(() => {
    if (!hotelId) return;

    setLoading(true);
    setError(false);
    getRoomRates(hotelId);
  }, [hotelId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isEditOrRemove && prevLoadingRef.current && loading === false) {
      setOpenModalAction(true);
    }

    prevLoadingRef.current = loading;
  }, [loading, isEditOrRemove]);

  const remove = useCallback(async () => {
    try {
      if (!hotelId) return;

      await repository.baseRoomRateRepository.removeBaseRoomRate(hotelId);

      setIsEdit(false);
      setIsEditOrRemove(false);

      showAlert('Base Room Rate removed to this hotel', 'success', 'OK');
      history.push('/');
    } catch (error: any) {
      showAlert(
        error.response ? error.response.data.message : 'Error saving changes',
        'error',
        'OK',
      );
    }
  }, [hotelId]); // eslint-disable-line react-hooks/exhaustive-deps

  const save = useCallback(
    async () => {
      try {
        if (!hotelId) return;
        if (!baseRoomRate || !strategicAlignment)
          return showAlert('Both fields are required', 'error', 'OK');

        const payload = {
          room_rate_id: baseRoomRate,
          strategic_alignment: strategicAlignment,
        };

        isEdit
          ? await repository.baseRoomRateRepository.editBaseRoomRate(
              hotelId,
              payload,
            )
          : await repository.baseRoomRateRepository.addBaseRoomRate(
              hotelId,
              payload,
            );

        showAlert('Base Room Rate configured to this hotel', 'success', 'OK');
        history.push('/');
      } catch (error: any) {
        showAlert(
          error.response ? error.response.data.message : 'Error saving changes',
          'error',
          'OK',
        );
        setCurrentStep(0);
      }
    },
    [hotelId, baseRoomRate, strategicAlignment, history], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const getRoomRates = async (hotelId: number) => {
    try {
      const params = { active_rooms: 0, active_rates: 0 };
      const response =
        await repository.baseRoomRateRepository.fetchRoomRateAssociations(
          hotelId,
          params,
        );

      setRoomRates(response.data.data);
      getSettings(hotelId);
    } catch (error: any) {
      setError(true);
      showAlert(
        error.response ? error.response.data.message : 'Error getting data',
        'error',
        'OK',
      );
      setCurrentStep(0);
    }
  };

  const getSettings = async (hotelId: number) => {
    setBaseRoomRate(undefined);
    setStrategicAlignment(0);
    try {
      const response = await repository.baseRoomRateRepository.getSettings(
        hotelId,
      );

      setBaseRoomRate(response.data.data.base_room_rate_id);
      setStrategicAlignment(response.data.data.strategic_alignment);
      setIsEdit(true);
      setIsEditOrRemove(true);
    } catch {}
    setLoading(false);
  };

  return (
    <div className="flex bg-white p-4 overflow-hidden shadow-md dark:bg-ebonyClay relative">
      <BreadcrumbCreation
        currentStep={currentStep}
        steps={steps}
        onCancel={() => setCurrentStep((prevState) => prevState - 1)}
        onResolve={() => {
          if (currentStep === 0 && hotelId === undefined) {
            showAlert('Please select a hotel', 'error', 'OK');

            return;
          }

          setCurrentStep((prevState) => {
            if (prevState < steps.length - 1) {
              return prevState + 1;
            }

            return prevState;
          });

          if (currentStep === steps.length - 1) {
            setOpenModalAction(!openModalAction);
          }
        }}
      />

      <ModalAction
        title={
          <div className="flex flex-col text-sm">
            <span>{hotelSelected?.name}</span>
            {isEditOrRemove ? (
              <span>This hotel already has base room rate.</span>
            ) : (
              <span>You will define the base room rate for this hotel.</span>
            )}
          </div>
        }
        message={
          isEditOrRemove
            ? 'Do you want to proceed or remove the data?'
            : 'Do you want to proceed?'
        }
        open={openModalAction}
        setOpen={() => {
          setOpenModalAction(!openModalAction);
          setTimeout(() => {
            isEditOrRemove && setIsEditOrRemove(false);
          }, 200);
        }}
        onSave={() => {
          setOpenModalAction(!openModalAction);
          isEditOrRemove ? remove() : save();
        }}
        onCancel={() => {
          setOpenModalAction(!openModalAction);
          setTimeout(() => {
            isEditOrRemove && setIsEditOrRemove(false);
          }, 200);
        }}
        buttonCancelName={isEditOrRemove ? 'Proceed' : ''}
        buttonSaveName={isEditOrRemove ? 'Remove' : 'Save'}
        type="success"
      />
    </div>
  );
};

export default BaseRoomRate;
