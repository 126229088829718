import { Alert, Input, Select, Skeleton } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import { useLocation } from 'react-router';
import Repository from '../../../../config/repository';
import { useUser } from '../../../../context/user';
import { BookingAgentForm } from './list';

interface Props {
  history: any;
  match: any;
}

const Form: React.FC<Props> = ({ history, match }) => {
  const repository = new Repository();
  const { showAlert: showAlertError } = useUser();

  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(true);
  const agentOperations = ['rates', 'availability', 'restrictions'];
  const { TextArea } = Input;

  const [form, setForm] = useState<BookingAgentForm>({
    id: undefined,
    name: '',
    code: '',
    description: '',
    agent_operations: [],
    channel_manager_id: '',
  });

  const { search } = useLocation();
  const channelManagerId = search.match(/\d+(\.\d+)?/g)?.[0];

  const goBack = useCallback(() => {
    history.replace({
      pathname: '/api/booking-agents/list',
      state: {
        channelManagerId: form.channel_manager_id || channelManagerId,
      },
    });
    resetForm();
  }, [channelManagerId, form.channel_manager_id, history]);

  const alertChange = useCallback(() => {
    setShowAlert(true);

    setTimeout(() => {
      setShowAlert(false);
      goBack();
    }, 600);
  }, [goBack]);

  const getBookingAgentById = useCallback(
    async (id: string | number) => {
      try {
        const { data } = await repository.api.getBookingAgentById(id);
        setForm({
          id: data.id,
          code: data.code,
          agent_operations: data.agent_operations,
          description: data.description,
          name: data.name,
          channel_manager_id: data.channel_manager_id,
        });
        setLoading(false);
      } catch {
        showAlertError('Error while getting data', 'error', 'OK');
        setLoading(false);
      }
    },
    [repository.api, showAlertError],
  );

  const editBookingAgent = useCallback(
    async (data: BookingAgentForm) => {
      if (!data.id) return;

      try {
        await repository.api.updateOneBookingAgent(data.id, {
          ...data,
        });
        alertChange();
        setLoading(false);
      } catch (error: any) {
        showAlertError(error.response.data.message, 'error', 'OK');
        setLoading(false);
      }
    },
    [alertChange, repository.api, showAlertError],
  );

  const saveBookingAgent = useCallback(
    async (data: BookingAgentForm) => {
      try {
        await repository.api.createBookingAgent({
          ...data,
          channel_manager_id: channelManagerId,
        });
        alertChange();
      } catch (error: any) {
        showAlertError(error?.response?.data?.message, 'error', 'OK');
        setLoading(false);
      }
    },
    [alertChange, channelManagerId, repository.api, showAlertError],
  );

  const save = useCallback(() => {
    if (form.id) {
      editBookingAgent(form);

      return;
    }

    saveBookingAgent(form);
  }, [editBookingAgent, form, saveBookingAgent]);

  const resetForm = () => {
    setForm({
      id: undefined,
      name: '',
      code: '',
      description: '',
      agent_operations: [],
      channel_manager_id: '',
    });
  };

  useEffect(() => {
    resetForm();

    if (match.params.id) {
      getBookingAgentById(match.params.id);
      return;
    }

    setLoading(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="bg-white p-4 overflow-hidden shadow-md dark:bg-ebonyClay ">
      <div>
        <div className="space-y-6">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-iron">
              {form.id ? `Update ${form.name}` : 'New  Booking Agent'}
            </h3>
          </div>
          {loading ? (
            <Skeleton />
          ) : (
            <div className="space-y-6 sm:space-y-5">
              <div className="flex flex-col">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 dark:text-iron">
                  Name
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="text"
                    name="name"
                    value={form.name}
                    onChange={(event) =>
                      setForm({ ...form, name: event?.target.value })
                    }
                    className="max-w-lg block w-full shadow-sm sm:text-sm border-gray-300 dark:bg-transparent dark:text-white"
                  />
                </div>
              </div>

              <div className="flex flex-col">
                <label
                  htmlFor="code"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 dark:text-iron">
                  Code
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="text"
                    name="code"
                    value={form.code}
                    onChange={(event) =>
                      setForm({ ...form, code: event?.target.value })
                    }
                    className="block max-w-lg w-full shadow-sm sm:text-sm border-gray-300 dark:bg-transparent dark:text-white"
                  />
                </div>
              </div>

              <div className="flex flex-col">
                <label
                  htmlFor="agent"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 dark:text-iron">
                  Agent Operations
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Select
                    mode="multiple"
                    id="agent"
                    placeholder="Please select"
                    value={form.agent_operations}
                    className="block max-w-lg w-full shadow-sm sm:text-sm border-gray-300 dark:bg-transparent dark:text-white"
                    onChange={(value) => {
                      setForm({ ...form, agent_operations: value });
                    }}>
                    {agentOperations.map((item, index: number) => (
                      <Select.Option value={item} key={index}>
                        {item}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="description"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 dark:text-iron">
                  Description
                </label>
                <TextArea
                  id="description"
                  value={form.description}
                  onChange={(event) =>
                    setForm({ ...form, description: event?.target.value })
                  }
                  className="block max-w-lg w-full shadow-sm sm:text-sm border-gray-300 dark:bg-transparent dark:text-white"
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <button
            onClick={() => goBack()}
            type="button"
            className="bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50">
            Cancel
          </button>
          <button
            onClick={() => save()}
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700">
            Save
          </button>
        </div>
      </div>
      {showAlert && (
        <Alert
          className="fixed right-0 top-0 mt-20 mr-4"
          message="Saved Changes"
          type="success"
          showIcon
        />
      )}
    </div>
  );
};

export default Form;
