import axios from 'axios';
import jwtDecode from 'jwt-decode';

const apiService = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    'application-id': '306d5914-68af-45f6-9a39-573246578b7b',
    'application-secret-key': 'mg3mo91ptOXBBlUIOYOhB7VPjSeiDJdS',
  },
});

const getAccessToken = () => {
  const access = localStorage.getItem('token');

  if (!access) return;

  const decoded: any = jwtDecode(access);
  const exp = decoded.exp;
  const currentTime = new Date().getTime() / 1000;

  if (currentTime > exp) {
    localStorage.removeItem('token');
    window.location.pathname = '/login';

    return;
  }

  return access;
};

apiService.interceptors.request.use((config) => {
  return {
    ...config,
    headers: { ...config.headers, Authorization: `Bearer ${getAccessToken()}` },
  };
});

export default apiService;
