import ApiService from '../../api-service';

export default class AiRepository {
  api = ApiService;

  fetchAiBookingStatusMappings() {
    return this.api.get(`api/support/ai-booking-status-mappings`);
  }

  fetchAiBookingStatusMappingById(id: string) {
    return this.api.get(`api/support/ai-booking-status-mappings/${id}`);
  }

  createAiBookingStatusMapping(data = {}) {
    return this.api.post(`api/support/ai-booking-status-mappings`, data);
  }

  createAiBookingStatusMappingBulk(data = {}) {
    return this.api.post(`api/support/ai-booking-status-mappings/bulk`, data);
  }

  updateAiBookingStatusMapping(id: string, data = {}) {
    return this.api.put(`api/support/ai-booking-status-mappings/${id}`, data);
  }

  deleteAiBookingStatusMapping(id: string) {
    return this.api.delete(`api/support/ai-booking-status-mappings/${id}`);
  }

  createAiDispatcher(data = {}) {
    return this.api.post(`api/support/ai-dispatcher`, data);
  }

  createAiOutlier(hotelId: number, data = {}) {
    return this.api.post(`api/support/hotels/${hotelId}/outlier`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  updateLastImport(id: string | number, last_import_time: string) {
    return this.api.patch(`api/support/hotels/${id}`, { last_import_time });
  }
}
