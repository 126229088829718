import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import Breadcrumb from '../../components/breadcrumb';
import MarketSegments from './pages/market-segments';
import BookingStatusSets from './pages/booking-status-sets';
import BookingAgentsLinkage from './pages/booking-agents-linkage';
import ConfigurationWizard from './pages/configuration-wizard';
import GoogleRatings from './pages/google-ratings';
import Report from './pages/report';
import Files from './pages/files';
import RateShopper from './pages/rate-shopper-linked';
import RateShopperLink from './pages/rate-shopper-link';
import UnblockedHotels from './pages/unblocked-hotels';
import ChannelManager from './pages/channel-manager';
import RatesType from './pages/rates-type';
import BookingAgents from './pages/booking-agents';
import ReportsSchedule from './pages/reports-schedule';
import MaintenanceMode from './pages/maintenance-mode';
import AutopilotEmails from './pages/autopilot-emails';
import BaseRoomRate from './pages/base-room-rate';
import RateShopperProvider from './pages/rate-shopper-provider';
import PriceRecommendation from './pages/price-recommendation';
import ClearCache from './pages/clear-cache';
import Trivago from './pages/trivago';
import HistoricalData from './pages/historical-data';
import ActivateDeactivateMP from './pages/activate-deactivate';

interface Props {
  match: any;
}

const Api: React.FC<Props> = ({ match }) => {
  return (
    <div className="w-full h-full">
      <div className="flex justify-between items-center mb-2">
        <Breadcrumb />
      </div>

      <Switch>
        <Redirect exact from={match.url} to={`${match.url}/report`} />

        <Route path={`${match.url}/rate-shopper`} component={RateShopper} />
        <Route
          path={`${match.url}/rate-shopper-link`}
          component={RateShopperLink}
        />
        <Route path={`${match.url}/provider`} component={RateShopperProvider} />

        <Route path={`${match.url}/report/list`} component={Report} />
        <Route path={`${match.url}/files/list`} component={Files} />

        <Route
          path={`${match.url}/reports-schedule`}
          component={ReportsSchedule}
        />

        <Route
          path={`${match.url}/market-segments`}
          component={MarketSegments}
        />

        <Route
          path={`${match.url}/booking-status-sets`}
          component={BookingStatusSets}
        />

        <Route path={`${match.url}/booking-agents`} component={BookingAgents} />
        <Route
          path={`${match.url}/booking-agents-linkage`}
          component={BookingAgentsLinkage}
        />

        <Route
          path={`${match.url}/configuration-wizard`}
          component={ConfigurationWizard}
        />
        <Route path={`${match.url}/rates-type`} component={RatesType} />
        <Route path={`${match.url}/trivago`} component={Trivago} />

        <Route path={`${match.url}/google-ratings`} component={GoogleRatings} />

        <Route
          path={`${match.url}/channel-manager`}
          component={ChannelManager}
        />

        <Route
          path={`${match.url}/unblocked-hotels`}
          component={UnblockedHotels}
        />
        <Route
          path={`${match.url}/maintenance-mode`}
          component={MaintenanceMode}
        />
        <Route path={`${match.url}/clear-cache`} component={ClearCache} />

        <Route
          path={`${match.url}/autopilot-emails`}
          component={AutopilotEmails}
        />
        <Route path={`${match.url}/base-room-rate`} component={BaseRoomRate} />
        <Route path={`${match.url}/price-recommendation`} component={PriceRecommendation} />

        <Route path={`${match.url}/historical-data`} component={HistoricalData} />
        <Route path={`${match.url}/activate-deactivate`} component={ActivateDeactivateMP} />
      </Switch>
    </div>
  );
};

export default Api;
