import ApiService from "../../../api-service";

export default class ApiReportsScheduleRepository {
    api = ApiService;

    getReportsSchedule(page = 1, limit = 10, queryParams = {}) {
        return this.api.get(`api/support/reports/list?page=${page}&limit=${limit}`, {
            params: queryParams,
        });
    }

    getReportsScheduleByHotel(hotelId: string, page = 1, limit = 10, queryParams = {}) {
        return this.api.get(`api/support/reports/hotels/${hotelId}/list?page=${page}&limit=${limit}`, {
            params: queryParams,
        });
    }

    createReportSchedule(data = {}) {
        return this.api.post(`api/support/reports`, data);
    }

    updateReportSchedule(id: string | number, data = {}) {
        return this.api.put(`api/support/reports/${id}`, data);
    }

    getReportScheduleById(id: string | number) {
        return this.api.get(`api/support/reports/${id}`);
    }

    deleteReportSchedule(id: string | number,) {
        return this.api.delete(`api/support/reports/${id}`);
    }
}
