import { PencilIcon, TrashIcon } from '@heroicons/react/outline';
import { Alert, Select, Skeleton, TablePaginationConfig } from 'antd';
import { AxiosResponse } from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import ModalAction from '../../../../components/modal-action';
import Table from '../../../../components/table';
import Repository from '../../../../config/repository';
import { useUser } from '../../../../context/user';
import { useLocation } from 'react-router-dom';
import Filter from '../../../../components/filter';
import moment from 'moment';

export interface BookingAgentForm {
  id?: number;
  channel_manager_id: number | string;
  code: string;
  description: string;
  name: string;
  agent_operations: string[];
}

export interface BookingAgent extends Omit<BookingAgentForm, 'id'> {
  id: number;
  created_at: string;
  updated_at: string;
}

export interface BookingAgentData
  extends Omit<BookingAgent, 'agent_operations'> {
  agent_operations: string;
}

export interface IChannelManager {
  id: number;
  name: string;
  description: string;
}

export interface IFilterColumn {
  data_index: string;
  title: string;
  items?: IItem[];
  value?: string | number | string[];
  is_searchable: boolean;
  search_type?: string;
  sort_key?: string;
  cls: string;
}

export interface IItem {
  value: string;
  name?: string;
  code?: string;
}

interface ICustomState {
  channelManagerId: string;
}

const repository = new Repository();

const BookingAgents: React.FC = () => {
  const history = useHistory();
  const location = useLocation<ICustomState>();

  const { showAlert: showAlertError } = useUser();

  const [channelManagers, setChannelManagers] = useState<IChannelManager[]>([]);
  const [channelManagerId, setChannelManagerId] = useState<string>();

  const [openModalAction, setOpenModalAction] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingChannels, setLoadingChannels] = useState(true);
  const [showAlert, setShowAlert] = useState(false);

  const [data, setData] = useState<BookingAgentData[]>([]);
  const [originalData, setOriginalData] = useState<BookingAgentData[]>([]);
  const [currentItem, setCurrentItem] = useState({ id: '', name: '' });

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 10,
  });

  const [filterInputs, setFilterInputs] = useState<IFilterColumn[]>([
    {
      data_index: 'id',
      title: 'Id',
      is_searchable: true,
      search_type: 'input_number',
      cls: 'w-2/12',
    },
    {
      data_index: 'name',
      title: 'Name',
      sort_key: 'name',
      is_searchable: true,
      search_type: 'search_box',
      cls: 'w-6/12',
    },
    {
      data_index: 'code',
      title: 'Code',
      sort_key: 'code',
      is_searchable: true,
      search_type: 'search_box',
      cls: 'w-4/12',
    },
    {
      data_index: 'created_at',
      title: 'Created At',
      sort_key: 'created_at',
      is_searchable: true,
      search_type: 'interval_date_picker',
      cls: 'w-4/12',
    },
    {
      data_index: 'updated_at',
      title: 'Updated At',
      is_searchable: true,
      search_type: 'interval_date_picker',
      cls: 'w-4/12',
    },
  ]);

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Code',
      dataIndex: 'code',
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: 'agent_operations',
      dataIndex: 'agent_operations',
    },
    {
      title: 'created_at',
      dataIndex: 'created_at',
    },
    {
      title: 'updated_at',
      dataIndex: 'updated_at',
    },
  ];

  const renderAction = (record: any) => {
    return (
      <div className="flex justify-center">
        <PencilIcon
          onClick={() => history.push(`/api/booking-agents/${record.id}`)}
          className="w-4 h-4 cursor-pointer"
        />
        <TrashIcon
          onClick={() => {
            setCurrentItem(record);
            setOpenModalAction(true);
          }}
          className="w-4 h-4 cursor-pointer ml-2"
        />
      </div>
    );
  };

  const alertChange = useCallback(() => {
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 1400);
  }, []);

  const fetchAgents = useCallback(
    async (id) => {
      setLoading(true);

      try {
        const response: AxiosResponse<BookingAgent[]> =
          await repository.api.fetchBookingAgentsByChannelManagerId(id, {
            'sort[by]': 'name',
            'sort[order]': 'asc',
          });

        setPagination({
          current: 1,
          pageSize: 10,
          total: response.data.length,
        });

        const bookingAgentsData = response.data.map((element) => {
          return {
            ...element,
            agent_operations: element.agent_operations.join(', '),
          };
        });

        const bookingAgentsDataSortedById = bookingAgentsData.sort(
          (a, b) => a.id - b.id,
        );

        setData(bookingAgentsDataSortedById);
        setOriginalData(bookingAgentsDataSortedById);

        setFilterInputs((prevState) => {
          return prevState.map((item) => {
            if (item.data_index === 'name') {
              const names = bookingAgentsData.map((bookingAgent) => ({
                name: bookingAgent.name,
                value: bookingAgent.name,
              }));
              item.items = names;
            }

            if (item.data_index === 'code') {
              const codes = bookingAgentsData
                .sort((a, b) => a.code.localeCompare(b.code))
                .map((bookingAgent) => ({
                  code: bookingAgent.code,
                  value: bookingAgent.code,
                }));
              item.items = codes;
            }

            return item;
          });
        });
        setLoading(false);
      } catch {
        showAlertError('Error while getting data', 'error', 'OK');
        setLoading(false);
      }
    },
    [showAlertError],
  );

  const fetchChannels = useCallback(async () => {
    try {
      const response: AxiosResponse<IChannelManager[]> =
        await repository.api.fetchChannelsManager({
          'sort[by]': 'name',
          'sort[order]': 'asc',
        });

      setChannelManagers(response.data);
      setLoadingChannels(false);
    } catch {
      showAlertError('Error while getting data', 'error', 'OK');
      setLoadingChannels(false);
    }
  }, [showAlertError]);

  const deleteBookingAgent = useCallback(async () => {
    setLoading(true);
    setOpenModalAction(false);

    try {
      await repository.api.deleteBookingAgent(currentItem.id);
      setLoading(false);
      await fetchAgents(channelManagerId);
      alertChange();
    } catch (error: any) {
      showAlertError(error?.response?.data?.message, 'error', 'OK');
      setLoading(false);
    }
  }, [
    alertChange,
    channelManagerId,
    currentItem.id,
    fetchAgents,
    showAlertError,
  ]);

  useEffect(() => {
    const parameters = location as { state?: ICustomState };

    const channelManagerIdPrevious = parameters.state?.channelManagerId;
    if (channelManagerIdPrevious) {
      setChannelManagerId(channelManagerIdPrevious);
    }
  }, [location]);

  useEffect(() => {
    if (channelManagerId) {
      fetchAgents(channelManagerId);
    }
  }, [channelManagerId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchChannels();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const search = useCallback(() => {
    const newData = originalData
      .filter(({ id, code, name, created_at, updated_at }) => {
        const filterInputsValues = {
          id: filterInputs[0].value,
          names: filterInputs[1].value as string[] | undefined,
          codes: filterInputs[2].value as string[] | undefined,
          createdAt: filterInputs[3].value as string[] | undefined,
          updatedAt: filterInputs[4].value as string[] | undefined,
        };

        const isFiltered = (
          value: string | number | string[] | undefined,
          elementValue: string | number,
          dateComparison = false,
        ) => {
          if (value) {
            if (
              Array.isArray(value) &&
              typeof elementValue === 'string' &&
              value.length > 0
            ) {
              if (dateComparison) {
                const startDate = moment(value[0]);
                const endDate = moment(value[1]);
                const elementDate = moment(elementValue);

                return elementDate.isBetween(startDate, endDate, 'days', '[]');
              }

              return value.includes(elementValue);
            }

            if (!Array.isArray(value)) {
              return elementValue === value;
            }
          }

          return true;
        };

        const isElementFiltered =
          isFiltered(filterInputsValues.id, id) &&
          isFiltered(filterInputsValues.names, name) &&
          isFiltered(filterInputsValues.codes, code) &&
          isFiltered(filterInputsValues.createdAt, created_at, true) &&
          isFiltered(filterInputsValues.updatedAt, updated_at, true);

        return isElementFiltered;
      })
      .sort((a, b) => a.id - b.id);

    setData(newData);

    setPagination({
      current: 1,
      pageSize: 10,
      total: newData.length,
    });
  }, [originalData, filterInputs]);

  const onChangeTable = (value: TablePaginationConfig) => {
    if (value.current && value.pageSize && value.total) {
      setPagination({
        current: value.current,
        pageSize: value.pageSize,
        total: value.total,
      });
    }
  };

  const changeColumn = (
    value: number | string | string[],
    column: IFilterColumn,
  ) => {
    setFilterInputs((prevState) => {
      return prevState.map((item) => {
        if (item.data_index !== column.data_index) {
          return item;
        }

        item.value = value;

        return item;
      });
    });
  };

  const reset = () => {
    setFilterInputs((prevState) => prevState.map(({ value, ...rest }) => rest));
  };

  return (
    <div className="table-report bg-white p-4 overflow-hidden shadow-md dark:bg-ebonyClay relative">
      <div>
        <div className="flex flex-col w-full">
          {loadingChannels ? (
            <Skeleton />
          ) : (
            <div className="mt-1 sm:mt-0  flex flex-col">
              <label className="dark:text-iron mb-2" htmlFor="channelId">
                Select a channel
              </label>
              <Select
                className="w-6/12"
                id="channelId"
                placeholder="Please select the channel manager"
                value={channelManagerId}
                onChange={(value) => setChannelManagerId(value)}>
                {channelManagers.map((item, index: number) => (
                  <Select.Option value={item.id} key={index}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
          )}
        </div>
        {channelManagerId && (
          <>
            <div className="-mx-2 mt-4">
              <Filter
                inputs={filterInputs}
                sendValue={changeColumn}
                onReset={reset}
                onSearch={search}
              />
            </div>
            <Table
              data={data}
              columns={columns}
              pagination={pagination}
              loading={loading}
              rowKey="id"
              renderTableActions={(record: any) => renderAction(record)}
              hasAddnew={true}
              history={history}
              onChange={onChangeTable}
              addNewRouter={`/api/booking-agents/new?channelManagerId=${channelManagerId}`}
            />
          </>
        )}
      </div>

      <ModalAction
        title={`You will delete the item - ${currentItem.name}`}
        message="Do you confirm the action?"
        open={openModalAction}
        setOpen={() => setOpenModalAction(!openModalAction)}
        onSave={() => deleteBookingAgent()}
        onCancel={() => setOpenModalAction(!openModalAction)}
      />

      {showAlert && (
        <Alert
          className="fixed right-0 top-0 mt-20 mr-4"
          message="Saved Changes"
          type="success"
          showIcon
        />
      )}
    </div>
  );
};

export default BookingAgents;
