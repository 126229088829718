import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import Breadcrumb from '../../components/breadcrumb';
import List from './list/index';
import Settings from './settings';

interface Props {
  match: any;
}

const HotelOnboarding: React.FC<Props> = ({ match }) => {
  return (
    <div className="flex flex-col w-full">
      <div className="flex justify-between items-center mb-2">
        <Breadcrumb />
      </div>

      <div className="relative pt-0 pb-0 overflow-hidden">
        <Switch>
          <Redirect exact from={match.url} to={`${match.url}/list`} />

          <Route path={`${match.url}/list`} component={List} />
          <Route path={`${match.url}/settings`} component={Settings} />
        </Switch>
      </div>
    </div>
  );
};

export default HotelOnboarding;
