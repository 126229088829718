import React, { useState } from 'react';
import { useUser } from '../../context/user';

interface Props {
  history: any;
}

const Login: React.FC<Props> = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const { login } = useUser();

  const onSubmit = async (event: any) => {
    event.preventDefault();

    if (loading) {
      return;
    }

    const { email, password } = event.target;

    setLoading(true);

    try {
      await login({ email: email.value, password: password.value });

      setLoading(false);
      history.push(`/dashboard`);
    } catch {
      setLoading(false);
    }
  };

  return (
    <div className="flex w-full justify-center h-screen -mt-20 items-center box-login dark:bg-mirage">
      <div
        style={{ maxWidth: '400px' }}
        className="bg-white w-full pt-8 pb-6 px-6 shadow-lg rounded-sm z-10 dark:bg-ebonyClay">
        <div className="flex flex-col w-full justify-center items-center mb-8">
          <span className="text-2xl font-bold text-indigo-500">XLR8rms 🛠</span>
          <span className="text-sm font-medium text-gray-700 dark:text-iron">
            Please sign-in to your account
          </span>
        </div>
        <form
          className="space-y-4"
          onSubmit={onSubmit}
          action="#"
          method="POST">
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700 dark:text-iron">
              Email address
            </label>
            <div className="mt-1">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                placeholder="john@xlr8rm.com"
                required
                className="appearance-none block w-full px-3 py-2 border border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:bg-transparent dark:text-white"
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700 dark:text-iron">
              Password
            </label>
            <div className="mt-1">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                placeholder="**********"
                required
                className="appearance-none block w-full px-3 py-2 border border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:bg-transparent dark:text-white"
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className={`${
                loading
                  ? 'cursor-not-allowed bg-gray-300'
                  : 'bg-indigo-500 bg-indigo-500 hover:bg-indigo-700'
              } w-full flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium text-white outline-none`}>
              Sign in
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
