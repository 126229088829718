import React, { Fragment } from 'react';
import CheckOutlined from '@ant-design/icons/lib/icons/CheckOutlined';
import CloseOutlined from '@ant-design/icons/lib/icons/CloseOutlined';
import { DatePicker, Select, Switch } from 'antd';
import {
  CheckIcon,
  PencilIcon,
  PlusIcon,
  TrashIcon,
  XIcon,
} from '@heroicons/react/outline';
import moment from 'moment';
import classNames from '../../utils/classNames';

export interface FieldRow {
  type: string;
  value: any;
  label: string;
  key: string;
  options?: any[];
  cls?: string;
  id?: string | number;
}

export interface Row {
  id?: string;
  new?: boolean;
  state?: string;
  fields: FieldRow[];
}

interface Props {
  rows: Row[] | undefined;
  currentRow: Row | undefined;
  pmsOptions?: { id: string | number; label: string | number | any[] }[];
  onChangeRow?: (row: Row) => void;
  onDeleteRow?: (row: Row) => void;
  onCancelRow?: (row: Row) => void;
  handleInput?: (row: Row, field: FieldRow, newValue: any) => void;
  onSaveRow?: (row: Row) => void;
  addPeriod?: (row: Row) => void;
  removePeriod?: (row: Row, field: FieldRow) => void;
  className?: string;
}

const CustomRows: React.FC<Props> = ({
  rows,
  currentRow,
  pmsOptions,
  className,
  onChangeRow,
  onDeleteRow,
  onCancelRow,
  onSaveRow,
  addPeriod,
  removePeriod,
  handleInput,
}) => {
  const renderAction = (row: any, index: number) => (
    <div
      className={`${
        index === 0 ? '' : ''
      } flex justify-center dark:text-white`}>
      {row.id === currentRow?.id ? (
        <CheckIcon
          onClick={() => onSaveRow && onSaveRow(row)}
          className={classNames(
            !onSaveRow && 'hidden',
            'w-4 h-4 cursor-pointer hover:text-default',
          )}
        />
      ) : (
        <PencilIcon
          onClick={() => onChangeRow && onChangeRow(row)}
          className="w-4 h-4 cursor-pointer hover:text-default"
        />
      )}
      {row.id === currentRow?.id ? (
        <XIcon
          onClick={() => {
            onCancelRow && onCancelRow(row);
          }}
          className={classNames(
            !onCancelRow && 'hidden',
            'w-4 h-4 cursor-pointer ml-2 hover:text-default',
          )}
        />
      ) : (
        <TrashIcon
          onClick={() => {
            onDeleteRow && onDeleteRow(row);
          }}
          className="w-4 h-4 cursor-pointer ml-2 hover:text-default"
        />
      )}
    </div>
  );

  if (!rows) return null;

  return (
    <div className={classNames(className, 'flex flex-col')}>
      {rows.map((item, index) => (
        <Fragment key={index}>
          <div className="flex items-center justify-between my-4">
            <>
              <div className="flex w-full flex-wrap">
                {item.fields.map((field: any, innerIndex: number) => (
                  <div
                    key={innerIndex}
                    className={`${field.cls} flex flex-col mr-5`}>
                    {index === 0 && field.label && (
                      <label
                        htmlFor={field.key}
                        className="block text-xs font-bold text-gray-700 sm:mt-px sm:pt-2 dark:text-iron mb-4">
                        {field.label}
                      </label>
                    )}

                    {currentRow?.id === item.id ? (
                      <>
                        {field.type === 'text' ? (
                          <input
                            type={field.type}
                            name={field.key}
                            value={field.value}
                            onChange={(event) =>
                              handleInput &&
                              handleInput(item, field, event.target.value)
                            }
                            className="block w-full shadow-sm sm:text-sm border-gray-300 dark:bg-transparent dark:text-white"
                          />
                        ) : field.type === 'multipleSelect' ? (
                          <Select
                            mode="multiple"
                            style={{ width: '100%' }}
                            placeholder="Please select"
                            defaultValue={field.value}
                            onChange={(event) =>
                              handleInput && handleInput(item, field, event)
                            }>
                            {pmsOptions &&
                              pmsOptions.map((option, optionIndex: number) => (
                                <Select.Option
                                  value={option.id}
                                  key={optionIndex}>
                                  {option.label || option}
                                </Select.Option>
                              ))}
                          </Select>
                        ) : field.type === 'tags' ? (
                          <Select
                            mode="tags"
                            style={{ width: '100%' }}
                            placeholder="Please select"
                            defaultValue={field.value}
                            onChange={(event) =>
                              handleInput && handleInput(item, field, event)
                            }
                            dropdownStyle={{
                              display: field.hiddenOptions ? 'none' : '',
                            }}
                          />
                        ) : field.type === 'select' ? (
                          <Select
                            style={{ width: '100%' }}
                            placeholder="Please select"
                            defaultValue={field.value}
                            onChange={(event) =>
                              handleInput && handleInput(item, field, event)
                            }>
                            {field.options.map(
                              (option: any, selectOptionIndex: number) => (
                                <Select.Option
                                  value={option.id ?? option}
                                  key={selectOptionIndex}>
                                  {option.label ?? option}
                                </Select.Option>
                              ),
                            )}
                          </Select>
                        ) : field.type === 'date' ? (
                          <div className="flex items-center w-full">
                            <DatePicker.RangePicker
                              style={{ width: '100%' }}
                              value={field.value}
                              onChange={(event) =>
                                handleInput && handleInput(item, field, event)
                              }
                            />
                            {item.fields[2].value === 2 && (
                              <>
                                <PlusIcon
                                  className="ml-3 w-4 dark:text-white cursor-pointer"
                                  onClick={() => addPeriod && addPeriod(item)}
                                />
                                <TrashIcon
                                  className="ml-1 w-4 dark:text-white cursor-pointer"
                                  onClick={() =>
                                    removePeriod && removePeriod(item, field)
                                  }
                                />
                              </>
                            )}
                          </div>
                        ) : (
                          <Switch
                            className="w-6 mt-2"
                            checked={!!field.value}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            onChange={(event) =>
                              handleInput && handleInput(item, field, event)
                            }
                          />
                        )}
                      </>
                    ) : (
                      <span>
                        {field.type === 'toggle' ? (
                          <Switch
                            className="w-6"
                            checked={!!field.value}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            disabled={true}
                          />
                        ) : field.type === 'multipleSelect' ? (
                          <div>
                            {field.value.map(
                              (id: string, multipleSelectIndex: number) => (
                                <span className="mr-1 text-xs">
                                  {(pmsOptions &&
                                    pmsOptions.filter(
                                      (item: any) => item.id === id,
                                    )[0]?.label) ??
                                    id}
                                  <>
                                    {multipleSelectIndex <
                                    field.value.length - 1
                                      ? ','
                                      : ''}
                                  </>
                                </span>
                              ),
                            )}
                          </div>
                        ) : field.type === 'select' ? (
                          <span className="mr-1 text-xs">
                            {
                              field.options.filter(
                                (item: any) => item.id === field.value,
                              )[0]?.label
                            }
                          </span>
                        ) : field.type === 'date' ? (
                          <span className="text-xs">
                            {moment(field.value[0]).format('DD/MM/YYYY')} -{' '}
                            {moment(field.value[1]).format('DD/MM/YYYY')}
                          </span>
                        ) : (
                          <span className="text-xs">{field.value}</span>
                        )}
                      </span>
                    )}
                  </div>
                ))}
              </div>
              <div className="mb-1"> {renderAction(item, index)}</div>
            </>
          </div>

          <hr className="dark:border-gray-400" />
        </Fragment>
      ))}
    </div>
  );
};

export default CustomRows;
