import ApiService from '../../../../api-service';

interface Payload {
    min_modified_at: string;
}

export default class HistoricalDataRepository {
  api = ApiService;

  getPreCalculatedHotelMetricsByHotel(hotelId: number) {
    return this.api.get(`/api/support/pre-calculated-hotel-metrics/latest-updates/${hotelId}`);
  }

  addPreCalculatedHotelMetrics(hotelId: number, payload: Payload) {
    return this.api.post(`/api/support/pre-calculated-hotel-metrics/${hotelId}`, payload);
  }
}
