import React, { useCallback, useEffect, useMemo, useState } from 'react';
import BreadcrumbCreation, {
  Step,
} from '../../../../components/breadcrumb-creation';
import { Select } from 'antd';
import Repository from '../../../../config/repository';
import { useUser } from '../../../../context/user';
import ModalAction from '../../../../components/modal-action';
import { useHistory } from 'react-router';

const repository = new Repository();

const AutopilotEmails: React.FC = () => {
  const { clientHotels, showAlert } = useUser();
  const history = useHistory();

  const [currentStep, setCurrentStep] = useState(0);
  const [hotelId, setHotelId] = useState(undefined);
  const [autopilotEmails, setAutopilotEmails] = useState<string[]>([]);
  const [openModalAction, setOpenModalAction] = useState(false);

  const hotelOptions = useMemo(
    () => clientHotels.map((value) => ({ value: value.id, label: value.name })),
    [clientHotels],
  );

  const emailsOptions = useMemo(
    () => autopilotEmails.map((email) => ({ label: email, value: email })),
    [autopilotEmails],
  );

  const hotelSelected = useMemo(
    () => clientHotels.find((hotel) => hotel.id === hotelId),
    [hotelId, clientHotels],
  );

  const steps: Step[] = useMemo(
    () => [
      {
        title: 'Hotel',
        subTitle: 'Select a Hotel',
        content: (
          <Select
            className="w-6/12"
            showSearch
            placeholder="Please select the hotel"
            filterOption={(input, option) => {
              if (option?.label && typeof option.label === 'string') {
                return (option.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }
              return true;
            }}
            value={hotelId}
            onChange={(value) => setHotelId(value)}
            options={hotelOptions}
          />
        ),
      },
      {
        title: 'Autopilot Emails',
        subTitle: 'List and Change',
        content: (
          <div className="flex flex-col w-full mb-4">
            <span className="pb-2">Email(s)</span>
            <div className="mb-4 mt-1 sm:mt-0 sm:col-span-2 flex items-center">
              <Select
                mode="tags"
                className="max-w-lg block w-full shadow-sm sm:text-sm dark:bg-transparent"
                placeholder="Insert email(s)"
                options={emailsOptions}
                value={autopilotEmails}
                onChange={(e: string[]) => {
                  setAutopilotEmails(e);
                }}
              />
            </div>
          </div>
        ),
      },
    ],
    [autopilotEmails, emailsOptions, hotelId, hotelOptions],
  );

  useEffect(() => {
    if (!hotelId) return;

    getAutopilotEmails(hotelId);
  }, [hotelId]); // eslint-disable-line react-hooks/exhaustive-deps

  const save = useCallback(
    async () => {
      try {
        if (!hotelId) return;

        const emails = { emails: autopilotEmails };
        await repository.autopilotEmailsRepository.addAutopilotEmails(
          hotelId,
          emails,
        );

        showAlert('Email(s) added to autopilot of this hotel', 'success', 'OK');
        history.push('/');
      } catch {
        showAlert('Error while saving', 'error', 'OK');
      }
    },
    [autopilotEmails, hotelId], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const getAutopilotEmails = async (hotelId: number) => {
    try {
      const response =
        await repository.autopilotEmailsRepository.getAutopilotEmails(hotelId);

      if (!response.data.length) return;

      setAutopilotEmails(response.data);
    } catch {
      showAlert('Error fetching configuration', 'error', 'OK');
    }
  };

  return (
    <div className="flex bg-white p-4 overflow-hidden shadow-md dark:bg-ebonyClay relative">
      <BreadcrumbCreation
        currentStep={currentStep}
        steps={steps}
        onCancel={() => setCurrentStep((prevState) => prevState - 1)}
        onResolve={() => {
          if (currentStep === 0 && hotelId === undefined) {
            showAlert('Please select a hotel', 'error', 'OK');

            return;
          }

          setCurrentStep((prevState) => {
            if (prevState < steps.length - 1) {
              return prevState + 1;
            }

            return prevState;
          });

          if (currentStep === steps.length - 1) {
            setOpenModalAction(!openModalAction);
          }
        }}
      />

      <ModalAction
        title={
          <div className="flex flex-col text-sm">
            <span>{hotelSelected?.name}</span>
            <span>
              You will update the emails with access to autopilot in this hotel.
            </span>
          </div>
        }
        message="Do you want to proceed?"
        open={openModalAction}
        setOpen={() => setOpenModalAction(!openModalAction)}
        onSave={() => {
          setOpenModalAction(!openModalAction);
          save();
        }}
        onCancel={() => setOpenModalAction(!openModalAction)}
        buttonSaveName="Save"
        type="success"
      />
    </div>
  );
};

export default AutopilotEmails;
