import React, { useEffect, useMemo } from 'react';
import CustomRows from '../../../../components/custom-rows';
import { useTables } from '../../../../context/tables';

interface Props {
  hotelId: number;
  pmsOptions: { id: string | number; label: string }[];
}

const Confirmed: React.FC<Props> = ({ hotelId, pmsOptions }) => {
  const { setTables, tables } = useTables();

  const data = useMemo(
    () =>
      tables.bookingStatusSets.data
        .map((item) => {
          if (item.type === 1 && item.hotel_id === hotelId) {
            return {
              ...item,
              fields: [
                {
                  type: 'multipleSelect',
                  value: item.pms_booking_statuses,
                  options: [],
                  hiddenOptions: true,
                  key: 'pms_booking_statuses',
                  cls: 'w-8/12',
                },
              ],
            };
          }

          return null;
        })
        .filter((item) => item),
    [hotelId, tables.bookingStatusSets.data],
  );

  const currentItem = useMemo(() => data[0] ?? null, [data]);

  useEffect(() => {
    if (data.length > 0) return;

    setTables((prevState) => ({
      ...prevState,
      bookingStatusSets: {
        data: [
          ...prevState.bookingStatusSets.data,
          {
            type: 1,
            id: null,
            hotel_id: hotelId,
            fields: [
              {
                type: 'multipleSelect',
                value: [],
                options: [],
                hiddenOptions: true,
                key: 'pms_booking_statuses',
                cls: 'w-8/12',
              },
            ],
          },
        ],
      },
    }));
  }, [data.length]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <div className="flex justify-between">
        <p className="font-bold dark:text-white">Definitive</p>
      </div>
      <CustomRows
        className="-mt-2 mb-4"
        rows={data}
        currentRow={currentItem}
        pmsOptions={pmsOptions}
        handleInput={(row, field, newValue) => {
          setTables((prevState) => ({
            ...prevState,
            bookingStatusSets: {
              data: prevState.bookingStatusSets?.data?.map((item) =>
                item.type === 1 && item.hotel_id === hotelId
                  ? { ...item, pms_booking_statuses: newValue }
                  : item,
              ),
            },
          }));
        }}
      />
    </div>
  );
};

export default Confirmed;
