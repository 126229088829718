import React, { useState } from 'react';
import { OfficeBuildingIcon } from '@heroicons/react/outline';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons/lib/icons';
import { Checkbox, Switch } from 'antd';

// TODO: create component for input field

const General: React.FC = () => {
  const [form, setForm] = useState({
    min: '',
    max: '',
    vat: '',
    name: '',
    pms: '',
    status: 0,
    token: '',
    subscriptionId: '',
  });

  return (
    <div className="flex w-full">
      <div className="flex flex-col items-start w-full">
        <div className="flex w-full items-center mb-4">
          <span className="h-12 w-12 flex items-center justify-center rounded-full overflow-hidden bg-gray-100 mr-4 -mt-2">
            <OfficeBuildingIcon className="w-7 h-7 text-gray-600" />
          </span>
          <div className="flex flex-col items-start">
            <div>
              <button
                type="button"
                className="inline-flex items-center px-2 py-1 border border-indigo-600 mr-2 text-xs leading-4 font-medium shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none">
                Upload
              </button>
              <button
                type="button"
                className="inline-flex items-center px-2 py-1 border border-gray-300 shadow-sm text-xs leading-4 font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none">
                Reset
              </button>
            </div>
            <span className="text-xs text-gray-500 pt-1">
              Allowed JPG, GIF or PNG. Max size of 800kB
            </span>
          </div>
        </div>

        <div className="flex w-full items-start">
          <div className="flex flex-col w-3/12 mr-5">
            <label
              htmlFor="name"
              className="block text-xs font-medium mb-1 text-gray-700 sm:mt-px sm:pt-2 dark:text-iron">
              Name
            </label>
            <input
              type="text"
              name="name"
              value={form.name}
              onChange={(event) =>
                setForm({ ...form, name: event?.target.value })
              }
              className="block w-full shadow-sm sm:text-sm border-gray-300 dark:bg-transparent dark:text-white"
            />
          </div>
          <div className="flex flex-col w-3/12">
            <label
              htmlFor="vat"
              className="block text-xs font-medium mb-1 text-gray-700 sm:mt-px sm:pt-2 dark:text-iron">
              VAT Number
            </label>
            <input
              type="number"
              name="vat"
              value={form.vat}
              onChange={(event) =>
                setForm({ ...form, vat: event?.target.value })
              }
              className="block w-full shadow-sm sm:text-sm border-gray-300 dark:bg-transparent dark:text-white"
            />
          </div>
        </div>

        <hr className="flex mt-6 justify-start w-6/12" />

        <p className="text-left w-full block text-xs font-medium text-gray-700 mt-4 mb-2 dark:text-iron">
          Rate Configuration
        </p>

        <div className="flex w-full items-center">
          <div className="flex flex-col w-3/12 mr-5">
            <label
              htmlFor="min"
              className="block text-xs font-medium mb-1 text-gray-700 sm:mt-px sm:pt-2 dark:text-iron">
              Min
            </label>
            <input
              type="number"
              name="min"
              value={form.min}
              onChange={(event) =>
                setForm({ ...form, min: event?.target.value })
              }
              className="block w-full shadow-sm mr-4 sm:text-sm border-gray-300 dark:bg-transparent dark:text-white"
            />
          </div>
          <div className="flex flex-col w-3/12">
            <label
              htmlFor="max"
              className="block text-xs font-medium mb-1 text-gray-700 sm:mt-px sm:pt-2 dark:text-iron">
              Max
            </label>
            <input
              type="number"
              name="max"
              value={form.max}
              onChange={(event) =>
                setForm({ ...form, max: event?.target.value })
              }
              className="block w-full shadow-sm sm:text-sm border-gray-300 dark:bg-transparent dark:text-white"
            />
          </div>
        </div>

        <hr className="flex mt-6 justify-start w-6/12" />

        <p className="text-left w-full block text-xs font-medium text-gray-700 mt-4 mb-2 dark:text-iron">
          Type of PMS
        </p>

        <div className="flex w-full items-center">
          <div className="flex flex-col w-3/12">
            <label
              htmlFor="pms"
              className="block text-xs font-medium mb-1 text-gray-700 sm:mt-px sm:pt-2 dark:text-iron">
              Type
            </label>
            <select
              onChange={(event) =>
                setForm({ ...form, pms: event.target.value })
              }
              className="block w-full shadow-sm mr-4 sm:text-sm border-gray-300 dark:bg-transparent dark:text-white">
              <option value="host">Host</option>
              <option value="sihot">Sihot</option>
            </select>
          </div>
        </div>

        <hr className="flex mt-6 justify-start w-6/12" />

        <p className="text-left w-full block text-xs font-medium text-gray-700 mt-4 mb-2 dark:text-iron">
          OTA insight config
        </p>

        <div className="flex w-full items-center">
          <div className="flex flex-col w-3/12 mr-5">
            <label
              htmlFor="subscriptionId"
              className="block text-xs font-medium mb-1 text-gray-700 sm:mt-px sm:pt-2 dark:text-iron">
              Subscription id
            </label>
            <input
              type="number"
              name="subscriptionId"
              value={form.subscriptionId}
              onChange={(event) =>
                setForm({ ...form, subscriptionId: event?.target.value })
              }
              className="block w-full shadow-sm mr-4 sm:text-sm border-gray-300 dark:bg-transparent dark:text-white"
            />
          </div>
          <div className="flex flex-col w-3/12">
            <label
              htmlFor="token"
              className="block text-xs font-medium mb-1 text-gray-700 sm:mt-px sm:pt-2 dark:text-iron">
              Token
            </label>
            <input
              type="text"
              name="token"
              value={form.token}
              onChange={(event) =>
                setForm({ ...form, token: event?.target.value })
              }
              className="block w-full shadow-sm mr-4 sm:text-sm border-gray-300 dark:bg-transparent dark:text-white"
            />
          </div>
        </div>

        <div className="flex w-full items-center">
          <div className="flex flex-col w-3/12 mr-5">
            <label
              htmlFor="status"
              className="block text-xs font-medium mb-1 text-gray-700 sm:mt-px sm:pt-2 dark:text-iron">
              Status
            </label>
            <Switch
              className="w-6"
              checked={!!form.status}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              onChange={() => setForm({ ...form, status: form.status ? 0 : 1 })}
            />
          </div>
          <div className="flex flex-col w-3/12">
            <label
              htmlFor="los"
              className="block text-xs font-medium mb-1 text-gray-700 sm:mt-px sm:pt-2 dark:text-iron">
              Los
            </label>
            <Checkbox className="w-5"></Checkbox>
          </div>
        </div>

        <div className="flex w-full mt-6">
          <button
            onClick={() => {
              setForm({
                min: '',
                max: '',
                vat: '',
                name: '',
                pms: '',
                status: 0,
                token: '',
                subscriptionId: '',
              });
            }}
            type="button"
            className="inline-flex items-center px-3 py-2 border border-indigo-600 mr-4 text-xs leading-4 font-medium shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none">
            Save Changes
          </button>
          <button
            onClick={() => null}
            type="button"
            className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-xs leading-4 font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default General;
