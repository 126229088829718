import { PencilIcon, TrashIcon } from '@heroicons/react/outline';
import { Alert } from 'antd';
import { AxiosResponse } from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Filter from '../../../../components/filter';
import ModalAction from '../../../../components/modal-action';
import Table from '../../../../components/table';
import Repository from '../../../../config/repository';
import { useUser } from '../../../../context/user';
import { frequencyOptions, typeOptions } from './form';

export interface IReportSchedule {
  type: string | number;
  daily: boolean;
  weekly: boolean;
  monthly: boolean;
  hotel_id: number;
  description: string;
  active?: boolean;
}

const repository = new Repository();

const ReportsScheduleList: React.FC = () => {
  const history = useHistory();

  const { showAlert: showAlertError, clientHotels } = useUser();

  const [reportsSchedule, setReportsSchedule] = useState<IReportSchedule[]>([]);
  const [openModalAction, setOpenModalAction] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isActiveFilter, setIsActiveFilter] = useState(false);
  const [currentItem, setCurrentItem] = useState({ id: '', description: '' });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 10,
  });
  const [filterColumns, setFilterColumns] = useState([
    {
      cls: 'w-4/12',
      dataIndex: 'name',
      filter_key: 'id',
      is_searchable: true,
      items: clientHotels,
      search_type: 'searchSelect',
      placeholder: 'Please select the hotel',
      title: 'Hotel',
      value: '',
    },
  ]);

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
    },
    {
      title: 'Hotel',
      dataIndex: 'hotel_id',
      render: (record: any) => (
        <span>
          {clientHotels.find((item) => item.id === record)?.name || record}
        </span>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: (record: number) => (
        <span>{typeOptions.find((item) => item.id === record)?.name}</span>
      ),
    },

    {
      title: 'Frequency',
      render: (record: any) => {
        const frequencies = frequencyOptions
          .filter((frequency) => record[frequency] === 1)
          .join(', ');

        return <span className="capitalize">{frequencies}</span>;
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: 'Status',
      dataIndex: 'active',
      render: (record: any) => {
        return <span>{record === 1 ? 'Active' : 'Inactive'}</span>;
      },
    },
  ];

  useEffect(() => {
    getReportsSchedule();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setFilterColumns([
      {
        ...filterColumns[0],
        items: clientHotels,
      },
    ]);
  }, [clientHotels]); // eslint-disable-line react-hooks/exhaustive-deps

  const getReportsSchedule = useCallback(
    async (page = pagination.current, limit = pagination.pageSize) => {
      try {
        const response: AxiosResponse =
          await repository.apiReportsSchedule.getReportsSchedule(page, limit, {
            'sort[by]': 'hotel_id',
            'sort[order]': 'asc',
          });
        setReportsSchedule(response.data.data);
        setPagination({
          ...response.data.meta.pagination,
          current: response.data.meta.pagination.current_page,
          pageSize: response.data.meta.pagination.per_page,
          total: response.data.meta.pagination.total || 10,
        });
      } catch {
        showAlertError('Error while getting data', 'error', 'OK');
      } finally {
        setLoading(false);
      }
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const deleteReportSchedule = useCallback(async () => {
    setLoading(true);
    setOpenModalAction(false);

    try {
      await repository.apiReportsSchedule.deleteReportSchedule(currentItem.id);
      setLoading(false);
      await getReportsSchedule();
      alertChange();
    } catch (error: any) {
      showAlertError(error?.response?.data?.message, 'error', 'OK');
      setLoading(false);
    }
  }, [currentItem.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const getReportsScheduleByHotel = useCallback(
    async (hotelId, page = pagination.current, limit = pagination.pageSize) => {
      try {
        const response: AxiosResponse =
          await repository.apiReportsSchedule.getReportsScheduleByHotel(
            hotelId,
            page,
            limit,
          );
        setReportsSchedule(response.data.data);
        setPagination({
          ...response.data.meta.pagination,
          current: response.data.meta.pagination.current_page,
          pageSize: response.data.meta.pagination.per_page,
          total: response.data.meta.pagination.total || 10,
        });
      } catch {
        showAlertError('Error while getting data', 'error', 'OK');
      } finally {
        setLoading(false);
      }
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const changeColumn = useCallback((value: any, column: any) => {
    setFilterColumns((previousState) => {
      const copyState = [...previousState];
      copyState[0].value = value;
      return copyState;
    });
  }, []);

  const onChangeTable = useCallback(
    (pagination: any) => {
      setPagination(pagination);
      setLoading(true);
      const hotelId = filterColumns[0].value;

      isActiveFilter
        ? getReportsScheduleByHotel(
            pagination.current,
            pagination.pageSize,
            hotelId,
          )
        : getReportsSchedule(pagination.current, pagination.pageSize);
    },
    [pagination], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const renderAction = (record: any) => {
    return (
      <div className="flex justify-center">
        <PencilIcon
          onClick={() => history.push(`/api/reports-schedule/${record.id}`)}
          className="w-4 h-4 cursor-pointer"
        />
        <TrashIcon
          onClick={() => {
            setCurrentItem(record);
            setOpenModalAction(true);
          }}
          className="w-4 h-4 cursor-pointer ml-2"
        />
      </div>
    );
  };

  const alertChange = () => {
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 1400);
  };

  const reset = () => {
    setFilterColumns((previousState) => {
      const copyState = [...previousState];
      copyState[0].value = '';
      return copyState;
    });
    setIsActiveFilter(false);

    setLoading(true);
    getReportsSchedule();
  };

  const search = async () => {
    if (filterColumns[0].value) setIsActiveFilter(true);
    else return;

    const hotelId = filterColumns[0].value;
    await getReportsScheduleByHotel(hotelId);
  };

  return (
    <div className="table-report bg-white p-4 overflow-hidden shadow-md dark:bg-ebonyClay relative">
      <div className="-mx-2">
        <Filter
          inputs={filterColumns}
          sendValue={changeColumn}
          onReset={reset}
          onSearch={search}
        />
      </div>

      <Table
        data={reportsSchedule}
        columns={columns}
        pagination={pagination}
        loading={loading}
        rowKey="id"
        renderTableActions={(record: any) => renderAction(record)}
        hasAddnew={true}
        history={history}
        onChange={onChangeTable}
        addNewRouter={`/api/reports-schedule/new`}
      />

      <ModalAction
        title={`You will delete the item - ${currentItem.description}`}
        message="Do you confirm the action?"
        open={openModalAction}
        setOpen={() => setOpenModalAction(!openModalAction)}
        onSave={() => deleteReportSchedule()}
        onCancel={() => setOpenModalAction(!openModalAction)}
      />

      {showAlert && (
        <Alert
          className="fixed right-0 top-0 mt-20 mr-4"
          message="Saved Changes"
          type="success"
          showIcon
        />
      )}
    </div>
  );
};

export default ReportsScheduleList;
