import ApiService from '../../api-service';

export default class ApiRepository {
  api = ApiService;

  getMarketSegmentsSchema() {
    return this.api.get(`api/support/market-segments/schema`);
  }

  getMarketSegments(page = 1, limit = 10, queryParams = {}) {
    return this.api.get(
      `api/support/market-segments?page=${page}&limit=${limit}`,
      { params: queryParams },
    );
  }

  getMarketSegment(id: string | number) {
    return this.api.get(`api/support/market-segments/${id}`);
  }

  postMarketSegments(data = {}) {
    return this.api.post(`api/support/market-segments`, data);
  }

  putMarketSegments(id: string | number | null, data = {}) {
    return this.api.put(`api/support/market-segments/${id}`, data);
  }

  deleteMarketSegments(id: string | number) {
    return this.api.delete(`api/support/market-segments/${id}`);
  }

  getLogReportsSchema() {
    return this.api.get(`api/support/log-reports/schema`);
  }

  getLogReports(page = 1, limit = 10, queryParams = {}) {
    return this.api.get(`api/support/log-reports?page=${page}&limit=${limit}`, {
      params: queryParams,
    });
  }

  getLogFilesSchema() {
    return this.api.get(`api/support/log-files/schema`);
  }

  getLogFiles(page = 1, limit = 10, queryParams = {}) {
    return this.api.get(`api/support/log-files?page=${page}&limit=${limit}`, {
      params: queryParams,
    });
  }

  fetchBookingAgentsByHotelId(id: string | number, queryParams = {}) {
    return this.api.get(`api/support/hotels/${id}/booking-agents/list`, {
      params: queryParams,
    });
  }

  updateBookingAgent(id: any, data = {}) {
    return this.api.put(`api/support/hotels/${id}/booking-agents/bulk`, data);
  }

  fetchConfigurationWizard(id: string | number) {
    return this.api.get(`api/v3/hotels/${id}/cm/connection`);
  }

  fetchChannelsManager(queryParams = {}) {
    return this.api.get(`api/support/channel-manager`, {
      params: queryParams,
    });
  }

  fetchChannelManagerById(id: string | number) {
    return this.api.get(`api/support/channel-manager/${id}`);
  }

  createChannelManager(data = {}) {
    return this.api.post(`api/support/channel-manager`, data);
  }

  updateChannelManager(id: string | number, data = {}) {
    return this.api.put(`api/support/channel-manager/${id}`, data);
  }

  deleteChannelManager(id: string | number) {
    return this.api.delete(`api/support/channel-manager/${id}`);
  }

  testConfigurationWizard(id: string | number, data = {}) {
    return this.api.post(`api/v3/hotels/${id}/cm/connection/test`, data);
  }

  connectConfigurationWizard(id: string | number, data = {}) {
    return this.api.post(`api/v3/hotels/${id}/cm/connection`, data);
  }

  updateHotelInfo(id: string | number, data = {}) {
    return this.api.patch(`api/support/hotels/${id}`, data);
  }

  fetchBookingStatus() {
    return this.api.get(`api/support/status`);
  }

  createBookingAgent(data = {}) {
    return this.api.post(`api/support/booking-agents`, data);
  }

  updateOneBookingAgent(id: string | number, data = {}) {
    return this.api.put(`api/support/booking-agents/${id}`, data);
  }

  deleteBookingAgent(id: string | number) {
    return this.api.delete(`api/support/booking-agents/${id}`);
  }

  fetchBookingAgentsByChannelManagerId(id: string | number, queryParams = {}) {
    return this.api.get(`api/support/booking-agents/channel-manager/${id}`, {
      params: queryParams,
    });
  }

  getBookingAgentById(id: string | number) {
    return this.api.get(`api/support/booking-agents/${id}`);
  }

  fetchBookingStatusById(id: string) {
    return this.api.get(`api/support/status/${id}`);
  }

  fetchBookingStatusCodes(hotelId: number | string) {
    return this.api.get(`api/support/status/codes?hotel_id=${hotelId}`);
  }

  createBookingStatus(data = {}) {
    return this.api.post(`api/support/status`, data);
  }

  updateBookingStatus(id: any, data = {}) {
    return this.api.put(`api/support/status/${id}`, data);
  }

  fetchRateShopperLinks(queryParams = {}) {
    return this.api.get(`api/support/rate-shopper/link`, {
      params: queryParams,
    });
  }

  fetchRateShopperLinkById(id: string | number) {
    return this.api.get(`api/support/rate-shopper/link/${id}`);
  }

  createRateShopperLink(data = {}) {
    return this.api.post(`api/support/rate-shopper/link`, data);
  }

  updateRateShopperLink(id: string | number, data = {}) {
    return this.api.put(`api/support/rate-shopper/link/${id}`, data);
  }

  patchRateShopperLink(id: string | number, data = {}) {
    return this.api.patch(`api/support/rate-shopper/link/${id}`, data);
  }

  deleteRateShopperLink(id: string | number) {
    return this.api.delete(`api/support/rate-shopper/link/${id}`);
  }

  unblockedHotels(id: string | number) {
    return this.api.post(`api/support/bulk-schedule/${id}/unblock-processes`);
  }
}
