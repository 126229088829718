import React from 'react';
import { TablesProvider } from './tables';
import { UserProvider } from './user';

const AppProvider: React.FC = ({ children }) => (
  <UserProvider>
    <TablesProvider>{children}</TablesProvider>
  </UserProvider>
);

export default AppProvider;
