import React from 'react';
import { Redirect, Route, Switch } from 'react-router';

import Form from './form';
import List from './list';

interface Props {
  match: any;
}

const MarketSegments: React.FC<Props> = ({ match }) => {
  return (
    <div className="w-full h-full">
      <Switch>
        <Redirect exact from={match.url} to={`${match.url}/list`} />

        <Route path={`${match.url}/list`} component={List} />
        <Route path={`${match.url}/new`} component={Form} />
        <Route path={`${match.url}/:id`} component={Form} />
      </Switch>
    </div>
  );
};

export default MarketSegments;
