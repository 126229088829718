import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { useHistory } from 'react-router-dom';
import Repository from '../../config/repository';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

interface ContextValue {
  user: User;
  token: string;
  isDark: boolean;
  setIsDark: (value: boolean) => void;
  login: ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => Promise<any>;
  logout: () => Promise<any>;
  refresh: number;
  setRefresh: React.Dispatch<React.SetStateAction<number>>;
  hotels: Hotel[];
  showAlert: (message: string, type: any, buttonName: string) => void;
  clientHotels: Hotel[];
  updateHotelRateType: (hotelId: number, rateType: 0 | 1) => void;
  updateHotelInfo: (hotelId: number, hotelInfo: Hotel) => void;
}

interface Props {
  children: React.ReactNode;
}

export const UserContext = React.createContext<ContextValue | undefined>(
  undefined,
);

export const UserProvider: React.FC<Props> = (props) => {
  const repository = new Repository();
  const [user, setUser] = useState({} as User);
  const [isDark, setDark] = useState(!!localStorage.getItem('isDark'));
  const [token, setToken] = useState('');
  const [refresh, setRefresh] = useState(0);
  const [hotels, setHotels] = useState<Hotel[]>([]);
  const history = useHistory();
  const MySwal = withReactContent(Swal);

  const clientHotels = useMemo(
    () => hotels.filter((hotel) => hotel.is_client),
    [hotels],
  );

  useEffect(() => {
    const accessToken = localStorage.getItem('token');

    if (accessToken) {
      setToken(accessToken);
      fetchUser();
      fetchHotels();

      return;
    }

    if (window.location.pathname !== '/login') {
      history.push('/login');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showAlert = useCallback(
    (message: string, type: any, buttonName: string) => {
      MySwal.fire({
        background: '#283045',
        color: 'mirage',
        position: 'center',
        title: (
          <span
            className="text-sm block"
            style={{
              whiteSpace: 'pre-wrap',
            }}>
            {message}
          </span>
        ),
        icon: type,
        confirmButtonText: buttonName,
        timer: 3000,
      });
    },
    [MySwal],
  );

  const setIsDark = useCallback((value: boolean): void => {
    setDark(value);

    if (value) {
      document.getElementsByTagName('body')[0].classList.add('dark');
      localStorage.setItem('isDark', 'true');

      return;
    }

    document.getElementsByTagName('body')[0].classList.remove('dark');
    localStorage.removeItem('isDark');
  }, []);

  const fetchUser = useCallback(async () => {
    try {
      const response = await repository.global.fetchUser();

      setUser(response.data);
    } catch {}
  }, [repository.global]);

  const fetchHotels = useCallback(async () => {
    try {
      const response = await repository.global.fetchHotels();
      const hotelsSorted = response.data.sort((a: Hotel, b: Hotel) =>
        a.name.localeCompare(b.name),
      );
      setHotels(hotelsSorted);
    } catch {}
  }, [repository.global]);

  const login = useCallback(
    async (data = { email: '', password: '' }) => {
      try {
        const response = await repository.global.postLogin(data);

        setToken(response.data.access_token);
        localStorage.setItem('token', response.data.access_token);

        history.push('/dashboard');

        fetchHotels();
        return response;
      } catch {
        throw Error('Login failed');
      }
    },
    [fetchHotels, history, repository.global],
  );

  const logout = useCallback(async () => {
    try {
      await repository.global.postLogout();

      setToken('');
      localStorage.removeItem('token');
      history.push('/login');
    } catch {}
  }, [history, repository.global]);

  const updateHotelRateType = useCallback(
    (hotelId: number, rateType: 0 | 1) => {
      setHotels((previous) => {
        const hotelIndex = previous.findIndex((hotel) => hotel.id === hotelId);
        const copyHotels = [...previous];
        copyHotels[hotelIndex].rates_type = rateType;
        return copyHotels;
      });
    },
    [],
  );

  const updateHotelInfo = useCallback((hotelId: number, hotelInfo: Hotel) => {
    setHotels((prevState) => {
      const hotelIndex = prevState.findIndex((hotel) => hotel.id === hotelId);
      const copyHotels = [...prevState];
      copyHotels[hotelIndex] = hotelInfo;
      return copyHotels;
    });
  }, []);

  const value = useMemo(
    () => ({
      user,
      token,
      isDark,
      refresh,
      hotels,
      clientHotels,
      setIsDark,
      login,
      logout,
      setRefresh,
      showAlert,
      updateHotelRateType,
      updateHotelInfo,
    }),
    [
      user,
      token,
      isDark,
      refresh,
      hotels,
      clientHotels,
      setIsDark,
      login,
      logout,
      setRefresh,
      showAlert,
      updateHotelRateType,
      updateHotelInfo,
    ],
  );

  return <UserContext.Provider value={value} {...props} />;
};

export const useUser = (): ContextValue => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error('useUser must be used within an UserProvider');
  }

  return context;
};

//
// Utils
//

export interface Hotel {
  id: number;
  unique_identifier: string;
  name: string;
  nif: number;
  status: number;
  is_client: boolean;
  last_import_time: string;
  rates_type: 0 | 1;
  pms: string;
  trivago_name: string;
  price_recommendation: boolean;
  provider: string;
  trivago_subscription: boolean;
  is_additional_guests: boolean;
  autopilot_emails: string[];
  created_at: string;
  updated_at: string;
}

export interface User {
  id: number;
  name: string;
  email: string;
  primary_hotel_id: number;
}

export interface MenuITems {
  name: string;
  router: string;
}
