import React, { useCallback, useMemo, useState } from 'react';
import BreadcrumbCreation, {
  Step,
} from '../../../../components/breadcrumb-creation';
import { DatePicker, Select, Skeleton } from 'antd';
import Repository from '../../../../config/repository';
import { useUser } from '../../../../context/user';
import ModalAction from '../../../../components/modal-action';
import { useHistory } from 'react-router';
import { Moment } from 'moment';
import moment from 'moment';

const HistoricalData: React.FC = () => {
  const repository = new Repository();
  const { clientHotels, showAlert } = useUser();
  const history = useHistory();

  const [currentStep, setCurrentStep] = useState(0);
  const [hotelId, setHotelId] = useState(undefined);
  const [modifiedAt, setModifiedAt] = useState<Moment | null>(null);
  const [openModalAction, setOpenModalAction] = useState(false);
  const [loading, setLoading] = useState(true);

  const hotelOptions = useMemo(
    () => clientHotels.map((value) => ({ value: value.id, label: value.name })),
    [clientHotels],
  );

  const hotelSelected = useMemo(
    () => clientHotels.find((hotel) => hotel.id === hotelId),
    [hotelId, clientHotels],
  );

  const steps: Step[] = useMemo(
    () => [
      {
        title: 'Hotel',
        subTitle: 'Select a Hotel',
        content: (
          <Select
            className="w-6/12"
            showSearch
            placeholder="Please select the hotel"
            filterOption={(input, option) => {
              if (option?.label && typeof option.label === 'string') {
                return (option.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }
              return true;
            }}
            value={hotelId}
            onChange={(value) => setHotelId(value)}
            options={hotelOptions}
          />
        ),
      },
      {
        title: 'Modified At',
        subTitle: 'Add or edit a modified at',
        content: (
          <>
            {loading ? (
              <Skeleton />
            ) : (
              <div className="flex flex-col w-full">
                <span className="pb-2">Modified At</span>
                <div className="flex items-center pb-4">
                  <DatePicker
                    className="w-6/12 text-sm"
                    placeholder="Select a date"
                    value={modifiedAt}
                    onChange={(date) => {
                      setModifiedAt(date);
                    }}
                  />
                </div>
              </div>
            )}
          </>
        ),
      },
    ],
    [hotelId, hotelOptions, loading, modifiedAt],
  );

  const save = useCallback(
    async () => {
      try {
        if (!hotelId || !modifiedAt) return;

        const payload = {
          min_modified_at: modifiedAt.format('YYYY-MM-DD'),
        };

        await repository.historicalDataRepository.addPreCalculatedHotelMetrics(
          hotelId,
          payload,
        );

        showAlert(
          `Pre Calculated Hotel Metrics saved for this hotel`,
          'success',
          'OK',
        );
        history.push('/');
      } catch (error: any) {
        showAlert(
          error.response ? error.response.data.message : 'Error saving changes',
          'error',
          'OK',
        );
        setCurrentStep(0);
      }
    },
    [hotelId, modifiedAt, history], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const getPreCalculatedHotelMetricsByHotel = async (hotelId: number) => {
    try {
      setModifiedAt(null);
      setLoading(true);

      const response =
        await repository.historicalDataRepository.getPreCalculatedHotelMetricsByHotel(
          hotelId,
        );

      setModifiedAt(response.data.latest_calculated_at ? moment(response.data.latest_calculated_at) : null);
    } catch {}
    setLoading(false);
  };

  return (
    <div className="flex bg-white p-4 overflow-hidden shadow-md dark:bg-ebonyClay relative">
      <BreadcrumbCreation
        currentStep={currentStep}
        steps={steps}
        onCancel={() => setCurrentStep((prevState) => prevState - 1)}
        onResolve={() => {
          if (currentStep === 0 && hotelId === undefined) {
            showAlert('Please select a hotel', 'error', 'OK');

            return;
          }

          setCurrentStep((prevState) => {
            if (prevState < steps.length - 1) {
              hotelId && getPreCalculatedHotelMetricsByHotel(hotelId);

              return prevState + 1;
            }

            return prevState;
          });

          if (currentStep === steps.length - 1) {
            setOpenModalAction(!openModalAction);
          }
        }}
      />

      <ModalAction
        title={
          <div className="flex flex-col text-sm">
            <span>{hotelSelected?.name}</span>
            <span>
              You will save the pre calculated hotel metrics to this hotel.
            </span>
          </div>
        }
        message="Do you want to proceed?"
        open={openModalAction}
        setOpen={() => setOpenModalAction(!openModalAction)}
        onSave={() => {
          setOpenModalAction(!openModalAction);
          save();
        }}
        onCancel={() => setOpenModalAction(!openModalAction)}
        buttonSaveName="Save"
        type="success"
      />
    </div>
  );
};

export default HistoricalData;
