import ApiService from '../../../../api-service';

interface Payload {
  room_rate_id: number;
  strategic_alignment: number;
}

interface Params {
  active_rooms: number;
  active_rates: number;
}

export default class BaseRoomRateRepository {
  api = ApiService;

  addBaseRoomRate(hotelId: number, payload: Payload) {
    return this.api.post(
      `/api/support/price-recommendation/settings/${hotelId}`,
      payload,
    );
  }

  editBaseRoomRate(hotelId: number, payload: Payload) {
    return this.api.put(
      `/api/support/price-recommendation/settings/${hotelId}`,
      payload,
    );
  }

  removeBaseRoomRate(hotelId: number) {
    return this.api.delete(
      `/api/support/price-recommendation/settings/${hotelId}`,
    );
  }

  fetchRoomRateAssociations(hotelId: number, params: Params) {
    return this.api.get(
      `/api/v3/hotels/${hotelId}/rates/list/room-associations`,
      { params },
    );
  }

  getSettings(hotelId: number) {
    return this.api.get(
      `/api/v3/hotels/${hotelId}/price-recommendation/settings`,
    );
  }
}
