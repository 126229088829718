import { PencilIcon, TrashIcon } from '@heroicons/react/outline';
import { Alert } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Repository from '../../../../config/repository';

import ModalAction from '../../../../components/modal-action';
import Table from '../../../../components/table';
import { IChannelManagerForm } from './form';
import { useUser } from '../../../../context/user';

interface Props {
  history: any;
}

interface IChannelManager extends IChannelManagerForm {
  id: number;
}

interface IColumn {
  title: string;
  dataIndex: string;
}

const List: React.FC<Props> = ({ history }) => {
  const { showAlert: showAlertError } = useUser();

  const repository = new Repository();

  const [data, setData] = useState<any[]>([]);
  const columns = useMemo<IColumn[]>(
    () => [
      {
        title: 'Name',
        dataIndex: 'name',
      },
      {
        title: 'Description',
        dataIndex: 'description',
      },
    ],
    [],
  );
  const [showAlert, setShowAlert] = useState(false);
  const [currentItem, setCurrentItem] = useState({ id: '', name: '' });
  const [openModalAction, setOpenModalAction] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 10,
  });
  const currentQueryParams = {
    'sort[by]': 'name',
    'sort[order]': 'desc',
  };

  const getChannelManagers = useCallback(() => {
    repository.api
      .fetchChannelsManager(currentQueryParams)
      .then((res) => {
        setData(
          res.data.sort((a: IChannelManager, b: IChannelManager) =>
            a.name.localeCompare(b.name),
          ),
        );
        setPagination((old) => {
          return {
            ...old,
            total: res.data.length,
          };
        });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        showAlertError('Error while getting data', 'error', 'OK');
      });
  }, [repository.api, showAlertError]) // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeTable = useCallback((value: any) => {
    setPagination(value);
  }, []);

  const renderAction = (record: any) => {
    return (
      <div className="flex justify-center">
        <PencilIcon
          onClick={() => history.push(`/api/channel-manager/${record.id}`)}
          className="w-4 h-4 cursor-pointer"
        />
        <TrashIcon
          onClick={() => {
            setCurrentItem(record);
            setOpenModalAction(true);
          }}
          className="w-4 h-4 cursor-pointer ml-2"
        />
      </div>
    );
  };

  const deleteChannelManager = useCallback(() => {
    setOpenModalAction(false);

    repository.api
      .deleteChannelManager(currentItem.id)
      .then(() => {
        alertChange();
        setLoading(true);
        getChannelManagers();
      })
      .catch((error) => {
        showAlertError(error?.response?.data?.message, 'error', 'OK');
      });
  }, [currentItem.id, getChannelManagers, repository.api, showAlertError]);

  const alertChange = () => {
    setShowAlert(true);

    setTimeout(() => {
      setShowAlert(false);
    }, 1400);
  };

  useEffect(() => {
    getChannelManagers();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="relative bg-white p-4 pb-0 overflow-hidden shadow-md dark:bg-ebonyClay">
      <Table
        data={data}
        columns={columns}
        pagination={pagination}
        loading={loading}
        rowKey="id"
        onChange={onChangeTable}
        renderTableActions={(record: any) => renderAction(record)}
        hasAddnew={true}
        history={history}
        addNewRouter={'/api/channel-manager/new'}
      />

      <ModalAction
        title={`You will delete the item - ${currentItem.name}`}
        message="Do you confirm the action?"
        open={openModalAction}
        setOpen={() => setOpenModalAction(!openModalAction)}
        onSave={() => deleteChannelManager()}
        onCancel={() => setOpenModalAction(!openModalAction)}
      />
      {showAlert && (
        <Alert
          className="fixed right-0 top-0 mt-20 mr-4"
          message="Saved Changes"
          type="success"
          showIcon
        />
      )}
    </div>
  );
};

export default List;
