import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { Input, Select, Switch } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import BreadcrumbCreation, {
  Step,
} from '../../../../components/breadcrumb-creation';
import Repository from '../../../../config/repository';
import { Hotel, useUser } from '../../../../context/user';
import ModalAction from '../../../../components/modal-action';

interface Props {}

const repository = new Repository();

interface ITrivagoForm {
  trivagoName?: string;
  trivagoSubscription?: boolean;
}

const Trivago: React.FC<Props> = () => {
  const { clientHotels, showAlert, updateHotelInfo } = useUser();
  const [currentStep, setCurrentStep] = useState(0);
  const [hotelId, setHotelId] = useState(undefined);

  const [loading, setLoading] = useState(false);
  const [openModalAction, setOpenModalAction] = useState(false);

  const [form, setForm] = useState<ITrivagoForm>();
  const [formErrors, setFormErrors] = useState({
    trivagoName: false,
  });

  const history = useHistory();

  const hotelSelected = useMemo(
    () => clientHotels.find((hotel) => hotel.id === hotelId),
    [hotelId, clientHotels],
  );

  useEffect(() => {
    if (hotelId) {
      setForm({
        trivagoName: hotelSelected?.trivago_name,
        trivagoSubscription: hotelSelected?.trivago_subscription,
      });
    }
  }, [hotelId, setForm, hotelSelected]);

  const saveChanges = useCallback(async () => {
    if (!hotelId) return;

    setLoading(true);

    try {
      const data = {
        trivago_name: form?.trivagoName,
        trivago_subscription: form?.trivagoSubscription,
      };
      const response = await repository.api.updateHotelInfo(hotelId, data);
      updateHotelInfo(hotelId, response.data as Hotel);

      setLoading(false);
      showAlert('Trivago Status changed successfully', 'success', 'OK');
      history.push('/');
    } catch {
      setLoading(false);
      showAlert('Error on changing trivago status', 'error', 'OK');
    }
  }, [history, hotelId, showAlert, form, updateHotelInfo]);

  const steps: Step[] = useMemo(
    () => [
      {
        title: 'Hotel',
        subTitle: 'Select a Hotel',
        content: (
          <Select
            className="w-6/12"
            showSearch
            placeholder="Please select the hotel"
            filterOption={(input, option) => {
              if (option?.label && typeof option.label === 'string') {
                return (option.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }
              return true;
            }}
            value={hotelId}
            onChange={(value) => setHotelId(value)}
            options={clientHotels.map((value) => ({
              value: value.id,
              label: value.name,
            }))}
          />
        ),
      },
      {
        title: 'Trivago',
        subTitle: 'Update Trivago',
        content: (
          <div className="flex w-full mb-4 gap-4 flex-col sm:flex-row">
            <div className="flex flex-col w-full sm:w-1/2">
              <label
                htmlFor="name"
                className="text-sm font-medium text-gray-700 pb-1 dark:text-iron">
                Trivago Name
              </label>
              <Input
                id="name"
                value={form?.trivagoName}
                style={{
                  border: `${formErrors.trivagoName ? '1px solid red ' : ''}`,
                }}
                onChange={(event) => {
                  setForm({ ...form, trivagoName: event?.target.value });
                  setFormErrors({
                    ...formErrors,
                    trivagoName: event.target.value.trim() === '',
                  });
                }}
                className="w-full shadow-sm sm:text-sm dark:bg-transparent dark:text-white"
              />
            </div>

            <div className="flex flex-col">
              <label
                htmlFor="status"
                className="block text-sm font-medium text-gray-700 pb-1 dark:text-iron">
                Status
              </label>
              <Switch
                checked={form?.trivagoSubscription}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={() =>
                  setForm({
                    ...form,
                    trivagoSubscription: !form?.trivagoSubscription,
                  })
                }
              />
            </div>
          </div>
        ),
      },
    ],
    [hotelId, clientHotels, form, formErrors],
  );

  return (
    <div className="flex bg-white p-4 overflow-hidden shadow-md dark:bg-ebonyClay relative">
      <BreadcrumbCreation
        currentStep={currentStep}
        steps={steps}
        loading={loading}
        onCancel={() => setCurrentStep((prevState) => prevState - 1)}
        onResolve={() => {
          if (currentStep === 0 && hotelId === undefined) {
            showAlert('Please select a hotel', 'error', 'OK');

            return;
          }

          setCurrentStep((prevState) => {
            if (prevState < steps.length - 1) {
              return prevState + 1;
            }

            return prevState;
          });

          if (currentStep === steps.length - 1 && !formErrors.trivagoName) {
            setOpenModalAction(!openModalAction);
          }
        }}
      />

      <ModalAction
        title={
          <div className="flex flex-col text-sm">
            <span>{hotelSelected?.name}</span>
            <span>You will update the trivago.</span>
          </div>
        }
        message="Do you want to proceed?"
        open={openModalAction}
        setOpen={() => setOpenModalAction(!openModalAction)}
        onSave={() => {
          setOpenModalAction(!openModalAction);
          saveChanges();
        }}
        onCancel={() => setOpenModalAction(!openModalAction)}
        buttonSaveName="Save"
        type="success"
      />
    </div>
  );
};

export default Trivago;
