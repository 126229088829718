import ApiService from '../../../../api-service';

interface Payload {
    active: boolean;
}

export default class ActivateDeactivateMPRepository {
  api = ApiService;

  getPreCalculatedHotelMetrics(page = 1, limit = 10) {
    return this.api.get(`/api/support/pre-calculated-hotel-metrics/latest-updates?page=${page}&limit=${limit}`);
  }

  addPreCalculatedHotelMetrics(hotelId: number, payload: Payload) {
    return this.api.patch(`/api/support/pre-calculated-hotel-metrics/latest-updates/${hotelId}`, payload);
  }
}
