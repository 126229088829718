/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TrashIcon } from '@heroicons/react/outline';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { Alert, Switch } from 'antd';
import Repository from '../../../../config/repository';
import Table from '../../../../components/table';
import ModalAction from '../../../../components/modal-action';
import { useUser } from '../../../../context/user';
import { useTables } from '../../../../context/tables';

interface Props {
  history: any;
}

const List: React.FC<Props> = ({ history }) => {
  const { refresh, hotels } = useUser();
  const { tables, currentTable, setTables, setCurrentTable } = useTables();
  const repository = new Repository();
  const [data, setData] = useState<any[]>([]);
  const [showAlert, setShowAlert] = useState(false);
  const [currentItem, setCurrentItem] = useState({ id: '', name: '' });
  const [openModalAction, setOpenModalAction] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 10,
  });
  const [currentQueryParams, setCurrentQueryParams] = useState({});

  const columns = useMemo<any[]>(
    () => [
      {
        title: 'Hotel',
        sort_key: 'hotel_id',
        dataIndex: 'hotel_id',
        data_index: 'hotel_id',
        filter_key: 'hotel_id',
        is_searchable: true,
        render: (record: any) => (
          <span>
            {hotels.find((item) => item.id === record)?.name || record}
          </span>
        ),
      },
      {
        title: 'Linked Hotel',
        sort_key: 'linked_hotel_id',
        dataIndex: 'linked_hotel_id',
        data_index: 'linked_hotel_id',
        filter_key: 'linked_hotel_id',
        is_searchable: true,
        render: (record: any) => (
          <span>
            {hotels.find((item) => item.id === record)?.name || record}
          </span>
        ),
      },
      {
        title: 'Created At',
        sort_key: 'created_at',
        dataIndex: 'created_at',
        data_index: 'created_at',
        filter_key: 'created_at',
        is_searchable: true,
      },
      {
        title: 'Updated At',
        sort_key: 'updated_at',
        dataIndex: 'updated_at',
        data_index: 'updated_at',
        filter_key: 'updated_at',
        is_searchable: true,
      },
      {
        title: 'Status',
        sort_key: 'is_active',
        dataIndex: 'is_active',
        data_index: 'is_active',
        filter_key: 'is_active',
        is_searchable: true,
        render: (record: any, row: any, index: number) => (
          <Switch
            checked={record}
            checkedChildren={
              <span className="flex items-center">
                Active <CheckOutlined className="ml-2" />
              </span>
            }
            unCheckedChildren={
              <span className="flex items-center">
                Inactive <CloseOutlined className="ml-2" />
              </span>
            }
            onChange={() =>
              patchRateShopperLink(row.id, record ? false : true, index)
            }
          />
        ),
      },
    ],
    [hotels],
  );

  const fetchRateShopperLinks = useCallback(
    (queryParams = {}) => {
      setCurrentQueryParams(queryParams);

      repository.api.fetchRateShopperLinks(queryParams).then(
        (res) => {
          setData(res.data);
          setLoading(false);
        },
        () => {
          setLoading(false);
        },
      );
    },
    [data, pagination],
  );

  const patchRateShopperLink = (id: number, value: boolean, index: number) => {
    if (!id) return;

    setData((prevState) => {
      prevState[index].is_active = value;

      return prevState;
    });

    repository.api
      .patchRateShopperLink(id, {
        is_active: value ? true : false,
      })
      .then(() => {
        alertChange();
      });
  };

  const onchangeTable = useCallback(
    (value: any, filter: any, sort: any) => {
      let by = null;
      let order = null;

      if (sort.column) {
        by = sort.column.sort_key;
        order = sort.order === 'ascend' ? 'asc' : 'desc';
      }

      setPagination(value);
      setLoading(true);
      fetchRateShopperLinks({
        ...currentQueryParams,
        'sort[by]': by,
        'sort[order]': order,
      });
    },
    [pagination],
  );

  const renderAction = (record: any) => {
    return (
      <div className="flex justify-center">
        <TrashIcon
          onClick={() => {
            setCurrentItem(record);
            setOpenModalAction(true);
          }}
          className="w-4 h-4 cursor-pointer ml-2"
        />
      </div>
    );
  };

  const deleteMarketSegment = () => {
    setOpenModalAction(false);

    repository.api.deleteRateShopperLink(currentItem.id).then(() => {
      alertChange();
      setLoading(true);
      fetchRateShopperLinks(currentQueryParams);
    });
  };

  const alertChange = () => {
    setShowAlert(true);

    setTimeout(() => {
      setShowAlert(false);
    }, 1400);
  };

  useEffect(() => {
    setPagination(tables.rateShopper.pagination);
    setData(tables.rateShopper.data);
    setLoading(false);

    if (tables.rateShopper.data.length === 0) {
      setLoading(true);
      fetchRateShopperLinks(currentQueryParams);
    }
  }, [refresh]);

  useEffect(() => {
    setTables({
      ...tables,
      rateShopper: {
        columns,
        data,
        pagination,
      },
    });
  }, [columns, data, pagination]);

  useEffect(() => {
    if (currentTable !== 'rateShopper') return;

    setLoading(true);
    fetchRateShopperLinks(currentQueryParams);
    setCurrentTable('');
  }, [currentTable]);

  return (
    <div className="relative bg-white p-4 pb-0 overflow-hidden shadow-md dark:bg-ebonyClay">
      <Table
        data={data}
        columns={columns}
        pagination={pagination}
        loading={loading}
        rowKey="id"
        onChange={onchangeTable}
        renderTableActions={(record: any) => renderAction(record)}
        hasAddnew={true}
        history={history}
        addNewRouter="/api/rate-shopper-link"
      />

      <ModalAction
        title={`You will delete the item`}
        message="Do you confirm the action?"
        open={openModalAction}
        setOpen={() => setOpenModalAction(!openModalAction)}
        onSave={() => deleteMarketSegment()}
        onCancel={() => setOpenModalAction(!openModalAction)}
      />

      {showAlert && (
        <Alert
          className="fixed right-0 top-0 mt-20 mr-4"
          message="Saved Changes"
          type="success"
          showIcon
        />
      )}
    </div>
  );
};

export default List;
