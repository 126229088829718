import ApiService from '../../api-service';

export default class GlobalRepository {
  api = ApiService;

  postLogin(data = {}) {
    return this.api.post('/api/v3/login', data);
  }

  fetchUser() {
    return this.api.get('/v1/user');
  }

  postLogout() {
    return this.api.post('/api/v3/logout');
  }

  fetchHotels() {
    return this.api.get('/api/support/hotels');
  }

  fetchHotel(hotelId: string) {
    return this.api.get(`/api/support/hotels/${hotelId}`);
  }
}
