import { Select } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import BreadcrumbCreation, {
  Step,
} from '../../../components/breadcrumb-creation';
import FileUpload from '../../../components/FileUpload';
import ModalAction from '../../../components/modal-action';
import Repository from '../../../config/repository';
import { useUser } from '../../../context/user';

const Outlier: React.FC = () => {
  const repository = new Repository();
  const history = useHistory();
  const { clientHotels, showAlert } = useUser();
  const [currentStep, setCurrentStep] = useState(0);
  const [form, setForm] = useState<{
    hotel: number | undefined;
    file: File | null;
  }>({
    hotel: undefined,
    file: null,
  });
  const [openModalAction, setOpenModalAction] = useState(false);
  const [loading, setLoading] = useState(false);

  const hotelSelected = useMemo(
    () => clientHotels.find((hotel) => hotel.id === form.hotel),
    [form.hotel, clientHotels],
  );

  const steps: Step[] = [
    {
      title: 'Hotel',
      subTitle: 'Select a Hotel',
      content: (
        <Select
          className="w-6/12"
          showSearch
          placeholder="Please select the hotel"
          filterOption={(input, option) => {
            if (option?.label && typeof option.label === 'string') {
              return (option.label ?? '')
                .toLowerCase()
                .includes(input.toLowerCase());
            }
            return true;
          }}
          value={form.hotel}
          onChange={(value) =>
            setForm((prevState) => ({
              ...prevState,
              hotel: value,
            }))
          }
          options={clientHotels.map((value) => {
            return {
              value: value.id,
              label: value.name,
            };
          })}
        />
      ),
    },
    {
      title: 'File',
      subTitle: 'Upload CSV file',
      content: (
        <FileUpload
          onChange={(files) => {
            setForm((prevState) => ({
              ...prevState,
              file: files[0],
            }));
          }}
          limit={1}
          limitSize={10}
          extensions={['csv']}
        />
      ),
    },
    {
      title: 'Overhaul',
      subTitle: 'Review all the details',
      content: (
        <div className="flex flex-col">
          <span className="flex items-center">
            <b className="mr-1">Hotel:</b>
            {hotelSelected?.name}
          </span>
          <span className="flex items-center">
            <b className="mr-1">File:</b>
            {form.file?.name}
          </span>
        </div>
      ),
    },
    {
      title: 'Dispatch',
      subTitle: 'Dispatch dataset',
      content: <span>Save data</span>,
    },
  ];

  const saveData = useCallback(async () => {
    if (!form.hotel || !form.file) return;

    setLoading(true);

    try {
      const formData = new FormData();
      formData.set('csv', form.file);

      await repository.ai.createAiOutlier(form.hotel, formData);

      showAlert('Outlier will be created', 'success', 'OK');
      setLoading(false);
      history.push('/');
    } catch {
      setLoading(false);
      showAlert('Error while saving', 'error', 'OK');
    }
  }, [form.file, form.hotel, history, repository.ai, showAlert]);

  return (
    <div className="flex bg-white p-4 overflow-hidden shadow-md dark:bg-ebonyClay relative">
      <BreadcrumbCreation
        currentStep={currentStep}
        steps={steps}
        loading={loading}
        onCancel={() => setCurrentStep((prevState) => prevState - 1)}
        onResolve={() => {
          if (currentStep === 0 && form.hotel === undefined) {
            showAlert('Please select a hotel', 'error', 'OK');

            return;
          }

          if (currentStep === 1 && !form.file) {
            showAlert('Please select a file', 'error', 'OK');

            return;
          }

          setCurrentStep((prevState) => {
            if (prevState < steps.length - 1) {
              return prevState + 1;
            }

            return prevState;
          });

          if (currentStep === steps.length - 1) {
            setOpenModalAction(!openModalAction);
          }
        }}
      />
      <ModalAction
        title={
          <div className="flex flex-col text-sm">
            <span>{hotelSelected?.name}</span>
            <span>You will upload the outlier data.</span>
          </div>
        }
        message="Do you want to proceed?"
        open={openModalAction}
        setOpen={() => setOpenModalAction(!openModalAction)}
        onSave={() => {
          setOpenModalAction(!openModalAction);
          saveData();
        }}
        onCancel={() => setOpenModalAction(!openModalAction)}
        buttonSaveName="Save"
        type="success"
      />
    </div>
  );
};

export default Outlier;
