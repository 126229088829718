import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { positions, Provider as AlertProvider, transitions } from 'react-alert';

import AppProvider from './context';

import GlobalStyles from './styles/global';
import 'antd/dist/antd.css';
import './styles/tailwind.css';
import './styles/index.css';
import Default from './layout/default';
import Alert from './components/alert';

const alertOptions = {
  position: positions.TOP_RIGHT,
  transition: transitions.FADE,
  timeout: 4000,
};

const App: React.FC = () => {
  return (
    <Router>
      <AlertProvider template={Alert} {...alertOptions}>
        <AppProvider>
          <Default />
        </AppProvider>
      </AlertProvider>
      <GlobalStyles />
    </Router>
  );
};

export default App;
