import React, { useCallback, useEffect, useState } from 'react';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { Switch, Alert, Skeleton, Select, Input } from 'antd';

import Repository from '../../../../config/repository';
import { Hotel, useUser } from '../../../../context/user';

interface Props {
  history: any;
  match: any;
}

interface IFormReportSchedule {
  id?: string | number;
  hotel?: Hotel;
  description: string;
  hotel_id?: number;
  daily: boolean;
  weekly: boolean;
  monthly: boolean;
  type?: number;
  active: boolean;
}

interface IOption {
  name: string;
  id: number;
}

type FrequencyOptions = 'daily' | 'weekly' | 'monthly';

export const typeOptions = [
  {
    id: 1,
    name: 'Market segment default',
  },
  {
    id: 2,
    name: 'Last week accounts',
  },
  {
    id: 3,
    name: 'Market segment custom 1',
  },
  {
    id: 4,
    name: 'Market segment custom 1 2',
  },
  {
    id: 6,
    name: 'Nationalities',
  },
  {
    id: 7,
    name: 'Market sub segment default',
  },
  {
    id: 8,
    name: 'Market sub segment custom 1',
  },
  {
    id: 9,
    name: 'Market sub segment custom 1 2',
  },
  {
    id: 10,
    name: 'Yield',
  },
];

export const frequencyOptions: FrequencyOptions[] = [
  'daily',
  'weekly',
  'monthly',
];

const Form: React.FC<Props> = ({ history, match }) => {
  const repository = new Repository();
  const { TextArea } = Input;
  const { clientHotels, showAlert: showErrorAlert } = useUser();

  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(true);
  const [form, setForm] = useState<IFormReportSchedule>({
    id: undefined,
    hotel: undefined,
    description: '',
    hotel_id: undefined,
    daily: false,
    weekly: false,
    monthly: false,
    type: undefined,
    active: true,
  });

  const [formErrors, setFormErrors] = useState({
    type: false,
    frequency: false,
    hotel: false,
    description: false,
  });

  const [actualFrequency, setActualFrequency] = useState<string[]>([]);

  useEffect(() => {
    const hotel = clientHotels.find((hotel) => hotel.id === form.hotel_id);
    setForm((previousState) => ({ ...previousState, hotel }));
  }, [clientHotels, form.hotel_id]);

  useEffect(() => {
    if (match.params.id) {
      getReportScheduleById(match.params.id);
      return;
    }

    setLoading(false);
  }, [match.params.id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setActualFrequency(() => {
      const newFrequencies = [];
      if (form.daily) {
        newFrequencies.push('daily');
      }
      if (form.weekly) {
        newFrequencies.push('weekly');
      }
      if (form.monthly) {
        newFrequencies.push('monthly');
      }

      return newFrequencies;
    });
  }, [form.daily, form.weekly, form.monthly]); // eslint-disable-line react-hooks/exhaustive-deps

  const getReportScheduleById = useCallback(async (id: string | number) => {
    try {
      const res = await repository.apiReportsSchedule.getReportScheduleById(id);
      const { data } = res;

      setForm(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const editReportSchedule = async (
    data: IFormReportSchedule,
    id: string | number,
  ) => {
    try {
      await repository.apiReportsSchedule.updateReportSchedule(id, data);

      alertChange();
    } catch (error: any) {
      showErrorAlert(error?.response?.data?.message, 'error', 'OK');
    }
  };

  const saveReportSchedule = async (data: IFormReportSchedule) => {
    try {
      await repository.apiReportsSchedule.createReportSchedule(data);
      alertChange();
    } catch (error: any) {
      showErrorAlert(error?.response?.data?.message, 'error', 'OK');
    }
  };

  const save = useCallback(() => {
    if (!form.type || actualFrequency.length === 0 || !form.hotel_id) {
      setFormErrors({
        type: !form.type,
        frequency: actualFrequency.length === 0,
        description: form.description.trim() === '',
        hotel: !form.hotel_id,
      });
      return;
    }

    const dataRequest = {
      active: form.active,
      daily: form.daily,
      description: form.description,
      hotel_id: form.hotel_id,
      monthly: form.monthly,
      type: form.type,
      weekly: form.weekly,
    };

    if (form.id) {
      editReportSchedule(dataRequest, form.id);
      return;
    }

    saveReportSchedule(dataRequest);
  }, [form]); // eslint-disable-line react-hooks/exhaustive-deps

  const alertChange = () => {
    setShowAlert(true);

    setTimeout(() => {
      goBack();
      setShowAlert(false);
    }, 600);
  };

  const goBack = () => {
    history.goBack();
  };

  return (
    <div className="bg-white p-4 overflow-hidden shadow-md dark:bg-ebonyClay">
      <div>
        <div className="space-y-6">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-iron">
              {form.id ? form.id : 'New Report Schedule'}
            </h3>
          </div>
          {loading && <Skeleton />}
          {!loading && (
            <div className="space-y-6 sm:space-y-5">
              <div className="flex flex-col">
                <label
                  htmlFor="hotel_id"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 dark:text-iron">
                  Hotel
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Select
                    className="w-6/12"
                    showSearch
                    style={{
                      border: `${formErrors.hotel ? '1px solid red' : ''}`,
                    }}
                    placeholder="Please select the hotel"
                    filterOption={(input, option) => {
                      if (option?.label && typeof option.label === 'string') {
                        return (option.label ?? '')
                          .toLowerCase()
                          .includes(input.toLowerCase());
                      }
                      return true;
                    }}
                    value={form?.hotel_id}
                    onChange={(value) => {
                      const hotel = clientHotels.find(
                        (hotel) => hotel.id === value,
                      );

                      setForm({
                        ...form,
                        hotel,
                        hotel_id: value,
                      });
                      setFormErrors({
                        ...formErrors,
                        hotel: !value,
                      });
                    }}
                    options={clientHotels.map((value) => ({
                      value: value.id,
                      label: value.name,
                    }))}
                  />
                </div>
              </div>

              <div className="flex flex-col">
                <label
                  htmlFor="type"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 dark:text-iron">
                  Type
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Select
                    className="max-w-lg block w-full shadow-sm sm:text-sm  dark:bg-transparent
                    dark:text-white"
                    value={form?.type}
                    style={{
                      border: `${formErrors.type ? '1px solid red' : ''}`,
                    }}
                    id="type"
                    placeholder="Select a type"
                    dropdownMatchSelectWidth={false}
                    onChange={(e: number) => {
                      setForm({ ...form, type: e });

                      setFormErrors({
                        ...formErrors,
                        type: !e,
                      });
                    }}>
                    {typeOptions.map((option: IOption, i: number) => (
                      <Select.Option key={i} value={option.id}>
                        {option.name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>

              <div className="flex flex-col">
                <label
                  htmlFor="hotel_id"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 dark:text-iron">
                  Frequency
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Select
                    mode="multiple"
                    className={`max-w-lg block w-full shadow-sm sm:text-sm dark:bg-transparent ${
                      actualFrequency.length > 0 ? 'capitalize' : ''
                    }`}
                    placeholder="Select a frequency"
                    style={{
                      border: `${formErrors.frequency ? '1px solid red ' : ''}`,
                    }}
                    value={actualFrequency}
                    onChange={(e: string[]) => {
                      setForm((previousState) => {
                        const copyState = { ...previousState };

                        frequencyOptions.forEach((frequency) => {
                          if (e.includes(frequency))
                            copyState[frequency] = true;
                          else copyState[frequency] = false;
                        });

                        return copyState;
                      });

                      setFormErrors({
                        ...formErrors,
                        frequency: !e,
                      });
                    }}>
                    {frequencyOptions.map((option: string) => (
                      <Select.Option
                        value={option}
                        key={option}
                        className="capitalize">
                        {option}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>

              <div className="flex flex-col">
                <label
                  htmlFor="description"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 dark:text-iron">
                  Description
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <TextArea
                    id="description"
                    value={form.description}
                    style={{
                      border: `${
                        formErrors.description ? '1px solid red ' : ''
                      }`,
                    }}
                    onChange={(event) => {
                      setForm({ ...form, description: event?.target.value });
                      setFormErrors({
                        ...formErrors,
                        description: event.target.value.trim() === '',
                      });
                    }}
                    className="block max-w-lg w-full shadow-sm sm:text-sm dark:bg-transparent dark:text-white"
                  />
                </div>
              </div>

              <div className="flex flex-col">
                <label
                  htmlFor="status"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 dark:text-iron">
                  Status
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Switch
                    checked={!!form.active}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    onChange={() => setForm({ ...form, active: !form.active })}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <button
            onClick={() => goBack()}
            type="button"
            className="bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50">
            Cancel
          </button>
          <button
            onClick={() => save()}
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700">
            Save
          </button>
        </div>
      </div>
      {showAlert && (
        <Alert
          className="fixed right-0 top-0 mt-20 mr-4"
          message="Saved Changes"
          type="success"
          showIcon
        />
      )}
    </div>
  );
};

export default Form;
