import React, { useCallback, useEffect, useMemo, useState } from 'react';
import BreadcrumbCreation, {
  Step,
} from '../../../../components/breadcrumb-creation';
import { Select, Tooltip } from 'antd';
import Repository from '../../../../config/repository';
import { useUser } from '../../../../context/user';
import ModalAction from '../../../../components/modal-action';
import { useHistory } from 'react-router';
import { AxiosResponse } from 'axios';
import { InfoCircleFilled } from '@ant-design/icons';
import { CustomError } from '../maintenance-mode';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';

interface Props {}

const repository = new Repository();

const ConfigurationWizard: React.FC<Props> = () => {
  const { clientHotels, showAlert } = useUser();
  const [currentStep, setCurrentStep] = useState(0);
  const [hotelId, setHotelId] = useState(undefined);
  const [credentials, setCredentials] = useState({
    hotel_code: '',
    username: '',
    password: '',
    channel_manager_id: '',
  });
  const [openModalAction, setOpenModalAction] = useState(false);
  const [loading, setLoading] = useState(false);
  const [channelsManager, setChannelsManager] = useState<ChannelManager[]>([]);
  const [showPassword, setShowPassword] = useState(false);

  const history = useHistory();

  const hotelSelected = useMemo(
    () => clientHotels.find((hotel) => hotel.id === hotelId),
    [hotelId, clientHotels],
  );

  useEffect(() => {
    if (!hotelId) return;

    fetchConfiguration(hotelId);
    fetchChannelsManager();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotelId]);

  const fetchConfiguration = useCallback(
    async (id) => {
      try {
        const response = await repository.api.fetchConfigurationWizard(id);

        setCredentials(response.data.data);
      } catch {
        showAlert('Error fetching configuration', 'error', 'OK');
      }
    },
    [showAlert],
  );

  const testConnection = useCallback(async () => {
    if (!hotelId) return;

    setLoading(true);

    try {
      await repository.api.testConfigurationWizard(hotelId, credentials);
      setLoading(false);
      showAlert('Connection tested with success', 'success', 'OK');
    } catch (error) {
      setLoading(false);
      showAlert(
        (error as CustomError)?.response?.data?.message ??
          'Error while Testing',
        'error',
        'OK',
      );
    }
  }, [credentials, hotelId, showAlert]);

  const connect = useCallback(async () => {
    if (!hotelId) return;

    setLoading(true);

    try {
      await repository.api.connectConfigurationWizard(hotelId, credentials);
      setLoading(false);
      showAlert(
        'Channel Manager credentials stored successfully',
        'success',
        'OK',
      );
      history.push('/');
    } catch {
      setLoading(false);
      showAlert('Error on storing Channel Manager credentials', 'error', 'OK');
    }
  }, [credentials, history, hotelId, showAlert]);

  const fetchChannelsManager = useCallback(async () => {
    try {
      const response: AxiosResponse<ChannelManager[]> =
        await repository.api.fetchChannelsManager({
          'sort[by]': 'name',
          'sort[order]': 'desc',
        });

      setChannelsManager(response.data);
    } catch {}
  }, []);

  const toggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  const steps: Step[] = useMemo(
    () => [
      {
        title: 'Hotel',
        subTitle: 'Select a Hotel',
        content: (
          <Select
            className="w-6/12"
            showSearch
            placeholder="Please select the hotel"
            filterOption={(input, option) => {
              if (option?.label && typeof option.label === 'string') {
                return (option.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }
              return true;
            }}
            value={hotelId}
            onChange={(value) => setHotelId(value)}
            options={clientHotels.map((value) => {
              return {
                value: value.id,
                label: value.name,
              };
            })}
          />
        ),
      },
      {
        title: 'CM Configuration',
        subTitle: 'Test and Connect',
        hiddenNext: true,
        content: (
          <div className="flex flex-col w-full mb-4">
            <div className="mb-4 mt-1 sm:mt-0 sm:col-span-2 flex items-center">
              <Select
                className="w-5/12"
                placeholder="Channel Manager"
                value={credentials.channel_manager_id}
                onChange={(value) => {
                  setCredentials((prevState) => ({
                    ...prevState,
                    channel_manager_id: value,
                  }));
                }}>
                {channelsManager.map((item, index: number) => (
                  <Select.Option value={item.id} key={index}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </div>

            <div className="mb-4 mt-1 sm:mt-0 sm:col-span-2 flex flex-col">
              <div className="flex mb-2">
                <Tooltip
                  className="text-xs lg:text-sm"
                  title={
                    'Username should be provided. For siteminder is Xlr8rmsSMprod, CM HOST is RMSCODE. Others are provided by partners'
                  }>
                  <InfoCircleFilled style={{ color: '#092F57' }} />
                </Tooltip>
              </div>
              <input
                className="w-5/12 bg-transparent border pl-2 py-2 dark:text-iron text-sm"
                placeholder="Username"
                value={credentials.username}
                type="text"
                onChange={(e) =>
                  setCredentials((prevState) => ({
                    ...prevState,
                    username: e.target.value,
                  }))
                }
              />
            </div>

            <div className="flex flex-col mb-4 mt-1 sm:mt-0 sm:col-span-2">
              <div className="flex mb-2">
                <Tooltip
                  className="text-xs lg:text-sm"
                  title={
                    'Password is provided. Siteminder is the same, CM HOST is ocp key and others are provided by partners'
                  }>
                  <InfoCircleFilled style={{ color: '#092F57' }} />
                </Tooltip>
              </div>

              <div className="relative w-5/12">
                <input
                  className="w-full bg-transparent border pl-2 py-2 dark:text-iron text-sm"
                  placeholder="Password"
                  type={showPassword ? 'text' : 'password'}
                  value={credentials.password}
                  onChange={(e) =>
                    setCredentials((prevState) => ({
                      ...prevState,
                      password: e.target.value,
                    }))
                  }
                />
                <span
                  className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                  onClick={toggleShowPassword}>
                  {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                </span>
              </div>
            </div>

            <div className="mt-1 sm:mt-0 sm:col-span-2 flex flex-col">
              <div className="flex mb-2">
                <Tooltip
                  className="text-xs lg:text-sm"
                  title={
                    'Hotel Code is mandatory to be UNIQUE. Provided by all partners and Created by XLR8 to CM HOST'
                  }>
                  <InfoCircleFilled style={{ color: '#092F57' }} />
                </Tooltip>
              </div>
              <input
                className="w-5/12 bg-transparent border pl-2 py-2 dark:text-iron text-sm"
                placeholder="hotel code"
                value={credentials.hotel_code}
                type="text"
                onChange={(e) =>
                  setCredentials((prevState) => ({
                    ...prevState,
                    hotel_code: e.target.value,
                  }))
                }
              />
            </div>

            <div className="flex justify-between mt-4 mr-auto">
              <button
                onClick={() => testConnection()}
                className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-xs leading-4 font-medium text-gray-700 bg-white hover:opacity-70">
                Test Connection
              </button>
              <button
                onClick={() => connect()}
                className="inline-flex items-center px-3 py-2 ml-3 border border-indigo-600 text-xs leading-4 font-medium shadow-sm text-white bg-indigo-600 hover:bg-indigo-700">
                Store/change CM Credentials
              </button>
            </div>
          </div>
        ),
      },
    ],
    [
      channelsManager,
      connect,
      credentials.channel_manager_id,
      credentials.hotel_code,
      credentials.password,
      credentials.username,
      showPassword,
      hotelId,
      clientHotels,
      testConnection,
    ],
  );

  return (
    <div className="flex bg-white p-4 overflow-hidden shadow-md dark:bg-ebonyClay relative">
      <BreadcrumbCreation
        currentStep={currentStep}
        steps={steps}
        loading={loading}
        onCancel={() => setCurrentStep((prevState) => prevState - 1)}
        onResolve={() => {
          if (currentStep === 0 && hotelId === undefined) {
            showAlert('Please select a hotel', 'error', 'OK');

            return;
          }

          setCurrentStep((prevState) => {
            if (prevState < steps.length - 1) {
              return prevState + 1;
            }

            return prevState;
          });

          if (currentStep === steps.length - 1) {
            setOpenModalAction(!openModalAction);
          }
        }}
      />

      <ModalAction
        title={
          <div className="flex flex-col text-sm">
            <span>{hotelSelected?.name}</span>
            <span>You will update the booking status ai sets.</span>
          </div>
        }
        message="Do you want to proceed?"
        open={openModalAction}
        setOpen={() => setOpenModalAction(!openModalAction)}
        onSave={() => {
          setOpenModalAction(!openModalAction);
        }}
        onCancel={() => setOpenModalAction(!openModalAction)}
        buttonSaveName="Save"
        type="success"
      />
    </div>
  );
};

export default ConfigurationWizard;

//
// Utils
//

interface ChannelManager {
  id: number;
  name: string;
  description: string;
}
