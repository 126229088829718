import React, { useState } from 'react';
import { useHistory } from 'react-router';
import ModalAction from '../../../../components/modal-action';
import Repository from '../../../../config/repository';
import { useUser } from '../../../../context/user';
import { Skeleton } from 'antd';

const ClearCache: React.FC = () => {
  const repository = new Repository();

  const { showAlert } = useUser();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [openModalAction, setOpenModalAction] = useState(false);

  const clearCacheRequest = async () => {
    setLoading(true);

    try {
      await repository.clearCacheRepository.clearAllCache();
      showAlert('Cleared all cache with success', 'success', 'OK');
      history.push('/');
    } catch (error) {
      showAlert('Error to clear cache', 'error', 'OK');
    }

    setLoading(false);
  };

  return (
    <div className="flex bg-white p-4 overflow-hidden shadow-md dark:bg-ebonyClay relative">
      {loading ? (
        <Skeleton />
      ) : (
        <button
          onClick={() => setOpenModalAction(true)}
          type="button"
          className="inline-flex items-center px-3 py-2 ml-2 border border-indigo-600 text-xs leading-4 font-medium shadow-sm text-white bg-indigo-600 hover:bg-indigo-700">
          Clear All Cache
        </button>
      )}

      <ModalAction
        title={
          <div className="flex flex-col text-sm">
            <span>This action will clear all cache</span>
          </div>
        }
        message="Do you want to proceed?"
        open={openModalAction}
        setOpen={() => setOpenModalAction(!openModalAction)}
        onSave={() => {
          setOpenModalAction(!openModalAction);
          clearCacheRequest();
        }}
        onCancel={() => setOpenModalAction(!openModalAction)}
        buttonSaveName="Save"
        type="success"
      />
    </div>
  );
};

export default ClearCache;
