import React, { useCallback, useEffect, useState } from 'react';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { Switch, Alert, Skeleton, Select } from 'antd';

import Repository from '../../../../config/repository';
import { useTables } from '../../../../context/tables';

interface Props {
  history: any;
  match: any;
}

const Form: React.FC<Props> = ({ history, match }) => {
  const repository = new Repository();
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(true);
  const [form, setForm] = useState({
    id: undefined,
    hotel: { id: undefined, name: '' },
    name: '',
    hotel_id: '',
    is_group: 0,
    code: '',
    status: 0,
  });
  const [hotels, setHotels] = useState([]);
  const { refreshTable } = useTables();

  useEffect(() => {
    repository.global.fetchHotels().then((res) => {
      setHotels(res.data);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getMarketSegment = useCallback(
    (id: string | number) => {
      repository.api.getMarketSegment(id).then(
        (res) => {
          const { data } = res;

          setForm({
            id: data.id,
            hotel: data.hotel,
            name: data.name,
            code: data.code,
            hotel_id: data.hotel.id,
            is_group: data.is_group ? 1 : 0,
            status: data.status ? 1 : 0,
          });

          setLoading(false);
        },
        () => {
          setLoading(false);
        },
      );
    },
    [repository.api],
  );

  const editMarketSegment = (data: any) => {
    if (!data.id) return;

    repository.api
      .putMarketSegments(data.id, {
        ...data,
        hotel_id: data.hotel.id,
        hotel: undefined,
      })
      .then(() => {
        alertChange();
      });
  };

  const saveMarketSegment = (data: any) => {
    repository.api
      .postMarketSegments({
        ...data,
        hotel_id: data.hotel.id,
        hotel: undefined,
      })
      .then(() => {
        alertChange();
      });
  };

  const save = () => {
    if (form.id) {
      editMarketSegment(form);
      return;
    }

    saveMarketSegment(form);
  };

  const alertChange = () => {
    setShowAlert(true);

    setTimeout(() => {
      setShowAlert(false);
      refreshTable('marketSegments');
    }, 600);
  };

  const resetForm = () => {
    setForm({
      id: undefined,
      hotel: { id: undefined, name: '' },
      name: '',
      hotel_id: '',
      is_group: 0,
      code: '',
      status: 0,
    });
  };

  const goBack = () => {
    history.goBack();
    resetForm();
  };

  useEffect(() => {
    resetForm();

    if (match.params.id) {
      getMarketSegment(match.params.id);
      return;
    }

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bg-white p-4 overflow-hidden shadow-md dark:bg-ebonyClay">
      <div>
        <div className="space-y-6">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-iron">
              {form.id ? form.name : 'New Market Segment'}
            </h3>
          </div>
          {loading && <Skeleton />}
          {!loading && (
            <div className="space-y-6 sm:space-y-5">
              <div className="flex flex-col">
                <label
                  htmlFor="hotel_id"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 dark:text-iron">
                  Hotel Id
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Select
                    className="max-w-lg block w-full shadow-sm sm:text-sm border-gray-300 dark:bg-transparent dark:text-white"
                    value={form.hotel.name}
                    dropdownMatchSelectWidth={false}
                    onChange={(e: any) => {
                      setForm({ ...form, hotel: hotels[e] });
                    }}
                    filterOption={(input, option) =>
                      option?.props.children
                        .toLowerCase()
                        .indexOf(input?.toLowerCase()) >= 0
                    }>
                    {hotels &&
                      hotels.map((option: any, i: number) => (
                        <Select.Option key={i} value={option[option.id]}>
                          {option.name}
                        </Select.Option>
                      ))}
                  </Select>
                </div>
              </div>

              <div className="flex flex-col">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 dark:text-iron">
                  Name
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="text"
                    name="name"
                    value={form.name}
                    onChange={(event) =>
                      setForm({ ...form, name: event?.target.value })
                    }
                    className="max-w-lg block w-full shadow-sm sm:text-sm border-gray-300 dark:bg-transparent dark:text-white"
                  />
                </div>
              </div>

              <div className="flex flex-col">
                <label
                  htmlFor="code"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 dark:text-iron">
                  Code
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="text"
                    name="code"
                    value={form.code}
                    onChange={(event) =>
                      setForm({ ...form, code: event?.target.value })
                    }
                    className="block max-w-lg w-full shadow-sm sm:text-sm border-gray-300 dark:bg-transparent dark:text-white"
                  />
                </div>
              </div>

              <div className="flex flex-col">
                <label
                  htmlFor="status"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 dark:text-iron">
                  Status
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Switch
                    checked={!!form.status}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    onChange={() =>
                      setForm({ ...form, status: form.status ? 0 : 1 })
                    }
                  />
                </div>
              </div>

              <div className="flex flex-col">
                <label
                  htmlFor="is_group"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 dark:text-iron">
                  Is Group
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <Switch
                    checked={!!form.is_group}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    onChange={() =>
                      setForm({ ...form, is_group: form.is_group ? 0 : 1 })
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <button
            onClick={() => goBack()}
            type="button"
            className="bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50">
            Cancel
          </button>
          <button
            onClick={() => save()}
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700">
            Save
          </button>
        </div>
      </div>
      {showAlert && (
        <Alert
          className="fixed right-0 top-0 mt-20 mr-4"
          message="Saved Changes"
          type="success"
          showIcon
        />
      )}
    </div>
  );
};

export default Form;
