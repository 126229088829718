import React, { useMemo } from 'react';
import { ChevronRightIcon, HomeIcon } from '@heroicons/react/outline';
import { Link, useHistory } from 'react-router-dom';

interface Props {
  title?: string;
}

const Breadcrumb: React.FC<Props> = ({ title }) => {
  const { push, location } = useHistory();

  const getPath = useMemo((): string[] => {
    return location.pathname.split('/');
  }, [location]);

  const endRouter = useMemo((): string => {
    if (getPath[getPath.length - 1] === 'list') {
      return '/list';
    }

    return '';
  }, [getPath]);

  return (
    <div className="flex items-center dark:text-iron">
      <p className="text-xl capitalize pr-2 border-r dark:text-iron font-medium">
        {title ? title : `${getPath[2].replaceAll('-', ' ')}`}
      </p>

      <Link to="/dashboard">
        <HomeIcon className="w-7 cursor-pointer hover:text-blue-600 pl-2" />
      </Link>

      <ChevronRightIcon className="w-3 mr-1" />
      {getPath.map((item, index: number) => {
        if (index < 2) {
          return null;
        }

        return (
          <div
            key={index}
            className="flex w-auto items-center dark:text-iron font-medium">
            <p
              onClick={() => {
                if (index !== getPath.length - 1) {
                  push(`/${getPath[1]}/${item}${endRouter}`);
                }
              }}
              className={`capitalize ${
                index !== getPath.length - 1
                  ? 'cursor-pointer hover:text-blue-600'
                  : ''
              } `}>
              {`${item.replaceAll('-', ' ')} `}
            </p>
            <p>
              {index !== getPath.length - 1 && (
                <ChevronRightIcon className="w-3 mx-1" />
              )}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default Breadcrumb;
