import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Api from '../pages/api';
import Ai from '../pages/ai';
import Dashboard from '../pages/dashboard';
import HotelOnboarding from '../pages/hotel-onboarding';
import Login from '../pages/login';

const Routes: React.FC = () => (
  <Switch>
    <Redirect exact from="/" to="/dashboard" />

    <Route path="/dashboard" component={Dashboard} />
    <Route path="/default/hotel-onboarding" component={HotelOnboarding} />
    <Route path="/api" component={Api} />
    <Route path="/ai" component={Ai} />
    <Route path="/login" component={Login} />
  </Switch>
);

export default Routes;
