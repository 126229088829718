import styled from 'styled-components';

export const Container: any = styled.div`
  width: ${(props: any) => props.width};
  height: 100vh;
  z-index: 10;
  box-shadow: 0 0 15px 0 rgb(34 41 47 / 5%);
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);

  .ant-menu.ant-menu-inline-collapsed
    > .ant-menu-item
    .ant-menu-item-icon
    + span,
  .ant-menu.ant-menu-inline-collapsed
    > .ant-menu-submenu
    > .ant-menu-submenu-title
    .ant-menu-item-icon
    + span {
    display: none;
  }

  .ant-menu-sub {
    background-color: transparent !important;
  }

  .min-menu {
    .ant-menu-item,
    .ant-menu-submenu-title {
      padding: 0px 17px !important;
      width: 94% !important;
      margin-left: 5px !important;
      justify-content: center;
    }

    .ant-menu-submenu-title {
      span {
        margin-right: 7px !important;
      }
    }
  }

  .ant-menu {
    width: 100%;
    height: 100%;
    background: #fff;
    border: 0;

    .section-label {
      display: block;
      margin: 40px 28px 20px 28px;
      font-weight: 600;
      color: #a6a4b0;
      font-size: 12px;
      letter-spacing: 0.01rem;
    }

    .ant-menu-item-selected {
      box-shadow: 0 0 10px 1px rgb(99 102 241/ 70%);
      background: linear-gradient(118deg, #6366f1, rgb(99 102 241/ 70%));

      a,
      span,
      svg {
        color: #fff !important;
      }
    }

    .ant-menu-item,
    .ant-menu-submenu {
      color: #565360 !important;
      width: 90%;
      margin: auto;
      border-radius: 2px;
      font-weight: 500;
      padding-left: 15px !important;

      .anticon {
        font-size: 18px;
      }

      &::after {
        display: none;
      }

      .ant-menu-submenu-title {
        padding: 0px !important;
      }
    }

    .ant-menu-item-selected {
      &:hover {
        a {
          color: #fff;
        }
      }
    }

    .ant-menu-item {
      display: flex;
      align-items: center;

      &:hover {
        padding-left: 20px !important;
        transition: all 0.25s ease;

        a {
          color: #565360;
        }
      }
    }

    .ant-menu-submenu {
      margin-bottom: 0px;
      padding-left: 0px !important;

      .ant-menu-submenu-title {
        display: flex;
        align-items: center;
        padding-left: 15px !important;

        &:hover {
          padding-left: 20px !important;
          color: #565360;
          transition: all 0.25s ease;
        }
      }

      &.ant-menu-submenu-open {
        .ant-menu-submenu-title {
          background: #f5f5f5;
          border-radius: 2px;
        }

        .ant-menu-item {
          width: 100% !important;
        }
      }
    }

    .ant-menu-submenu:hover
      > .ant-menu-submenu-title
      > .ant-menu-submenu-expand-icon,
    .ant-menu-submenu:hover
      > .ant-menu-submenu-title
      > .ant-menu-submenu-arrow {
      color: #565360;
    }
  }

  .ant-menu.ant-menu-inline-collapsed .ant-menu-submenu-title {
    padding-left: 5px !important;
  }
`;

export const Header: any = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${(props: any) =>
    !props.collapsed ? '0px 20px' : '0px 0px 0px 28px'};
  height: 62px;

  span {
    font-size: 15px;
    cursor: pointer;
    font-weight: bold;
    color: #6366f1;
  }

  .icon {
    color: #6366f1;
    cursor: pointer;
    width: 15px;
  }
`;

export const ApplicationName: any = styled.div`
  font-weight: 600;
  font-size: 14px;
  margin: 0;

  span {
    color: #6d6b7b;
  }
`;

export const RounderIcon: any = styled.div`
  width: 19px;
  height: 19px;
  border-radius: 50%;
  border: 2px solid #6366f1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .ballInner {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    border: 2px solid #6366f1;
  }
`;
