import React, { useCallback, useState } from 'react';
import ModalAction from '../../../../../components/modal-action';
import { PlusIcon } from '@heroicons/react/outline';
import CustomRows, { Row } from '../../../../../components/custom-rows';

interface Props {}

const Custom1: React.FC<Props> = () => {
  const [currentItem, setCurrentItem] = useState<Row | undefined>(undefined);
  const [openModalAction, setOpenModalAction] = useState(false);
  const [pmsFields, setPmsFields] = useState<Row[]>([]);

  const addMockedRow = useCallback(
    (state, values = {}) => {
      if (state === 'pmsFields') {
        const data = {
          state: 'pmsFields',
          id: Math.random().toString(),
          fields: [
            {
              type: 'text',
              value: '',
              label: 'PMS Code',
              key: 'code',
              cls: 'w-4/12',
            },
          ],
          ...values,
        };

        setPmsFields([data, ...pmsFields]);

        return data;
      }
    },
    [pmsFields],
  );

  const deleteHotelOnboard = () => {
    setOpenModalAction(false);

    setPmsFields(pmsFields.filter((item) => item.id !== currentItem?.id));
    setCurrentItem(undefined);
  };

  return (
    <div>
      <ModalAction
        title={`You will delete the item`}
        message="Do you confirm the action?"
        open={openModalAction}
        setOpen={() => setOpenModalAction(!openModalAction)}
        onSave={() => deleteHotelOnboard()}
        onCancel={() => setOpenModalAction(!openModalAction)}
      />

      <div>
        <div className="flex justify-between">
          <p className="font-bold dark:text-white">Custom 1</p>
          <span
            onClick={() => {
              if (currentItem) {
                return;
              }

              setCurrentItem(addMockedRow('pmsFields', { new: true }));
            }}
            className={`flex items-center text-xs dark:text-iron ${
              currentItem ? 'opacity-50' : 'cursor-pointer'
            }`}>
            Add new <PlusIcon className="w-5 h-5" />
          </span>
        </div>
        {pmsFields.length > 0 ? (
          <CustomRows
            rows={pmsFields}
            currentRow={currentItem}
            onChangeRow={(item) => setCurrentItem(item)}
            onDeleteRow={(item) => {
              setCurrentItem(item);
              setOpenModalAction(true);
            }}
            onCancelRow={() => {
              setPmsFields(pmsFields.filter((row) => !row.new));
              setCurrentItem(undefined);
            }}
            onSaveRow={() => setCurrentItem(undefined)}
            handleInput={(row, field, newValue) => {
              setPmsFields([
                ...pmsFields.map((item) => {
                  if (item.id === row.id) {
                    return {
                      ...row,
                      fields: [
                        ...row.fields.map((item) => {
                          if (item.key === field.key) {
                            return {
                              ...item,
                              value: newValue,
                            };
                          }

                          return item;
                        }),
                      ],
                    };
                  }

                  return item;
                }),
              ]);
            }}
          />
        ) : (
          <p className="w-full mt-5 text-center dark:text-white">No results</p>
        )}
      </div>
    </div>
  );
};

export default Custom1;
