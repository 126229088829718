import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { PaginationProps, Switch } from 'antd';
import Repository from '../../../../config/repository';
import Table from '../../../../components/table';
import { useUser } from '../../../../context/user';

interface PreCalculatedHotelMetrics {
  hotel_id: number;
  latest_calculated_at: string;
  active: boolean | number;
  created_at: string;
}

interface Columns {
  title: string;
  dataIndex: string;
}

const ActivateDeactivateMP: React.FC = () => {
  const { hotels, showAlert } = useUser();
  const repository = new Repository();

  const [preCalculatedHotelMetrics, setPreCalculatedHotelMetrics] = useState<
    PreCalculatedHotelMetrics[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState<PaginationProps>({
    current: 1,
    pageSize: 10,
    total: 10,
  });

  const columns = useMemo<Columns[]>(
    () => [
      {
        title: 'Hotel',
        dataIndex: 'hotel_id',
        render: (record: number) => (
          <span>
            {hotels.find((item) => item.id === record)?.name || record}
          </span>
        ),
      },
      {
        title: 'Latest Calculated At',
        dataIndex: 'latest_calculated_at',
      },
      {
        title: 'Status',
        dataIndex: 'active',
        render: (
          record: boolean,
          row: PreCalculatedHotelMetrics,
          index: number,
        ) => (
          <Switch
            checked={record}
            checkedChildren={
              <span className="flex items-center">
                Active <CheckOutlined className="ml-2" />
              </span>
            }
            unCheckedChildren={
              <span className="flex items-center">
                Inactive <CloseOutlined className="ml-2" />
              </span>
            }
            onChange={() =>
              addPreCalculatedHotelMetrics(
                row.hotel_id,
                record ? false : true,
                index,
              )
            }
          />
        ),
      },
    ],
    [hotels], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    getPreCalculatedHotelMetrics();
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeTable = useCallback(
    (value: PaginationProps) => {
      setPagination(value);
      setLoading(true);
      getPreCalculatedHotelMetrics(value.current, value.pageSize);
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const getPreCalculatedHotelMetrics = async (
    current = pagination.current,
    pageSize = pagination.pageSize,
  ) => {
    try {
      setLoading(true);

      const response =
        await repository.activateDeactiveMPRepository.getPreCalculatedHotelMetrics(
          current,
          pageSize,
        );

      setPagination({
        ...response.data.meta.pagination,
        current: response.data.meta.pagination.current_page,
        pageSize: response.data.meta.pagination.per_page,
        total: response.data.meta.pagination.total || 10,
      });
      setPreCalculatedHotelMetrics(response.data.data);
    } catch {}
    setLoading(false);
  };

  const addPreCalculatedHotelMetrics = async (
    hotelId: number,
    active: boolean,
    index: number,
  ) => {
    try {
      setPreCalculatedHotelMetrics((prevState) => {
        prevState[index].active = active;
        const newRefState = [...prevState];
        return newRefState;
      });

      await repository.activateDeactiveMPRepository.addPreCalculatedHotelMetrics(
        hotelId,
        { active },
      );

      showAlert(`Status Changed`, 'success', 'OK');
    } catch (error: any) {
      setPreCalculatedHotelMetrics((prevState) => {
        prevState[index].active = !active;
        const newRefState = [...prevState];
        return newRefState;
      });

      showAlert(
        error.response ? error.response.data.message : 'Error saving changes',
        'error',
        'OK',
      );
    }
  };

  return (
    <div className="relative bg-white p-4 pb-0 overflow-hidden shadow-md dark:bg-ebonyClay">
      <Table
        data={preCalculatedHotelMetrics}
        columns={columns}
        pagination={pagination}
        loading={loading}
        rowKey="hotel_id"
        onChange={handleChangeTable}
      />
    </div>
  );
};

export default ActivateDeactivateMP;
