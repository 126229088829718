import React, { useCallback, useMemo, useState } from 'react';
import BreadcrumbCreation, {
  Step,
} from '../../../../components/breadcrumb-creation';
import { Select } from 'antd';
import Repository from '../../../../config/repository';
import { useUser } from '../../../../context/user';
import ModalAction from '../../../../components/modal-action';
import { useHistory } from 'react-router';

interface Props {}

const repository = new Repository();

const RateShopperLink: React.FC<Props> = () => {
  const { clientHotels, showAlert } = useUser();
  const [currentStep, setCurrentStep] = useState(0);
  const [hotelId, setHotelId] = useState(undefined);
  const [linkedHotelId, setLinkedHotelId] = useState(undefined);
  const [openModalAction, setOpenModalAction] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const hotelSelected = useMemo(
    () => clientHotels.find((hotel) => hotel.id === hotelId),
    [hotelId, clientHotels],
  );

  const linkedHotelSelected = useMemo(
    () => clientHotels.find((hotel) => hotel.id === linkedHotelId),
    [clientHotels, linkedHotelId],
  );

  const saveData = useCallback(async () => {
    if (!hotelId) return;

    setLoading(true);

    try {
      await repository.api.createRateShopperLink({
        hotel_id: hotelId,
        linked_hotel_id: linkedHotelId,
        is_active: true,
      });
      setLoading(false);
      showAlert('Your information was saved with success', 'success', 'OK');
      history.push('/');
    } catch {
      setLoading(false);
      showAlert('Your information was not saved', 'error', 'OK');
    }
  }, [history, hotelId, linkedHotelId, showAlert]);

  const steps: Step[] = useMemo(
    () => [
      {
        title: 'Hotel',
        subTitle: 'Select a Hotel',
        content: (
          <Select
            className="w-6/12"
            showSearch
            placeholder="Please select the hotel"
            filterOption={(input, option) => {
              if (option?.label && typeof option.label === 'string') {
                return (option.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }
              return true;
            }}
            value={hotelId}
            onChange={(value) => setHotelId(value)}
            options={clientHotels.map((value) => {
              return {
                value: value.id,
                label: value.name,
              };
            })}
          />
        ),
      },
      {
        title: 'Linked Hotel',
        subTitle: 'Select the linked hotel',
        content: (
          <Select
            className="w-6/12"
            showSearch
            placeholder="Please select the hotel"
            filterOption={(input, option) => {
              if (option?.label && typeof option.label === 'string') {
                return (option.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }
              return true;
            }}
            value={linkedHotelId}
            onChange={(value) => setLinkedHotelId(value)}
            options={clientHotels.map((value) => {
              return {
                value: value.id,
                label: value.name,
              };
            })}
          />
        ),
      },
      {
        title: 'Overhaul',
        subTitle: 'Review all the details',
        content: (
          <div className="flex flex-col">
            <span>
              <b>Hotel:</b> {hotelSelected?.name}
            </span>
            <span>
              <b>Linked Hotel:</b> {linkedHotelSelected?.name}
            </span>
          </div>
        ),
      },
      {
        title: 'Record',
        subTitle: 'Apply changes',
        content: <span>Save data</span>,
      },
    ],
    [hotelId, clientHotels, linkedHotelId, hotelSelected, linkedHotelSelected],
  );

  return (
    <div className="flex bg-white p-4 overflow-hidden shadow-md dark:bg-ebonyClay relative">
      <BreadcrumbCreation
        currentStep={currentStep}
        steps={steps}
        loading={loading}
        onCancel={() => setCurrentStep((prevState) => prevState - 1)}
        onResolve={() => {
          if (currentStep === 0 && hotelId === undefined) {
            showAlert('Please select a hotel', 'error', 'OK');

            return;
          }

          setCurrentStep((prevState) => {
            if (prevState < steps.length - 1) {
              return prevState + 1;
            }

            return prevState;
          });

          if (currentStep === steps.length - 1) {
            setOpenModalAction(!openModalAction);
          }
        }}
      />

      <ModalAction
        title={
          <div className="flex flex-col text-sm">
            <span>{hotelSelected?.name} (Primary Hotel)</span>
            <span>{linkedHotelSelected?.name} (Linked Hotel)</span>
          </div>
        }
        message="You want to proceed with the linkage of Rate Shopper information?"
        open={openModalAction}
        setOpen={() => setOpenModalAction(!openModalAction)}
        onSave={() => {
          setOpenModalAction(!openModalAction);
          saveData();
        }}
        onCancel={() => setOpenModalAction(!openModalAction)}
        buttonSaveName="Save"
        type="success"
      />
    </div>
  );
};

export default RateShopperLink;
