import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: 0;
    outline: 0;
  }

  .ant-collapse-header {
    pointer-events: none;
  }

  .anticon {
    pointer-events: all;
  }

  .dark .ant-layout {
    background-color: transparent !important;
  }

  body {
    -webkit-font-smoothing: antialiased;
    background-color: #161d30 !important;
    font-family: 'Montserrat', sans-serif;
    height: 100vh;
    overflow: hidden;
  }

  .ant-layout {
    background-color: #f8f8f8;
  }

  #root, body, .ant-layout {
    height: 100%;
  }

  button {
    cursor: pointer;
  }

  #__react-alert__ {
    div {
      pointer-events: initial !important;
    }
  }

  .site-layout {
    background-color: #f8f8f8;
    height: calc(100vh - 62px) !important
  }

  .table-report {
    .ant-pagination {
      margin-right: 0px !important;
    }
  }

  .ant-select-item {
    font-size: 0.79rem !important;
    line-height: 1.25rem !important;
  }

  .ant-picker-input > input {
    font-size: 12px !important;
  }

  .ant-select-selector, .ant-picker {
    border-color: rgba(209, 213, 219, 0.7) !important;
    font-size: 0.79rem !important;
    line-height: 1.25rem !important;
    border-radius: 2px !important;
    min-height: 38px;

    .ant-select-selection-item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .ant-select-selection-item-content {
        font-size: 0.79rem !important;

        .anticon {
          vertical-align: 0em !important;
        }
      }
    }
  }

  .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }

  .ant-input-number-input-wrap input {
    min-height: 36px;
  }

  .ant-select-selector, .ant-picker, .ant-input-number {
    border-radius: 0 !important;
  }

  .ant-switch-checked {
    background-color: #565acb !important;
  }

  .ant-input-number {
    min-height: 36px;
    border: 1px solid #d9d9d9ab !important;
    border-radius: 0;
  }

  .dark {
    .ant-select-selector, .ant-picker, .ant-input-number {
      background-color: transparent !important;
      color: white !important;

      input {
        color: white !important;
      }
    }

    .ant-select-multiple .ant-select-selection-item {
      background-color: #565acb !important;
      border-color: #565acb !important;
    }

    .anticon {
      color: white;
    }

    .ant-table {
      background-color: transparent !important;
    }

    .ant-table-thead {
      .ant-table-cell {
        background-color: #343d55 !important;
        color: #b4b7bd !important;
        border: 0 !important;
      }
    }

    .ant-table-tbody {
      .ant-table-cell {
        color: #b4b7bd !important;
        background-color: transparent !important;
        border-bottom: 1px solid #9ea3ae3b !important;
      }
    }

    .ant-pagination {
      background-color: #343d55 !important;

      a {
        color: white !important; 
      }

      .anticon {
        color: white;
      }
    }
  }

  .ant-table-column-sorter-up.active, .ant-table-column-sorter-down.active {
    color: #7366f0 !important;
  }

  .dark {
    .ant-menu-submenu-arrow, span {
      color: #fff !important;
    }

    .ant-menu-submenu-title {
      background: #161c31 !important;
      color: #fff !important;

      &:hover {
        color: #fff !important;
      }
    }

    .ant-menu-sub.ant-menu-vertical {
      background-color: #161c31 !important;
      color: #fff !important;

      a, span, i {
        color: #fff !important;
      }

      .ant-menu-item {
        &:hover {
          background-color: #283045 !important;
        }
      }

      .ant-menu-item-selected {
        background-color: #283045 !important;
      }
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #7366f0 !important;
    border-color: #7366f0 !important;
  }

  .ant-collapse, .ant-collapse-content, .ant-collapse-item {
    background-color: transparent !important;
    border: 0 !important;
  }

  .ant-collapse-content-box {
    padding: 0px !important;
  }

  .dark {
    .ant-collapse-header {
      color: #fff !important;
    }
  }

  .ant-collapse-header {
    display: flex !important;
    align-items: center !important;
    padding: 0 8px 10px 8px !important;

    .anticon {
      padding: 0px !important;
      right: 5px;
      left: unset !important;
    }
  }

  #buttonGoTop {
    border-color: #7367f0!important;
    background-color: #7367f0!important;
    width: 36px;
    height: 36px;
    position: fixed;
    bottom: 5%;
    right: 30px;
    cursor: pointer;
    transition: opacity 0.2s linear 0s, visibility;
    visibility: visible;
    border-radius: 2px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;

    svg {
      width: 14px;
      height: 14px;
    }
  }

  .box-login {
    ::before {
      width: 244px;
      height: 243px;
      content: " ";
      position: absolute;
      margin-top: -222px;
      margin-left: -238px;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPQAAADzCAMAAACG9Mt0AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAA9KADAAQAAAABAAAA8wAAAADhQHfUAAAAyVBMVEUAAAD///+AgP+AgP9mZv+AgNWAgP9tbf9gYP+AgP9xcf9mZv+AZuaAgP9dXf90dOhiYv92dv9mZu5mZv93d+53d/9paf94afCAcfFrXvJra/9mZvJzZvJzc/JoaP96b/Rqav91aupsYvV2bOt2bPVxaPZ7cfZqavZyau1waPd4aO9xafBxafh4afB1bfh4avFuZ/F2afJzZvJzZ/N0aPN0bvN3bPR0ae5yZ/R3be93bfR1au9zafBxbPVzavV0a/F0a/ZyafFwaPKZm3nTAAAAQ3RSTlMAAQIEBQYGBwgICQoKCgsLDQ0PDw8PERESExMUFBQWFxgYGhoaGxsdHSAgIiIiIyQlJygqLCwtLi8vLzAzNDU3Nzg7h9vbHgAAA9RJREFUeNrt3ftS2kAUx/Fc1gSyWsErtuJdRDQiiteolb7/QzUoTm07k4AzObuu3/MCez45yWbzT36eZ6b8erO1e1B97baadd+zocJWmg0HaXe/+uqmg2GWtkLT5Lle1m9LdhG2+1lvzuiUO1knEF81yFc1N+35m15kZOGodz1vyLx+v2Lseq/erxtZd/NuweCTtfiwaWLOD5FnsqI7+VnP3y8afnEs3Es/1+H1qvETwuq18B7e6VlwLup1ZM8kWWQBOsrmHL7GVtxvYRZYgQ4ywae61ffsqH5Lbq20bQm6ncp9P2ehJegwE/u+rl95ttSwLrVSc2ANetAU28dSa9Cp2E623bUG3d2VWmn/wBq0XCugQYMGLdVKoOJaoiuok1NdXSW1WAUfRPtRUllflaJf5ZE/O9pXVbZUPTov5c+IDqvtRwStdTgLutoxy6GnGfYb2o+1I2gd+1OiqzfLocvVE7TSDqG1mgodaqfQZbvZC9rXjqG1X45WzqFVKVpk0LLo4lGP0ZGD6KgMnTiITkrQgXYQrYNitHISrYrRsZPouBhdcxJdK0YnTqKTYrR2Eq1BgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRoh9DH59ag86ACoSYOL61B55EUQk1s3VqDzsNHhJpYe7QGncfMSHUxaliCHgcKSXVxeWQJehwdJdXF4dAS9DgkTKqLxuibFeiXODixNi7OrEC/BP+JtbE0WrYA/RrxKNfH2YUF6NegSbk+Gk87xtErN6EsWm88fzeMXpwE9EruLns/l42io4dJFLPo2/Po1w+D6IW7t9Bt2SPx3vOOMfS7eHVZtN54ulg2go56138Ct4XRunE2Ovsmjg46WeddUoUWr6WL0fCoIYgO2/2s91fstDZQjcPL0ePt5flpdXUwqW46uMrS1j95JNpQrW0dHp9UV/uT2m416/8HVGg3qzhpBjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KC/FDpx0pwUo2tOomvF6NhJdFyMVk6iVTE6cBIdeF9vJyvZx/I/AzuIjsrQvoNovwzt4FamSs0Ojrp80PmvoB0zh940pb7azf1yg7t0LIt978uppzbnalfucDW92ZndLPRmKweGPduYJ+zoM5/Dk+gD5NdvLhXXPp88qcUqmEH5G5JZRs6cuxwIAAAAAElFTkSuQmCC);
    }

    ::after {
      width: 272px;
      height: 272px;
      content: " ";
      margin-top: 200px;
      margin-left: 260px;
      position: absolute;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAARAAAAEQCAMAAABP1NsnAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAABEKADAAQAAAABAAABEAAAAAAQWxS2AAAAwFBMVEUAAAD///+AgICAgP9VVaqqVf+qqv+AgL+AgP9mZsxmZv+ZZv+AgNWAgP9tbdttbf+Sbf+AYN+AgN+AgP9xceNmZv+AZuaAZv90dOh0dP9qav+AauqAav+AgP92dv9tbf+Abe2Abf93Zu53d+6AcO94afCAcfF5a+R5a/JzZuaAZvKAc/J5bed5bfOAaPN6b/R1auqAavR6ZvV6cPV2bOuAbPV7aPZ2be2AbfZ7au17avZ3Zu53b+57a+97a/d4aO9J6CoeAAAAQHRSTlMAAQICAwMDBAQFBQUGBgcHBwgICAkKCgoLCwwMDAwNDg4ODw8QERITExQUFBUVFhcYGBkZGhobHBwdHR4eHx8gJ5uMWwAAA/FJREFUeNrt2G1XEkEYxvHZNk2xHGzdbKFl0cTwgdSkCKzu7/+t4pw6sAjtjIueE/f8r3fMO35nZnbuy5gVGcvfzJe0rnTfGI+MggGJRUZnbpPIhJKt88nU53JnFULvyISY6KAv8vPj0vr2rYwiE2Z2B9J+uNYcyyQxwWZvaeGH3G4bMjsvI/kcwTC/V+7kLoahlITzQojP3ZFgsJCh7IJQzpX0QFj4uMiY18eDMZ9bZCF9OQahnK6cm/Y7js0sh/LF3Auv1PlQd3MxbdXYIQspV44EEEAAAWTNDAYYkKdJbNMsLzYueZbaZ2iM46RVbHBaiZ9Js+nHEdli42N9XuSen5hGp1CQTuOJQDRsD99N4gMSpYWapNH6IJo83CIeILZQFesEaber79NCWRoukOpNEnW0gXQqD81w6ACxhbrYde7VuFCYeA2QRCNIsgZISyNIqz6IyhPjOjNVIFYniK3dmKU6QdLaJUimEySrDZLrBMlrgxRKU7sxCw/EMe0CAggggADySJCqxixIkKpNEh6IozELD8RxjQACCCCAAPJIkKrGLEgQXqqAAEJjxrQLCCCAAEJjRmNGY8a0CwgggABCYwYIfQgggNCYMe0CAggggNCY0ZjRmDHtAgIIIIAAQmNGHwIIIDRmTLuAAAIIIDRmNGY0Zky7gAACCCCA0JjRhwACCI0Z0y4ggAACCI0ZjRmNGdMuIIAAAgggNGb0IYAAQmPGtAsIIIAAQmNGY0ZjxrQLCCCAAAIIjRl9CCCA0Jgx7QICCCCA0JjRmNGYMe0CAggggABCY0YfAgggNGZMu4AAAgggNGY0ZjRmTLuAAAIIIIDQmNGHAAIIjRnTLiCAAAIIjRmNGY0ZIEy7gAACCCA0ZvQhgABCY8a0CwgggABCY0ZjBgiNGdMuIIAAAgiN2f/Sh+Q6PfLaIJlOkKw2SKoTJK3dmFmdILb2tBvrBIlrg5iWRo+WqQ+SaARJ1gCJAzsxThCN16p1vNurGjNjoo42j07kAHFskoY2kEbl33U0ZgoPjXW+Rl0gkarnahqtDaJKxMPDDWIiNafGenh4gExvVhXfmk7Da6L1AVGxSby2h6MxK79Zk42ea1pJbJ48sU2zDezQ8iy1z6BBwoyjMQsvXp8YQAAhgADilRfyy+wf8WqZZUfGZihvgZiB3FybC+kCUU5XLkAo50C+gbBQdUzkAIVyejIAYfFTI1solHP2HgNCnHn5AYNy4jvpoVB6fVzL91cwzLJ9Lfd7S0jhehxO5H5/yePr1W6gHonI7fJ5ORSR/n6Q2yQanq763zuXU5LJZRKiyD/W9/pjkdPZz0/yJ8fqVyry+qQZDMjJKoDfy8bRVhHhQTwAAAAASUVORK5CYII=);
    }
  }
`;
