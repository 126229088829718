import React, { useState, useEffect } from 'react';
import ModalAction from '../../../components/modal-action';
import Table from '../../../components/table';
import { PencilIcon, TrashIcon } from '@heroicons/react/outline';
import { useHistory } from 'react-router';

interface Props {}

const List: React.FC<Props> = () => {
  const history = useHistory();
  const [data, setData] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [pagination] = useState({
    current: 1,
    pageSize: 10,
    total: 10,
  });
  const [, setCurrentItem] = useState({ id: '', name: '' });
  const [openModalAction, setOpenModalAction] = useState(false);

  const renderAction = (record: any) => {
    return (
      <div className="flex justify-center">
        <PencilIcon
          onClick={() =>
            history.push(
              `/default/hotel-onboarding/settings/general/${record.id}`,
            )
          }
          className="w-4 h-4 cursor-pointer"
        />
        <TrashIcon
          onClick={() => {
            setCurrentItem(record);
            setOpenModalAction(true);
          }}
          className="w-4 h-4 cursor-pointer ml-2"
        />
      </div>
    );
  };

  const deleteHotelOnboard = () => {
    setOpenModalAction(false);
  };

  useEffect(() => {
    setData([{ id: 1, nif: '12341123344', name: 'hotel onboarding 1' }]);

    setColumns([
      {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'NIF',
        dataIndex: 'nif',
        key: 'nif',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
    ]);

    setLoading(false);
  }, []);

  return (
    <div className="bg-white shadow-md px-4 dark:bg-ebonyClay">
      <ModalAction
        title={`You will delete the item`}
        message="Do you confirm the action?"
        open={openModalAction}
        setOpen={() => setOpenModalAction(!openModalAction)}
        onSave={() => deleteHotelOnboard()}
        onCancel={() => setOpenModalAction(!openModalAction)}
      />

      <Table
        data={data}
        columns={columns}
        pagination={pagination}
        loading={loading}
        rowKey="id"
        renderTableActions={(record: any) => renderAction(record)}
        hasAddnew={true}
        history={history}
        addNewRouter={'/default/hotel-onboarding/settings/general/new'}
      />
    </div>
  );
};

export default List;
